// import React from 'react';
import { React, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import i18n from 'i18next';                     /* Translations - RTL handling */

import img1 from '../images/projects/projects-item1.jpg';
import img2 from '../images/projects/projects-item2.jpg';
import img3 from '../images/projects/projects-item3.jpg';
import img4 from '../images/projects/projects-item4.jpg';
import img5 from '../images/projects/projects-item4.jpg';
import img6 from '../images/projects/projects-item4.jpg';
import img7 from '../images/projects/projects-item4.jpg';


import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const swiperOptions = {
    modules: [Autoplay, Pagination, Navigation],
    slidesPerView: 6,
    spaceBetween: 30,
    navigation: false,
    autoplay: {
        delay: 2000,
        disableOnInteraction: false,
    },
    loop: true,

    breakpoints: {
        320: {
            slidesPerView: 1,
            // spaceBetween: 30, 225
        },
        575: {
            slidesPerView: 1,
            // spaceBetween: 30, 162
        },
        767: {
            slidesPerView: 2,
            // spaceBetween: 30, 
        },
        991: {
            slidesPerView: 3,
            // spaceBetween: 30,
        },
        1199: {
            slidesPerView: 4,
            // spaceBetween: 30,
        },
        1350: {
            slidesPerView: 6,
            // spaceBetween: 30,
        },
    }


};



const Portfolio = () => {

    useEffect(() => {

        const dir = i18n.dir(i18n.language);
        document.documentElement.dir = dir;
    }, [i18n, i18n.language]);


    const { t } = useTranslation()
    const { title, description, description2, item1_title, item1_description, item2_title, item2_description, item3_title, item3_description, item4_title, item4_description, all_cases } = t("portfolio");


    const { squid_title, squid_subtitle, squid_description, squid_learn } = t("portfolio.squid");
    const { health_title, health_subtitle, health_description, health_learn } = t("portfolio.health");
    const { wewear_title, wewear_subtitle, wewear_description, wewear_learn } = t("portfolio.wewear");
    const { netpay_title, netpay_subtitle, netpay_description, netpay_learn } = t("portfolio.netpay");


    return (
        <>

            <section className="clients-section pt-10 pb-32 bg-black">


                <div className="my-4 py-4" id='portfolio'>

                    <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-white uppercase font-bold">
                            {title}
                        </h2>

                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-white'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-normal text-[#45E6FC]">
                            {description}
                        </h2>
                        <p className=" mx-12 text-center text-lg lg:text-lg font-normal text-white">
                            {description2}
                        </p>
                    </div>


                    {/* <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max "> */}


                    <div className="auto-container pl-12 pr-12" >
                        <Swiper {...swiperOptions} className="clients-carousel owl-carousel owl-theme ">


                            <SwiperSlide className="client-block">
                                <div to="/" className="bg-black transition-all ease-in-out duration-400 border-2 border-white overflow-hidden text-white hover:bg-[#1695A6] hover:text-white shadow-2xl m-10 group">

                                    <div className="m-2 text-justify text-sm ">

                                        <img alt="card img" className="group-hover:scale-125 scale-80 transition duration-1000 ease-in-out" src={img1} />

                                        <h2 className="font-semibold pt-8 pb-6  text-2xl text-center ">
                                            {item1_title}
                                        </h2>

                                        <p className="font-normal text-sm mt-2 mb-4 text-center ">
                                            {item1_description}
                                        </p>


                                    </div>
                                </div>

                            </SwiperSlide>

                            <SwiperSlide className="client-block">
                                <div to="/" className="bg-black transition-all ease-in-out duration-400 border-2 border-white overflow-hidden text-white hover:bg-[#1695A6] hover:text-white shadow-2xl m-10 group">

                                    <div className="m-2 text-justify text-sm ">

                                        <img alt="card img" className="group-hover:scale-125 scale-80 transition duration-1000 ease-in-out" src={img2} />

                                        <h2 className="font-semibold pt-8 pb-6 text-2xl text-center ">
                                            {item2_title}
                                        </h2>

                                        <p className="font-normal text-sm mt-2 mb-4 text-center ">
                                            {item2_description}
                                        </p>


                                    </div>
                                </div>

                            </SwiperSlide>

                            <SwiperSlide className="client-block">
                                <div to="/" className="bg-black transition-all ease-in-out duration-400 border-2 border-white overflow-hidden text-white hover:bg-[#1695A6] hover:text-white shadow-2xl m-10 group">

                                    <div className="m-2 text-justify text-sm ">

                                        <img alt="card img" className="group-hover:scale-125 scale-80 transition duration-1000 ease-in-out" src={img3} />

                                        <h2 className="font-semibold pt-8 text-lg text-center ">
                                            {item3_title}
                                        </h2>

                                        <p className="font-normal text-sm mt-2 mb-4 text-center ">
                                            {item3_description}
                                        </p>


                                    </div>
                                </div>

                            </SwiperSlide>

                            <SwiperSlide className="client-block">
                                <div to="/" className="bg-black transition-all ease-in-out duration-400 border-2 border-white overflow-hidden text-white hover:bg-[#1695A6] hover:text-white shadow-2xl m-10 group">

                                    <div className="m-2 text-justify text-sm ">

                                        <img alt="card img" className="group-hover:scale-125 scale-80 transition duration-1000 ease-in-out" src={img4} />

                                        <h2 className="font-semibold pt-8 pb-6  text-2xl text-center ">
                                            RETAIL
                                        </h2>

                                        <p className="font-normal text-sm mt-2 mb-4 text-center ">
                                            restaurants, coffeeshops,dept. stores
                                        </p>


                                    </div>
                                </div>

                            </SwiperSlide>


                            <SwiperSlide className="client-block">
                                <div to="/" className="bg-black transition-all ease-in-out duration-400 border-2 border-white overflow-hidden text-white hover:bg-[#1695A6] hover:text-white shadow-2xl m-10 group">

                                    <div className="m-2 text-justify text-sm ">

                                        <img alt="card img" className="group-hover:scale-125 scale-80 transition duration-1000 ease-in-out" src={img5} />

                                        <h2 className="font-semibold pt-8 pb-6  text-2xl text-center ">
                                            RETAIL
                                        </h2>

                                        <p className="font-normal text-sm mt-2 mb-4 text-center ">
                                            restaurants, coffeeshops,dept. stores
                                        </p>


                                    </div>
                                </div>

                            </SwiperSlide>

                            <SwiperSlide className="client-block">
                                <div to="/" className="bg-black transition-all ease-in-out duration-400 border-2 border-white overflow-hidden text-white hover:bg-[#1695A6] hover:text-white shadow-2xl m-10 group">

                                    <div className="m-2 text-justify text-sm ">

                                        <img alt="card img" className="group-hover:scale-125 scale-80 transition duration-1000 ease-in-out" src={img6} />

                                        <h2 className="font-semibold pt-8 pb-8 text-2xl text-center ">
                                            RETAIL
                                        </h2>

                                        <p className="font-normal text-sm mt-2 mb-4 text-center ">
                                            restaurants, coffeeshops,dept. stores
                                        </p>


                                    </div>
                                </div>

                            </SwiperSlide>

                            <SwiperSlide className="client-block">
                                <div to="/" className="bg-black transition-all ease-in-out duration-400 border-2 border-white overflow-hidden text-white hover:bg-[#1695A6] hover:text-white shadow-2xl m-10 group">

                                    <div className="m-2 text-justify text-sm ">

                                        <img alt="card img" className="group-hover:scale-125 scale-80 transition duration-1000 ease-in-out" src={img7} />

                                        <h2 className="font-semibold pt-8 pb-8 text-2xl text-center ">
                                            RETAIL
                                        </h2>

                                        <p className="font-normal text-sm mt-2 mb-4 text-center ">
                                            restaurants, coffeeshops,dept. stores
                                        </p>


                                    </div>
                                </div>

                            </SwiperSlide>



                        </Swiper>
                    </div>

                </div>
            </section >
        </>
    );
};

export default Portfolio;



