import React, { useState, useEffect } from 'react';


import { useTranslation } from 'react-i18next';
import i18n from 'i18next';                     /* Translations - RTL handling */


//import img from '../images/Web-developer.png'; //svg
import img from '../images/intro1-img.jpg';
import { Link } from 'react-router-dom';

const Intro = () => {

    const { t } = useTranslation()
    const { line1, line2, contact } = t("introdescription");


    useEffect(() => {

        window.scrollTo(0, 0);
        const dir = i18n.dir(i18n.language);
        document.documentElement.dir = dir;
    }, [i18n, i18n.language]);


    return (
        <>

            {/* max-w-6xl */}

            {/* <div className="m-auto max-w-full p-2 md:p-12 align-middle" id='intro' > */}

            <div className="flex justify-center items-center h-fit bg-black" id='intro' >





                <div className="flex flex-col-reverse lg:flex-row py-8 justify-center" data-aos="zoom-in" data-aos-delay="800" >

                    <div className="justify-center">

                        <div> {i18n.language == 'en' ?

                            <img alt="" className="scale-75 rounded-lg border border-white duration-700" src={img} />
                            :
                            <img alt="" className="scale-75 rounded-lg border border-white duration-700" src={img} />

                            // <svg className="w-8 h-4 ml-2 pl-2  scale-x-[-1]  group-hover: translate-x-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        }</div>


                    </div>



                    <div className="flex-col my-4 lg:my-0 lg:justify-center w-full lg:w-1/2 px-8 flex justify-center items-center text-center" data-aos="zoom-in" data-aos-delay="500">

                        <h3 className="text-3xl  text-white font-bold">

                            {t("introtitle")}

                        </h3>
                        <div>
                            <p className='text-xl text-[#45E6FC] font-normal text-center mt-8'>
                                {line1}
                            </p>
                        </div>

                        <div>
                            <p className='text-xl text-[#45E6FC] font-normal text-center'>
                                {line2}
                            </p>
                        </div>

                    </div>
                </div>
            </div>


        </>
    )
}

export default Intro;