//import React from 'react';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';                     /* Translations - RTL handling */


const Cta = () => {

    const { t } = useTranslation()
    const { cta_title, cta_subtitle1, cta_subtitle2, cta_messgae } = t("general.cta");


    useEffect(() => {
        window.scrollTo(0, 0);
        const dir = i18n.dir(i18n.language);
        document.documentElement.dir = dir;
    }, [i18n, i18n.language]);


    return (
        <div className="w-full flex items-center justify-center text-white cta">
            <div className="mx-8 w-full h-96  py-16 px-12 flex lg:justify-between items-center">
                <div className="w-full flex flex-col lg:flex-row lg:justify-around">
                    <div className="mb-4">
                        <p className='text-2xl md:text-4xl font-bold mb-4'>{cta_title}</p>
                        <p className="text-lg md:text-2xl">{cta_subtitle1} <span className='font-black'>{cta_subtitle2}</span></p>
                    </div>

                    <div className="w-full lg:w-72 pt-6 lg:mx-12">
                        <Link to="/contact" className="bg-transparent rounded-full border border-[#45E6FC] hover:bg-[#45E6FC] hover:border-[#45E6FC] text-[#45E6FC] hover:text-white justify-center text-center px-10 py-3 flex items-center group">{cta_messgae}

                            <div>
                                <svg className="w-5 h-5 ml-1 group-hover:translate-x-2 duration-500 ease-in" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </div>

                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Cta;