import { React, useEffect } from "react";
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import HeroVideo from '../components/HeroVideo';


import Intro from '../components/Intro';
import Portfolio from '../components/Portfolio';
import Products from '../components/Products';
import Services from '../components/Services';
import Technologies from '../components/Technologies';
import { Helmet } from 'react-helmet';


const Home = () => {


    return (
        <>
 
            <Helmet>
                <title>ANB - LED Solutions</title>
                <meta
                    name='description'
                    content='ANB - LED Solutions'
                />
                <meta
                    name='keywords'
                    content="software, software engineering, web application, mobile application, mobile developer, android, android developer, ios, ios developer, mobile app development, ANB, coding, mobile programming, software application, sW solutions, software company, mobile developer in batumi, website developer in batumi, מתכנת לפיתוח אתרים, מתכנת לפיתוח אפליקציה "
                />
            </Helmet>
            <HeroVideo />
            <Hero />
            <Intro />
            <Products />
            <Services />
            {/* <Technologies /> */}
            <Portfolio />
            <Cta />
            <Footer />
        </>
    )
}

export default Home;


///<Clients />


