import React, { useState, useEffect } from 'react';
import NavLinks from '../Navbar/NavLinks';
import { HashLink } from 'react-router-hash-link';
//import appLogo from '../../images/logo192.png';
import appLogo from '../../images/website_log.png';
import FloatingIcon from '../FloatingIcon';
import whatsapp from '../../icons/whatsapp.png';


import waImg from '../../icons/whatsapp.png';
// import lnkdImg from '../../icons/linkedin.png';
// import fbImg from '../../icons/facebook.png';


const ContactSideBar = () => {


    const [bottom, setBottom] = useState(!window.scrollY);
    const [isOpen, setisOpen] = React.useState(false);

    function handleClick() {
        setisOpen(!isOpen);
    }


    useEffect(() => {
        const scrollHandler = () => {
            if (window.pageYOffset > 10) {
                setBottom(false)
            }
            else {
                setBottom(true)
            }
        };
        window.addEventListener('scroll', scrollHandler);
        return () => window.removeEventListener('scroll', scrollHandler);
    }, [bottom]);

    return (

        // <nav id='navbar' className={` fixed right-0 top-1/2 h-44 w-12 z-30 transition duration-300 ease-in-out   bg-[#233962] hover:bg-[#4492A3] rounded-ss-xl rounded-s-xl shadow-lg `}>

        <nav id='navbar' className={` fixed right-0 mr-2 -bottom-6 h-44 w-20 z-30 transition duration-300 ease-in-out   bg-transparent rounded-ss-xl rounded-s-xl `}>

            <div className='flex '>

                {/* <div className=' w-1 h-12 left-0 pl-1 mt-14 bg-[#4492A3] '>

                </div> */}


                <div className="flex flex-row justify-between items-center py-4" >

                    <div className="flex flex-row justify-center md:px-2 items-center text-center font-semibold">
                        <ul className="flex justify-center mb-4 md:mb-0">
                            <li className="ml-6">

                                <div className="flex flex-col justify-center items-center text-center font-semibold ">

                                    <a aria-label="Chat on WhatsApp" href="https://wa.me/660982592690" target='_blank'> <img alt="Chat on WhatsApp"
                                        src={waImg}  className='w-10 h-10 border-2 border-white rounded-full' />
                                    </a>

                                    {/* <a aria-label="Chat on WhatsApp" href="https://www.linkedin.com" target='_blank'> <img alt="LinkedIn"
                                        src={lnkdImg} width='48' height='48' className='border-2 border-white rounded-full' />
                                    </a>

                                    <a aria-label="Chat on WhatsApp" href="https://www.facebook.com" target='_blank'> <img alt="Facebook"
                                        src={fbImg} width='48' height='48' className='border-2 border-white rounded-full' />
                                    </a> */}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>


            </div>


        </nav>
    )

}


export default ContactSideBar;
