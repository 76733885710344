import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import appLogo from '../images/website_logo.png';



const Footer = () => {


    const { t } = useTranslation()
    const { logo_title, logo_address } = t("footer.logo");
    const { company_title, company_about, company_aproach, company_policy, company_terms, company_contact } = t("footer.company");
    const { services_title, services_web, services_mobile, services_uxui, services_consult } = t("footer.services");
    const { social_title, social_subtitle } = t("footer.social");

    return (
        <>
            <footer>
                <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-black py-30 ">
                    {/* Top area: Blocks */}
                    <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12  border-gray-200 lg:ml-11">
                        {/* 1st block */}
                        <div className="col-span-12 lg:col-span-4">
                            <div className="box-border border-2 border-[#45E6FC] p-8 bg-[#0A1D28] text-gray-600 text-center rounded-lg xl:w-80 mx-auto items-center content-center place-content-center align-middle hover:scale-105">
                                {/* <img  className="items-center hover:scale-110 transition duration-1000 ease-in-out h-12 mt-2 mb-4" src={appLogo} /> */}
                                <section class="max-w-screen-lg mx-auto ">
                                    <img class="mx-auto hover:scale-110 transition duration-1000 ease-in-out h-12 mt-2 mb-4" src={appLogo} />
                                </section>


                                <div className='text-md font-medium text-white'>
                                    <h5>{logo_title}</h5>
                                    <p className='text-gray-500'>{logo_address}</p>
                                </div>
                            </div>
                        </div>

                        {/* 2rd block */}
                        <div className="col-span-6 md:col-span-6 lg:col-span-1 ml-7 mx-auto">
                            <h6 className="text-[#45E6FC] text-xl font-bold mb-4">{services_title}</h6>
                            <ul className="text-md">
                                <li className="mb-2">
                                    <HashLink to="/#products" className="text-white hover:text-gray-300 hover:tracking-wider transition duration-250 ease-in-out">{services_web}</HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink to="/#services" className="text-white hover:text-gray-300 hover:tracking-wider transition duration-250 ease-in-out">{services_mobile}</HashLink>
                                </li>
                                {/* <li className="mb-2">
                                    <HashLink to="/#technologies" className="text-white hover:text-gray-300 hover:tracking-wider transition duration-250 ease-in-out">{services_uxui}</HashLink>
                                </li> */}

                                <li className="mb-2">
                                    <HashLink to="/#portfolio" className="text-white hover:text-gray-300 hover:tracking-wider transition duration-250 ease-in-out">{services_consult}</HashLink>
                                </li>
                            </ul>
                        </div>


                        {/* 3rd block */}
                        <div className="col-span-6 md:col-span-6 lg:col-span-4 mx-auto">
                            <h6 className="text-[#45E6FC] text-xl font-bold mb-4">{company_title} </h6>
                            <ul className="text-md">
                                <li className="mb-2">
                                    <HashLink to="/#hero" className="text-white hover:text-gray-300 hover:tracking-wider transition duration-250 ease-in-out">{company_about}</HashLink>
                                </li>
                                {/* <li className="mb-2">
                                    <HashLink to="/privacy-policy" className="text-white hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">{company_policy}</HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink to="#" className="text-white hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">{company_terms}</HashLink>
                                </li> */}

                                <li className="mb-2">
                                    <HashLink to="/contact#contact" className="text-white hover:text-gray-300 hover:tracking-wider transition duration-250 ease-in-out">{company_contact}</HashLink>
                                </li>
                            </ul>
                        </div>





                        {/* 4th block */}
                        {/* <div className="col-span-12 text-center mx-auto lg:col-span-3 font-bold uppercase text-[#45E6FC]">
                            <div className="text-xl mb-6">
                                {social_title}
                            </div>

                            <div className="text-md font-medium mb-6">
                                {social_subtitle}
                            </div>
                            <div className="mx-auto text-center mt-2">

                                <ul className="flex justify-center mb-4 md:mb-0">

                                    <li>

                                        <div className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Twitter">
                                            <a href="https://www.facebook.com/profile.php?id=61565405637229" target="_blank" >
                                                <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                                                </svg>
                                            </a>
                                        </div>

                                    </li>

                                    <li className="ml-4">

                                        <div className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Twitter">
                                            <a href="https://www.facebook.com/profile.php?id=61565405637229" target="_blank" >
                                                <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118 1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zm-6.142 0H6.87v12.139H3.095z"></path>
                                                </svg>
                                            </a>
                                        </div>

                                    </li>


                                </ul>
                            </div>
                        </div> */}

                    </div>

                    <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4 pb-4">
                        <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
                            <div className="text-sm text-gray-400 font-normal py-1">
                                Copyright &copy; {new Date().getFullYear()}{"  "} <b>ANB Electronics.</b> All rights reserved.
                                Made with ❤️ by
                                <a className=" hover:text-[#45E6FC]"
                                    href="https://www.codesuits.com"
                                    target='_blank'
                                >
                                    <b> CodeSuits </b>
                                </a>.
                            </div>
                        </div>
                    </div>

                </div>

            </footer>
        </>
    )
}
export default Footer;
