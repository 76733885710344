import React, { useState, useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import axios from 'axios';
// import emailjs from 'emailjs-com';
import Notiflix from 'notiflix';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';


import { useTranslation } from 'react-i18next';

import mongoDbImg from '../images/mongoImg.svg';
import CassandraImg from '../images/CassandraImg.svg';
import ElasticsearchImg from '../images/ElasticsearchImg.svg';
import KubernetesImg from '../images/KubernetesImg.svg';
import KafkaImg from '../images/KafkaImg.svg';
import BigQueryImg from '../images/BigQueryImg.svg';
import SnowflakeImg from '../images/SnowflakeImg.svg';
import tableauImg from '../images/tableauImg.svg';
import PowerBIImg from '../images/PowerBIImg.svg';
import QlikImg from '../images/QlikImg.svg';
import PyTorchImg from '../images/PyTorchImg.svg';
import tensorImg from '../images/tensorImg.svg';
import KerasImg from '../images/KerasImg.svg';
import pythonImg from '../images/pythonImg.svg';
import aiImage from '../images/dataImg.jpg'

import dataTechImage from '../images/dataTechImage.jpg'

// import aiVideo1 from '../videos/data1.mp4'
// import aiVideo2 from '../videos/data2.mp4'
// import aiVideo3 from '../videos/data3.mp4'

const DataInfo = () => {
//    useDocTitle('CodeSuits | Data Solutions')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [errors, setErrors] = useState([])

    const { t } = useTranslation()

    const {

        tech_data_title,
        tech_data_description1,
        tech_data_description2,
        tech_data_description3,
        tech_data_description4,
        tech_data_card1,
        tech_data_card1_description,
        tech_data_card1_description2,
        tech_data_card2,
        tech_data_card2_description,
        tech_data_card3,
        tech_data_card3_description,
        tech_data_bigdata1,
        tech_data_bigdata2,
        tech_data_mlai1,
        tech_data_mlai2,
        tech_data_letstalk,

    } = t("tech_pages.tech_data");


    const { ux_techstack,
        uxui_stack,
        ux_techstack1,
        ux_techstack2,
        ux_techstack3,
        ux_techstack4,
        ux_techstack5, } = t("portfolio_all.pages.ux");

    const { ui_techstack,
        ui_techstack1,
        ui_techstack2,
        ui_techstack3,
        ui_techstack4,
        ui_techstack5, } = t("portfolio_all.pages.ui");



    useEffect(() => {

        window.scrollTo(0, 0);
    }, []);

    const clearErrors = () => {
        setErrors([])
    }

    const clearInput = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPhone('')
        setMessage('')
    }

    const sendEmail = (e) => {
        e.preventDefault();
        document.getElementById('submitBtn').disabled = true;
        document.getElementById('submitBtn').innerHTML = 'Loading...';
        let fData = new FormData();
        fData.append('first_name', firstName)
        fData.append('last_name', lastName)
        fData.append('email', email)
        fData.append('phone_number', phone)
        fData.append('message', message)

        axios({
            method: "post",
            url: process.env.REACT_APP_CONTACT_API,
            data: fData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(function (response) {
                document.getElementById('submitBtn').disabled = true;
                document.getElementById('submitBtn').innerHTML = 'send message';
                clearInput()
                //handle success
                Notiflix.Report.success(
                    'Success',
                    response.data.message,
                    'Okay',
                );
            })
            .catch(function (error) {
                document.getElementById('submitBtn').disabled = true;
                document.getElementById('submitBtn').innerHTML = 'send message';
                //handle error
                const { response } = error;
                if (response.status === 500) {
                    Notiflix.Report.failure(
                        'An error occurred',
                        response.data.message,
                        'Okay',
                    );
                }
                if (response.data.errors !== null) {
                    setErrors(response.data.errors)
                }

            });
    }
    return (
        <>

            <Helmet>
                <title>CodeSuits | Data & AI Solutions</title>
                <meta
                    name='description'
                    content='Our Data experts will help plan and impleent for your business data-based and ml/ai solutions'
                />
                <meta
                    name='keywords'
                    content="data, database, artificial intelligence, ai, machine learning, ml, server, aws, gcp, azure, cloud, cloud-based solutions, cloud solutions, מידע, בינה מלאכותית, ענןֿ, דאטה, אחסון בענן"
                />
            </Helmet>



            <div>
                <NavBar />
            </div>


            <section class="py-10 bg-gray-100 sm:py-16 lg:py-24  ">


                <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 my-24"  >
                    <div class="max-w-2xl mx-auto text-center">
                        <h2 class="text-3xl font-bold leading-tight text-gray-800 sm:text-4xl lg:text-5xl">{tech_data_title}</h2>
                    </div>


                    <div class="max-w-2xl mx-auto text-center">

                        <p class="max-w-xl mx-auto mt-12 text-lg leading-relaxed text-gray-600"><b>{tech_data_description1}</b> </p>
                        <p>{tech_data_description2}</p>
                        <p>{tech_data_description3}</p>
                    </div>

                    <div class="max-w-2xl mx-auto text-center">

                        <p class="max-w-xl mx-auto mt-4 text-xl leading-relaxed text-gray-600"><b>{tech_data_description4}</b></p>

                    </div>
                </div>



                <div className="container mx-auto  px-4 lg:px-20" data-aos="zoom-in">

                    <div className="w-full bg-white p-8  md:px-12 lg:w-12/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">

                        <div class="grid max-w-xl grid-cols-1 mx-auto mt-8 text-center lg:max-w-full  sm:mt-12 lg:mt-20 lg:grid-cols-3  w-full gap-x-12 gap-y-6">


                            <div class="overflow-hidden bg-white rounded-2xl shadow-2xl ">
                                <div class="px-8 py-12">

                                    {/* <video autoPlay loop muted className="flex justify-center mx-auto">
                                        <source src={aiVideo1} type="video/mp4" />
                                    </video> */}
                                    <p class="text-base font-semibold tex-tblack mt-9">{tech_data_card1}</p>
                                    <p class="mt-1 text-base text-gray-600">{tech_data_card1_description}   </p> <p class="mt-1 text-base text-gray-600">{tech_data_card1_description2}</p>
                                </div>
                            </div>


                            <div class="overflow-hidden bg-white rounded-2xl shadow-2xl">
                                <div class="px-8 py-12">

                                    {/* <video autoPlay loop muted className="flex justify-center mx-auto">
                                        <source src={aiVideo2} type="video/mp4" />
                                    </video> */}
                                    <p class="text-base font-semibold tex-tblack mt-9">{tech_data_card2}</p>
                                    <p class="mt-1 text-base text-gray-600">{tech_data_card2_description}</p>
                                </div>
                            </div>

                            <div class="overflow-hidden bg-white rounded-2xl shadow-2xl">
                                <div class="px-8 py-12">

                                    {/* <video autoPlay loop muted className="flex justify-center mx-auto">
                                        <source src={aiVideo3} type="video/mp4" />
                                    </video> */}
                                    <p class="text-base font-semibold tex-tblack mt-9">{tech_data_card3}</p>
                                    <p class="mt-1 text-base text-gray-600">{tech_data_card3_description}</p>
                                </div>
                            </div>




                        </div>




                        <section class="pt-10 pb-8 overflow-hidden sm:pt-16 lg:pt-24">

                            <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">

                                <div class="max-w-2xl mx-auto text-center">
                                    <h4 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-4xl">
                                        {tech_data_bigdata1}</h4>

                                    <p class="max-w-xl mx-auto mt-12 text-lg leading-relaxed text-gray-600">{tech_data_bigdata2}</p>
                                </div>

                                <div className="flex lg:justify-end w-full mt-16" data-aos="fade-up" data-aos-delay="700">
                                    <img alt="card img" className="rounded-t float-right duration-1000 w-full" src={dataTechImage} />
                                </div>

                                {/* <img class="flex justify-center mx-auto py-12  mt-12 scale-150 max-w-7xl lg:min-w-0 lg:mt-0 lg:scale-100" src={dataTechImage} alt="" /> */}


                            </div>

                            <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">

                                <div class="max-w-2xl mx-auto text-center my-12">
                                    <h4 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-4xl">
                                        {tech_data_mlai1}</h4>

                                    <p class="max-w-xl mx-auto mt-12 text-lg leading-relaxed text-gray-600">{tech_data_mlai2}</p>
                                </div>

                                <div className="flex lg:justify-end w-full mt-16" data-aos="fade-up" data-aos-delay="700">
                                    <img alt="card img" className="rounded-t float-right duration-1000 w-full" src={aiImage} />
                                </div>

                                {/* <img class="flex justify-center mx-auto py-12  mt-12 scale-150 max-w-7xl lg:min-w-0 lg:mt-0 lg:scale-100" src={aiImage} alt="" /> */}

                            </div>


                            {/* ---  UX Tech Stack --- */}




                            <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">

                                <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left overflow-auto md:overflow-scroll w-full" data-aos="fade-up">


                                    <div className='flex justify-center items-center gap-8 mt-12' data-aos="zoom-in" data-aos-delay="500">

                                        <div className='flex-col justify-center items-center'>

                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={mongoDbImg} style={{ height: 42, width: 42 }} />
                                            </div>


                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                MongoDB</h3>
                                        </div>


                                        <div className='flex-col justify-center items-center'>

                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={CassandraImg} style={{ height: 42, width: 42 }} />
                                            </div>


                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                Cassadria</h3>
                                        </div>


                                        <div className='flex-col justify-center items-center'>

                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={ElasticsearchImg} style={{ height: 42, width: 42 }} />
                                            </div>


                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                ElasticSearch</h3>
                                        </div>

                                        <div className='flex-col justify-center items-center'>

                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={KubernetesImg} style={{ height: 42, width: 42 }} />
                                            </div>


                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                Kubertetics</h3>
                                        </div>

                                        <div className='flex-col justify-center items-center'>

                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={KafkaImg} style={{ height: 42, width: 42 }} />
                                            </div>


                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                Kafka</h3>
                                        </div>


                                        <div className='flex-col justify-center items-center'>

                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={BigQueryImg} style={{ height: 42, width: 42 }} />
                                            </div>


                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                BigQuery</h3>
                                        </div>

                                        <div className='flex-col justify-center items-center'>

                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={SnowflakeImg} style={{ height: 42, width: 42 }} />
                                            </div>


                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                SnowFlake</h3>
                                        </div>

                                        <div className='flex-col justify-center items-center'>

                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={tableauImg} style={{ height: 42, width: 42 }} />
                                            </div>


                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                Tableau</h3>
                                        </div>

                                        <div className='flex-col justify-center items-center'>

                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={PowerBIImg} style={{ height: 42, width: 42 }} />
                                            </div>


                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                PowerBI</h3>
                                        </div>



                                        <div className='flex-col justify-center items-center'>

                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={QlikImg} style={{ height: 42, width: 42 }} />
                                            </div>


                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                Qlik</h3>
                                        </div>

                                        <div className='flex-col justify-center items-center'>

                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={PyTorchImg} style={{ height: 42, width: 42 }} />
                                            </div>


                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                PyTorch</h3>
                                        </div>

                                        <div className='flex-col justify-center items-center'>

                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={tensorImg} style={{ height: 42, width: 42 }} />
                                            </div>


                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                TensorFlow</h3>
                                        </div>


                                        <div className='flex-col justify-center items-center'>

                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={KerasImg} style={{ height: 42, width: 42 }} />
                                            </div>


                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                Keras</h3>
                                        </div>


                                        <div className='flex-col justify-center items-center'>

                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={pythonImg} style={{ height: 42, width: 42 }} />
                                            </div>


                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                Python</h3>
                                        </div>


                                    </div>

                                </div>



                            </div>



                            <div class="max-w-2xl mx-auto text-center lg:pt-12">

                                <Link to="/contact" class="inline-flex items-center justify-center px-8 py-4 text-base font-semibold text-blue-900 transition-all duration-200 border-2 border-blue-900 rounded-md mt-9 hover:bg-blue-900 hover:text-white hover:border-blue-900 focus:bg-gray-900 focus:text-white focus:border-gray-900">
                                    {tech_data_letstalk}
                                </Link>

                            </div>
                        </section>



                    </div>
                </div >



            </section>







            <Footer />
        </>


    )
}

export default DataInfo;