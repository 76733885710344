import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import DemoProduct from './pages/DemoProduct';
import UCSquid from './pages/UCSquid';
import UCFastGrab from './pages/UCFastGrab';
import UCQuickSpace from './pages/UCQuickSpace';
import UCSaasly from './pages/UCSaasly';
import About from './pages/About';
import Process from './pages/Process';
import FAQ from './pages/FAQ';
import { useDocTitle } from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';
import WebDevInfo from './pages/WebDevelopment';
import MobileDevelopment from './pages/MobileDevelopment';
import DesignInfo from './pages/DesignInfo';
import ITConsultantInfo from './pages/ITConsultantInfo';
import DataInfo from './pages/DataInfo';
import CrossPlatformInfo from './pages/CrossPlatformInfo';
import SecurityInfo from './pages/SecurityInfo';
// import CloudInfo from './pages/CloudInfo';
import CaseStudySquid from "./pages/CaseStudySquid";
import CaseStudyHealthness from "./pages/CaseStudyHealthness";
import CaseStudyWeWear from "./pages/CaseStudyWeWear";
import CaseStudyFinTech from "./pages/CaseStudyFinTech";

import CaseStudyJustPrint from "./pages/CaseStudyJustPrint";
import CaseStudyBari from "./pages/CaseStudyBari";
import CaseStudyPets from "./pages/CaseStudyPets";
import CaseStudyQuickSpace from "./pages/CaseStudyQuickSpace";
import CaseStudySaasly from "./pages/CaseStudySaasly";
import CaseStudyLogo from "./pages/CaseStudyLogo";
import CaseStudyFastGrab from "./pages/CaseStudyFastGrab";
import CaseStudyRides from "./pages/CaseStudyRides";
import CaseStudyUX from "./pages/CaseStudyUX";
import CaseStudyUI from "./pages/CaseStudyUI";
import CaseStudyMarketing from "./pages/CaseStudyMarketing";
import CaseStudyDocs from "./pages/CaseStudyDocs";


import CaseStudyFoodily from "./pages/CaseStudyFoodily";
import CaseStudyRelax from "./pages/CaseStudyRelax";
import CaseStudyRovero from "./pages/CaseStudyRovero";
import CaseStudyAdel from "./pages/CaseStudyAdel";

import CaseStudyKing from "./pages/CaseStudyKing";
import CaseStudyPark from "./pages/CaseStudyPark";
import CaseStudyLanas from "./pages/CaseStudyLanas";
import CaseStudyTracht from "./pages/CaseStudyTracht";


import PrivacyPolicy from "./pages/PrivacyPolicy";
import LanguageSelector from "./translations/LanguageSelector";
import PortfolioPage from './pages/PortfolioPage';

// import HeroVideo from './components/HeroVideo';




import { Helmet } from 'react-helmet';
import Industries from './components/Industries';
import { useTranslation } from 'react-i18next';

import Sidebar from './components/Sidebar';

// https://www.youtube.com/watch?v=BHEPVdfBAqE&list=PLC3y8-rFHvwh-K9mDlrrcDywl7CeVL2rO
//import BackgroundAnimation, { varColor2x, varColor3x, varKenburnsTop, varPanTop } from './components/BackgroundAnimation';
//import { CssBaseline } from '@mui/material';




// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCsch4EhwQTvbhcrSmB8L4lldJXXm0WgvI",
  authDomain: "anb-dark.firebaseapp.com",
  projectId: "anb-dark",
  storageBucket: "anb-dark.firebasestorage.app",
  messagingSenderId: "488602432545",
  appId: "1:488602432545:web:e2fc69b0f0029aeb101f60",
  measurementId: "G-BGRQJDP629"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {

  const { t } = useTranslation()

  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
      logEvent(analytics, 'test_firebase_analytics_${1}');
    });
  }, []);

  useDocTitle("ANB | LED Solutions");


  return (



    <>
      <Helmet>
        <title>
          {t("description")}
        </title>
        <meta name='description' content='ANB - LED Solutions' />
        <meta name='keywords' content="ANB, LED, Electronics, LED solutions, 3D LEDS, screen, screens, display, panel" />
      </Helmet>

      {/* <div className='flex'>
        <SocialSideBar />
      </div> */}

      <Router>
        <ScrollToTop>

          <Routes>

          {/* <Sidebar>
              <Route path="/" element={<Home />} />
              <Route path="/contact" element={<Contact />} />
          </Sidebar>
 */}

            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/get-demo" element={<DemoProduct />} />
            <Route path="/usecase-squid" element={<UCSquid />} />
            <Route path="/usecase-quickspace" element={<UCQuickSpace />} />
            <Route path="/usecase-fastgrab" element={<UCFastGrab />} />
            <Route path="/usecase-saasly" element={<UCSaasly />} />
            <Route path="/service-webdev" element={<WebDevInfo />} />
            <Route path="/service-mobiledev" element={<MobileDevelopment />} />
            <Route path="/service-design" element={<DesignInfo />} />
            <Route path="/service-consultant" element={<ITConsultantInfo />} />

            <Route path="/about" element={<About />} />
            <Route path="/process" element={<Process />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/tech-data" element={<DataInfo />} />
            {/* <Route path="/tech-cloud" element={<CloudInfo />} /> */}
            <Route path="/tech-cross" element={<CrossPlatformInfo />} />
            <Route path="/tech-security" element={<SecurityInfo />} />


            <Route path="/casestudy-squid" element={<CaseStudySquid />} />
            <Route path="/casestudy-healthness" element={<CaseStudyHealthness />} />
            <Route path="/casestudy-wewear" element={<CaseStudyWeWear />} />
            <Route path="/casestudy-fintech" element={<CaseStudyFinTech />} />

            <Route path="/casestudy-print" element={<CaseStudyJustPrint />} />
            <Route path="/casestudy-bari" element={<CaseStudyBari />} />
            <Route path="/casestudy-pets" element={<CaseStudyPets />} />
            <Route path="/casestudy-quickspace" element={<CaseStudyQuickSpace />} />
            <Route path="/casestudy-saasly" element={<CaseStudySaasly />} />
            <Route path="/casestudy-logo" element={<CaseStudyLogo />} />
            <Route path="/casestudy-grab" element={<CaseStudyFastGrab />} />
            <Route path="/casestudy-rides" element={<CaseStudyRides />} />
            <Route path="/casestudy-ux" element={<CaseStudyUX />} />
            <Route path="/casestudy-ui" element={<CaseStudyUI />} />
            <Route path="/casestudy-marketing" element={<CaseStudyMarketing />} />
            <Route path="/casestudy-docs" element={<CaseStudyDocs />} />

            <Route path="/casestudy-foodily" element={<CaseStudyFoodily />} />
            <Route path="/casestudy-relax" element={<CaseStudyRelax />} />
            <Route path="/casestudy-rovero" element={<CaseStudyRovero />} />
            <Route path="/casestudy-adel" element={<CaseStudyAdel />} />

            <Route path="/casestudy-king" element={<CaseStudyKing />} />
            <Route path="/casestudy-park" element={<CaseStudyPark />} />
            <Route path="/casestudy-lanas" element={<CaseStudyLanas />} />
            <Route path="/casestudy-tracht" element={<CaseStudyTracht />} />

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/industries" element={<Industries />} />
            <Route path="/language" element={<LanguageSelector />} />
            <Route path="/portfolio-all" element={<PortfolioPage />} />
            <Route path="/casesutdy_mobile" element={<PortfolioPage />} />

            {/* <Route path="/hero-video" element={<HeroVideo />} /> */}

            

          </Routes>
        </ScrollToTop>
      </Router>

    </>
  );
}


export default App;



// # BEGIN Newfold File Cache
// <IfModule mod_rewrite.c>
// 	RewriteEngine On
// 	RewriteBase /website_7d25e894/
// 	RewriteRule ^/wp-content/newfold-page-cache// - [L]
// 	RewriteCond %{REQUEST_METHOD} !POST
// 	RewriteCond %{QUERY_STRING} !.*=.*
// 	RewriteCond %{HTTP_COOKIE} !(wordpress_test_cookie|comment_author|wp\-postpass|wordpress_logged_in|wptouch_switch_toggle|wp_woocommerce_session_) [NC]
// 	RewriteCond %{HTTP:Cache-Control} ^((?!no-cache).)*$
// 	RewriteCond %{DOCUMENT_ROOT}/wp-content/newfold-page-cache//$1/_index.html -f
// 	RewriteRule ^(.*)$ /wp-content/newfold-page-cache//$1/_index.html [L]
// </IfModule>
// # END Newfold File Cache
// # BEGIN Newfold Headers
// <IfModule mod_headers.c>
// 	Header set X-Newfold-Cache-Level "2"
// </IfModule>
// # END Newfold Headers
// # BEGIN Newfold Browser Cache
// <IfModule mod_expires.c>
// 	ExpiresActive On
// 	ExpiresDefault "access plus 24 hours"
// 	ExpiresByType text/html "access plus 2 hours"
// 	ExpiresByType image/jpg "access plus 24 hours"
// 	ExpiresByType image/jpeg "access plus 24 hours"
// 	ExpiresByType image/gif "access plus 24 hours"
// 	ExpiresByType image/png "access plus 24 hours"
// 	ExpiresByType text/css "access plus 24 hours"
// 	ExpiresByType text/javascript "access plus 24 hours"
// 	ExpiresByType application/pdf "access plus 1 week"
// 	ExpiresByType image/x-icon "access plus 1 year"
// </IfModule>
// # END Newfold Browser Cache
// # BEGIN Newfold Skip 404 Handling for Static Files
// <IfModule mod_rewrite.c>
// 	RewriteEngine On
// 	RewriteCond %{REQUEST_FILENAME} !-f
// 	RewriteCond %{REQUEST_FILENAME} !-d
// 	RewriteCond %{REQUEST_URI} !(robots\.txt|ads\.txt|[a-z0-9_\-]*sitemap[a-z0-9_\.\-]*\.(xml|xsl|html)(\.gz)?)
// 	RewriteCond %{REQUEST_URI} \.(css|htc|less|js|js2|js3|js4|html|htm|rtf|rtx|txt|xsd|xsl|xml|asf|asx|wax|wmv|wmx|avi|avif|avifs|bmp|class|divx|doc|docx|eot|exe|gif|gz|gzip|ico|jpg|jpeg|jpe|webp|json|mdb|mid|midi|mov|qt|mp3|m4a|mp4|m4v|mpeg|mpg|mpe|webm|mpp|otf|_otf|odb|odc|odf|odg|odp|ods|odt|ogg|ogv|pdf|png|pot|pps|ppt|pptx|ra|ram|svg|svgz|swf|tar|tif|tiff|ttf|ttc|_ttf|wav|wma|wri|woff|woff2|xla|xls|xlsx|xlt|xlw|zip)$ [NC]
// 	RewriteRule .* - [L]
// </IfModule>
// # END Newfold Skip 404 Handling for Static Files

// # BEGIN WordPress
// # The directives (lines) between "BEGIN WordPress" and "END WordPress" are
// # dynamically generated, and should only be modified via WordPress filters.
// # Any changes to the directives between these markers will be overwritten.
// <IfModule mod_rewrite.c>
// RewriteEngine On
// RewriteRule .* - [E=HTTP_AUTHORIZATION:%{HTTP:Authorization}]
// RewriteBase /
// RewriteRule ^index\.php$ - [L]
// RewriteCond %{REQUEST_FILENAME} !-f
// RewriteCond %{REQUEST_FILENAME} !-d
// RewriteRule . /index.php [L]
// </IfModule>

// # END WordPress

// # php -- BEGIN cPanel-generated handler, do not edit
// # Set the “ea-php83” package as the default “PHP” programming language.
// <IfModule mime_module>
//   AddHandler application/x-httpd-ea-php83___lsphp .php .php8 .phtml
// </IfModule>
// # php -- END cPanel-generated handler, do not edit