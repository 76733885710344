import React, { useState, useEffect } from 'react';
import NavBar from './Navbar/NavBar';
import Footer from './Footer';
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';


import './CSHealthIntro.css'

import phpLogo from '../images/casestudy/phpLogo.svg';
import nodejsLogo from '../images/casestudy/nodejsLogo.svg';
import awsLogo from '../images/casestudy/awsLogo.svg';
import kotlinLogo from '../images/casestudy/kotlinLogo.svg';
import swiftLogo from '../images/casestudy/swiftLogo.svg';
import pythonLogo from '../images/casestudy/pythonLogo.svg';

import squidImg1 from '../images/casestudy/squidImg1.jpg';
import squidImg2 from '../images/casestudy/squidImg2.jpg';
import squidImg3 from '../images/casestudy/squidImg3.jpg';


const PrivacyPolicyInfo = () => {

    useEffect(() => {

        window.scrollTo(0, 0);
    }, []);


    return (

        <>
            <div className="squid" id='squid'>
                <div>
                    <NavBar />
                </div>

                {/* ---  INTRO  ---*/}


                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>

                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>




                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h1 className="mb-5 md:text-5xl text-3xl font-bold text-blue-900">
                                Privacy Policy</h1>
                            <div>
                            </div>
                        </div>
                    </div>
                </div>



                {/* ---  DESCRIPTION  ---*/}


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' style={{ backgroundColor: '#F3F4F5' }}>


                    <div className="flex  lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full  px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                            </h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when
                                    You use the Service and tells You about Your privacy rights and how the law protects You.
                                    We use Your Personal data to provide and improve the Service.
                                    By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</p>
                            </div>
                        </div>
                    </div>



                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full  px-8" data-aos="zoom-in" data-aos-delay="500">
                        <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                            Interpretation and Definitions</h3>


                        <h5 className="mb-4 md:text-2xl text-3xl font-bold text-blue-900">
                            Interpretation</h5>


                        <div>
                            <p className='my-3 text-xl text-gray-600 font-semibold'>The words of which the initial letter is capitalized have meanings defined under the following conditions.
                                The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
                        </div>


                        <h5 className="mb-4 md:text-2xl text-3xl font-bold text-blue-900">
                            Definitions</h5>


                        <div>
                            <p className='my-3 text-xl text-gray-600 font-semibold'>The Company may use Personal Data for the following purposes:</p>
                        </div>

                        <div>
                            <p className='my-3 text-xl text-gray-600 font-semibold'>



                                You means the individual accessing or using the Service, or the company,
                                or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.


                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <p>
                                    Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to </p>
                                <p><b>ANB Electronics Ltd., Soi Rewat 8, Bangkok, Thailand.</b></p>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>



                                Affiliate means an entity that controls, is controlled by or is under common control with a party,
                                where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for
                                election of directors or other managing authority.- Account means a unique account created for You to access our
                                Service or parts of our Service.


                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>


                                <p>
                                    Website refers to ANB, accessible from www.anbelectronics.com
                                    Service refers to the Website.
                                    Country refers to: Thailand
                                </p>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                Service Provider means any natural or legal person who processes the data on behalf of the Company.
                                It refers to third-party companies or individuals employed by the Company to facilitate the Service,
                                to provide the Service on behalf of the Company, to perform services related to the Service or
                                to assist the Company in analyzing how the Service is used.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>


                                <p> <span>
                                    Third-party Social Media Service refers to any website or any social network website through which a User can log in
                                    or create an account to use the Service.
                                    Personal Data is any information that relates to an identified or identifiable individual.
                                    Cookies are small files that are placed on Your computer, mobile device or any other device by a website,
                                    containing the details of Your browsing history on that website among its many uses.
                                    Device means any device that can access the Service such as a computer,
                                    a cellphone or a digital tablet.
                                    Usage Data refers to data collected automatically, either generated by the use of the Service
                                    or from the Service infrastructure itself (for example, the duration of a page visit).
                                </span></p>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                Collecting and Using Your Personal Data
                                Types of Data Collected
                                Personal Data
                                While using Our Service, We may ask You to provide Us with certain personally identifiable information
                                that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:- Usage Data

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                Usage Data
                                Usage Data is collected automatically when using the Service.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address),
                                browser type, browser version, the pages of our Service that You visit,
                                the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>
                                When You access the Service by or through a mobile device, We may collect certain information automatically, including,
                                but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device,
                                Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                We may also collect information that Your browser sends whenever You visit our Service or when You access
                                the Service by or through a mobile device.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                Tracking Technologies and Cookies
                                We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information.
                                Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent.
                                However, if You do not accept Cookies, You may not be able to use some parts of our Service.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer
                                or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser.
                                Learn more about cookies: All About Cookies.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                We use both session and persistent Cookies for the purposes set out below:

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                Necessary / Essential Cookies‍
                                Type: Session Cookies
                                Administered by: Us
                                Purpose: These Cookies are essential to provide You with services available through the Website and to enable You
                                to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts.
                                Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies
                                to provide You with those services.
                                Cookies Policy / Notice Acceptance Cookies‍
                                Type: Persistent Cookies
                                Administered by: Us
                                Purpose: These Cookies identify if users have accepted the use of cookies on the Website.
                                Functionality Cookies‍
                                Type: Persistent Cookies
                                Administered by: Us
                                Purpose: These Cookies allow us to remember choices You make when You use the Website,
                                such as remembering your login details or language preference.
                                The purpose of these Cookies is to provide You with a more personal experience and to avoid You having
                                to re-enter your preferences every time You use the Website.‍
                                Tracking and Performance Cookies‍
                                Type: Persistent Cookies
                                Administered by: Third-Parties
                                Purpose: These Cookies are used to track information about traffic to the Website and how users use the Website.
                                The information gathered via these Cookies may directly or indirectly identify you as an individual visitor.
                                This is because the information collected is typically linked to a pseudonymous identifier
                                associated with the device you use to access the Website. We may also use these Cookies to test new advertisements,
                                pages, features or new functionality of the Website to see how our users react to them.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                For more information about the cookies we use and your choices regarding cookies, please visit our
                                Cookies Policy or the Cookies section of our Privacy Policy.


                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                Use of Your Personal Data
                                The Company may use Personal Data for the following purposes:

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                To Provide and maintain our Service, including to monitor the usage of our Service.
                                To manage Your Account: to manage Your registration as a user of the Service.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                The Personal Data You provide can give You access to different functionalities of the Service that are available to
                                You as a registered user.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products,
                                items or services You have purchased or of any other contract with Us through the Service.
                                To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication,
                                such as a mobile application's push notifications regarding updates or informative communications related
                                to the functionalities, products or contracted services, including the security updates, when necessary or
                                reasonable for their implementation.
                                To provide You with news, special offers and general information about other goods, services and events which
                                we offer that are similar to those that you have already purchased or enquired about unless You have opted
                                not to receive such information.
                                We may share your personal information in the following situations:

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                With Service Providers: We may share Your personal information with Service Providers to monitor and
                                analyze the use of our Service, to advertise on third party websites to You after You visited our Service, to contact You.
                                For Business transfers: We may share or transfer Your personal information in connection with,
                                or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of our
                                business to another company.
                                With Affiliates: We may share Your information with Our affiliates, in which case we will require those affiliates
                                to honor this Privacy Policy.
                                Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that
                                We control or that are under common control with Us.
                                With Business partners: We may share Your information with Our business partners to offer You certain products,
                                services or promotions.
                                With other users: when You share personal information or otherwise interact in the public areas with
                                other users, such information may be viewed by all users and may be publicly distributed outside.
                                If You interact with other users or register through a Third-Party Social Media Service,
                                Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description
                                of Your activity. Similarly, other users will be able to view descriptions of Your activity,
                                communicate with You and view Your profile.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <h5 className="mb-4 md:text-2xl text-3xl font-bold text-blue-900">
                                Retention of Your Personal Data</h5>



                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy.
                                We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations
                                (for example, if we are required to retain your data to comply with applicable laws),
                                resolve disputes, and enforce our legal agreements and policies.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a
                                shorter period of time, except when this data is used to strengthen the security or to improve the functionality of
                                Our Service, or We are legally obligated to retain this data for longer time periods.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <h5 className="mb-4 md:text-2xl text-3xl font-bold text-blue-900">
                                Transfer of Your Personal Data</h5>

                                

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                Your information, including Personal Data, is processed at the Company's operating offices and in any other
                                places where the parties involved in the processing are located. It means that this information may be
                                transferred to — and maintained on — computers located outside of Your state, province, country or
                                other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in
                                accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization
                                or a country unless there are adequate controls in place including the security of Your data and other personal information.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <h5 className="mb-4 md:text-2xl text-3xl font-bold text-blue-900">
                                Disclosure of Your Personal Data</h5>


                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <h5 className="mb-4 md:text-2xl text-3xl font-bold text-blue-900">
                                Business transactions</h5>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred.
                                We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                Law enforcement
                                Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so
                                by law or in response to valid requests by public authorities (e.g. a court or a government agency).

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                Other legal requirements
                                The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                * Comply with a legal obligation

                                * Protect and defend the rights or property of the Company

                                * Prevent or investigate possible wrongdoing in connection with the Service

                                * Protect the personal safety of Users of the Service or the public

                                * Protect against legal liability

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <h5 className="mb-4 md:text-2xl text-3xl font-bold text-blue-900">
                                Security of your personal data</h5>

                                

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet,
                                or method of electronic storage is 100% secure. While We strive to use commercially acceptable means
                                to protect Your Personal Data, We cannot guarantee its absolute security.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                Detailed Information on the Processing of Your Personal Data

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                Service Providers have access to Your Personal Data only to perform their tasks on Our behalf and are obligated
                                not to disclose or use it for any other purpose.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <h5 className="mb-4 md:text-2xl text-3xl font-bold text-blue-900">
                                Analytics</h5>
                                

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                Google Analytics‍
                                Google Analytics is a web analytics service offered by Google that tracks and reports website traffic.
                                Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services.
                                Google may use the collected data to contextualise and personalise the ads of its own advertising network.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>


                                You can opt-out of having made your activity on the Service available to Google Analytics by
                                installing the Google Analytics opt-out browser add-on.
                                The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing information with
                                Google Analytics about visits activity.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page:

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                https://policies.google.com/privacy

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                Behavioral Remarketing
                                The Company uses remarketing services to advertise on third party websites to You after You visited our Service.
                                We and Our third-party vendors use cookies to inform, optimize and serve ads based on Your past visits to our Service.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                Google Ads (AdWords)
                                Google Ads (AdWords) remarketing service is provided by Google Inc.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network ads
                                by visiting the Google Ads Settings page: http://www.google.com/settings/ads‍

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                Google also recommends installing the Google Analytics Opt-out Browser Add-on -
                                https://tools.google.com/dlpage/gaoptout - for your web browser.
                                Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from
                                being collected and used by Google Analytics.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                For more information on the privacy practices of Google,
                                please visit the Google Privacy & Terms web page: https://policies.google.com/privacy

                                Facebook
                                Facebook remarketing service is provided by Facebook Inc.
                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                Facebook adheres to the Self-Regulatory Principles for Online Behavioral Advertising established
                                by the Digital Advertising Alliance. You can also opt-out from Facebook and other participating companies
                                through the Digital Advertising Alliance in the USA http://www.aboutads.info/choices/,
                                the Digital Advertising Alliance of Canada in Canada http://youradchoices.ca/ or the
                                European Interactive Digital Advertising Alliance in Europe http://www.youronlinechoices.eu/, or opt-out
                                using your mobile device settings.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                For more information on the privacy practices of Facebook, please visit Facebook's Data Policy:
                                https://www.facebook.com/privacy/explanation

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                Your California Privacy Rights (California's Shine the Light law)
                                Under California Civil Code Section 1798 (California's Shine the Light law), California residents with an
                                established business relationship with us can request information once a year about sharing their Personal Data
                                with third parties for the third parties' direct marketing purposes.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                If you'd like to request more information under the California Shine the Light law, and if you are a California resident,
                                You can contact Us using the contact information provided below.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                California Privacy Rights for Minor Users (California Business and Professions Code Section 22581)

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                California Business and Professions Code section 22581 allow California residents under the age of 18
                                who are registered users of online sites, services or applications to request and obtain removal of
                                content or information they have publicly posted.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                To request removal of such data, and if you are a California resident, You can contact Us using the contact information
                                provided below, and include the email address associated with Your account.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                Be aware that Your request does not guarantee complete or comprehensive removal of content or
                                information posted online and that the law may not permit or require removal in certain circumstances.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                Links to Other Website
                                Our Service may contain links to other websites that are not operated by Us. If You click on a third party link,
                                You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                We have no control over and assume no responsibility for the content, privacy policies or practices of
                                any third party sites or services.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                Changes to this Privacy Policy
                                We may update our
                                Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming
                                effective and update the "Last updated" date at the top of this Privacy Policy.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy
                                are effective when they are posted on this page.

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>



                                <b>Contact Us</b>
                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>
                                If you have any questions about these privacy policies, You can contact us by email: <b>sales@anbelectronics.com</b>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>


                            </p>
                        </div>




                    </div>



                </div>






            </div >
        </>
    )

}

export default PrivacyPolicyInfo;




// teleporting  https://play.teleporthq.io/projects/portfolio-page-bmoc3s/editor/9fa467fc-86c5-4001-96ba-81fb58f06cb4

