import React from 'react';

import img1 from '../images/services/services_item1.jpg';
import img2 from '../images/services/services_item2.jpg';
import img3 from '../images/services/services_item3.jpg';
import img4 from '../images/services/services_item4.jpg';


import WebDevInfo from '../pages/WebDevelopment';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';


const Services = () => {

    const { t } = useTranslation();
    const { title, description , description2 } = t("services");

    const { web_title, web_description } = t("services.webdev");
    const { mobile_title, mobile_description } = t("services.mobile");
    const { design_title, design_description } = t("services.design");
    const { consult_title, consult_description } = t("services.consult");
    const { entertainment_title, entertainment_description } = t("products.entertainment");

    const { build_title1, build_title2, build_description } = t("services.build");
    const { collaborate_title1, collaborate_title2, collaborate_description } = t("services.collaborate");


    return (
        <div id="services" className="bg-black py-12 bg-cover "   >

            <section data-aos="zoom-in-down">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-white uppercase font-bold">
                        {title}
                    </h2>

                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-white'></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-normal text-[#45E6FC]">
                        {description}
                    </h2>
                    <p className=" mx-12 text-center text-lg lg:text-lg font-normal text-white">
                        {description2}
                    </p>
                </div>



                <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4  ">

                        {/* <Link to="/" className="bg-black transition-all ease-in-out duration-400 border-2 border-white overflow-hidden text-white hover:bg-[#1F3763] hover:text-white shadow-2xl m-10 group">
                            <div className="m-2 text-justify text-sm ">
                                <img alt="card img" className="group-hover:scale-125 scale-80 transition duration-1000 ease-in-out" src={img1} />
                                <h2 className="font-semibold pt-16 pb-4 text-2xl text-center">
                                    {web_title}
                                </h2>
                            </div>
                        </Link> */}


                        <div to="/" className="bg-black transition-all ease-in-out duration-400 border-2 border-white overflow-hidden text-white hover:bg-[#1695A6] hover:text-white shadow-2xl m-10 group">
                            <div className="m-2 text-justify text-sm ">
                                <img alt="card img" className="group-hover:scale-125 scale-80 transition duration-1000 ease-in-out" src={img1} />
                                <h2 className="font-semibold pt-16 pb-4 text-2xl text-center">
                                    {web_title}
                                </h2>
                            </div>
                        </div>

                        <div to="/" className="bg-black transition-all ease-in-out duration-400 border-2 border-white overflow-hidden text-white hover:bg-[#1695A6] hover:text-white shadow-2xl m-10 group">
                            <div className="m-2 text-justify text-sm ">
                                <img alt="card img" className="group-hover:scale-125 scale-80 transition duration-1000 ease-in-out" src={img2} />
                                <h2 className="font-semibold pt-16 pb-4 text-2xl text-center ">
                                    {mobile_title}
                                </h2>
                            </div>
                        </div>

                        <div to="/" className="bg-black transition-all ease-in-out duration-400 border-2 border-white overflow-hidden text-white hover:bg-[#1695A6] hover:text-white shadow-2xl m-10 group">
                            <div className="m-2 text-justify text-sm ">
                                <img alt="card img" className="group-hover:scale-125 scale-80 transition duration-1000 ease-in-out" src={img3} />
                                <h2 className="font-semibold pt-16 pb-4 text-2xl text-center ">
                                    {design_title}
                                </h2>
                            </div>
                        </div>



                        <div to="/" className="bg-black transition-all ease-in-out duration-400 border-2 border-white overflow-hidden text-white hover:bg-[#1695A6] hover:text-white shadow-2xl m-10 group">
                            <div className="m-2 text-justify text-sm ">
                                <img alt="card img" className="group-hover:scale-125 scale-80 transition duration-1000 ease-in-out" src={img4} />
                                <h2 className="font-semibold pt-16 pb-4 text-2xl text-center ">
                                    {entertainment_title}
                                </h2>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


            <section>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                        <div className="my-4 py-4">
                            <h3 className="my-2 text-center text-3xl text-[#45E6FC] uppercase font-bold"> {build_title1}<span className='font-black'>  </span></h3>
                            <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-regular text-white">{build_description}</h2>
                        </div>
                        <div className="my-4 py-4">
                            <h3 className="my-2 text-center text-3xl text-[#45E6FC] uppercase font-bold"> {collaborate_title1}<span className='font-black'>  </span></h3>
                            <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-regular text-white">{collaborate_description}</h2>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    )
}

export default Services;