import React from 'react';


//import bgImg from '../images/background/led-background.jpg';
import img1 from '../images/products/product-item1.jpg';
import img2 from '../images/products/product-item2.jpg';
import img3 from '../images/products/product-item3.jpg';
import img4 from '../images/products/product-item4.jpg';
import img5 from '../images/products/product-item5.jpg';
import img6 from '../images/products/product-item6.jpg';


import WebDevInfo from '../pages/WebDevelopment';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';


const Products = () => {

    const { t } = useTranslation();
    const { title, description, description2 } = t("products");
    const { web_title, web_description } = t("products.webdev");
    const { mobile_title, mobile_description } = t("products.mobile");
    const { design_title, design_description } = t("products.design");

    const { entertainment_title, entertainment_description } = t("products.entertainment");
    const { linear_title, linear_description } = t("products.linear");
    const { media_title, media_description } = t("products.media");

    const { consult_title, consult_description } = t("products.consult");
    const { build_title, build_description } = t("products.build");
    const { collaborate_title, collaborate_description } = t("products.collaborate");


    //bg-[url(/src/images/background/led-background.jpg)]
    //style={{'background-image':'url(/src/images/background/led-background.jpg)'}}

    return (
        <div id="products" className="bg-black py-12 bg-cover bg-[url(/src/images/background/led-background.jpg)]"   >

            <section data-aos="zoom-in-down">
                
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-white uppercase font-bold">
                        {title}
                    </h2>

                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-white'></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-normal text-[#45E6FC]">
                        {description}
                    </h2>
                    <p className=" mx-12 text-center text-lg lg:text-lg font-normal text-white">
                        {description2}
                    </p>
                </div>



                <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-3 lg:grid-cols-3  ">

                        {/* <Link to="/" className="bg-black transition-all ease-in-out duration-400 border-2 border-white overflow-hidden text-white hover:bg-[#1F3763] hover:text-white shadow-2xl m-10 group">
                            <div className="m-2 text-justify text-sm ">
                                <img alt="card img" className="group-hover:scale-125 scale-80 transition duration-1000 ease-in-out" src={img1} />
                                <h2 className="font-semibold pt-16 pb-4 text-2xl text-center">
                                    {web_title}
                                </h2>
                            </div>
                        </Link> */}


                        <div to="/" className="bg-black transition-all ease-in-out duration-400 border-2 border-white overflow-hidden text-white hover:bg-[#1F3763] hover:text-white shadow-2xl m-10 group">
                            <div className="m-2 text-justify text-sm ">
                                <img alt="card img" className="group-hover:scale-125 scale-80 transition duration-1000 ease-in-out" src={img1} />
                                <h2 className="font-semibold pt-16 pb-4 text-2xl text-center">
                                    {web_title}
                                </h2>
                            </div>
                        </div>

                        <div to="/" className="bg-black transition-all ease-in-out duration-400 border-2 border-white overflow-hidden text-white hover:bg-[#1F3763] hover:text-white shadow-2xl m-10 group">
                            <div className="m-2 text-justify text-sm ">
                                <img alt="card img" className="group-hover:scale-125 scale-80 transition duration-1000 ease-in-out" src={img2} />
                                <h2 className="font-semibold pt-16 pb-4 text-2xl text-center ">
                                    {mobile_title}
                                </h2>
                            </div>
                        </div>

                        <div to="/" className="bg-black transition-all ease-in-out duration-400 border-2 border-white overflow-hidden text-white hover:bg-[#1F3763] hover:text-white shadow-2xl m-10 group">
                            <div className="m-2 text-justify text-sm ">
                                <img alt="card img" className="group-hover:scale-125 scale-80 transition duration-1000 ease-in-out" src={img3} />
                                <h2 className="font-semibold pt-16 pb-4 text-2xl text-center ">
                                    {design_title}
                                </h2>
                            </div>
                        </div>



                        <div to="/" className="bg-black transition-all ease-in-out duration-400 border-2 border-white overflow-hidden text-white hover:bg-[#1F3763] hover:text-white shadow-2xl m-10 group">
                            <div className="m-2 text-justify text-sm ">
                                <img alt="card img" className="group-hover:scale-125 scale-80 transition duration-1000 ease-in-out" src={img4} />
                                <h2 className="font-semibold pt-16 pb-4 text-2xl text-center ">
                                    {entertainment_title}
                                </h2>
                            </div>
                        </div>




                        <div to="/" className="bg-black transition-all ease-in-out duration-400 border-2 border-white overflow-hidden text-white hover:bg-[#1F3763] hover:text-white shadow-2xl m-10 group">
                            <div className="m-2 text-justify text-sm ">
                                <img alt="card img" className="group-hover:scale-125 scale-80 transition duration-1000 ease-in-out" src={img5} />
                                <h2 className="font-semibold pt-16 pb-4 text-2xl text-center ">
                                    {linear_title}
                                </h2>
                            </div>
                        </div>



                        <div to="/" className="bg-black transition-all ease-in-out duration-400 border-2 border-white overflow-hidden text-white hover:bg-[#1F3763] hover:text-white shadow-2xl m-10 group">
                            <div className="m-2 text-justify text-sm ">
                                <img alt="card img" className="group-hover:scale-125 scale-80 transition duration-1000 ease-in-out" src={img6} />
                                <h2 className="font-semibold pt-16 pb-4 text-2xl text-center ">
                                    {media_title}
                                </h2>
                            </div>
                        </div>



                        {/* <Link to="/" className="bg-black transition-all ease-in-out duration-400 border-2 border-white overflow-hidden text-white hover:bg-[#1F3763] hover:text-white shadow-2xl m-10 group">
                            <div className="m-2 text-justify text-sm ">
                                <img alt="card img" className="group-hover:scale-125 scale-80 transition duration-1000 ease-in-out" src={img2} />
                                <h2 className="font-semibold pt-20 text-2xl text-center ">
                                    {mobile_title}
                                </h2>
                            </div>
                        </Link> */}


                        {/* <Link to="/" className="bg-black transition-all ease-in-out duration-400 border-2 border-white overflow-hidden text-white hover:bg-[#1F3763] hover:text-white shadow-2xl m-10 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="group-hover:scale-[1.0] scale-50 transition duration-1000 ease-in-out" src={img3} />
                                <h2 className="font-semibold pt-4 pb-4 text-2xl text-center">
                                    {design_title}
                                </h2>
                            </div>
                        </Link> */}

                        {/* <Link to="/" className="bg-black transition-all ease-in-out duration-400 border-2 border-white overflow-hidden text-white hover:bg-[#1F3763] hover:text-white shadow-2xl m-10 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="group-hover:scale-[1.0] scale-50 transition duration-1000 ease-in-out" src={img4} />
                                <h2 className="font-semibold pt-4 pb-4 text-2xl text-center">
                                    {linear_title}
                                </h2>
                            </div>
                        </Link> */}






                        {/* <Link to="/" className="bg-black transition-all ease-in-out duration-400 border-2 border-white overflow-hidden text-white hover:bg-[#1F3763] hover:text-white shadow-2xl m-10 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="group-hover:scale-[1.0] scale-50 transition duration-1000 ease-in-out" src={img5} />
                                <h2 className="font-semibold pt-4 pb-4 text-2xl text-center">
                                    {linear_title}
                                </h2>
                            </div>
                        </Link>

                        <Link to="/" className="bg-black transition-all ease-in-out duration-400 border-2 border-white overflow-hidden text-white hover:bg-[#1F3763] hover:text-white shadow-2xl m-10 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="group-hover:scale-[1.0] scale-50 transition duration-1000 ease-in-out" src={img6} />
                                <h2 className="font-semibold pt-4 pb-4 text-2xl text-center">
                                    {media_title}
                                </h2>
                            </div>
                        </Link> */}




                    </div>
                </div>
            </section>


            {/*
            <div className="my-4 py-4">
                <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">{title}</h2>

                <div className='flex justify-center'>
                    <div className='w-24 border-b-4 border-blue-900'></div>
                </div>
                <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">{description}</h2>
            </div>
            */}

            <section>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                        <div className="my-4 py-4">
                            <h3 className="my-2 text-center text-3xl text-[#45E6FC] uppercase font-bold"> {build_title}<span className='font-black'>  </span></h3>
                            <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-regular text-white">{build_description}</h2>
                        </div>
                        <div className="my-4 py-4">
                            <h3 className="my-2 text-center text-3xl text-[#45E6FC] uppercase font-bold"> {collaborate_title}<span className='font-black'>  </span></h3>
                            <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-regular text-white">{collaborate_description}</h2>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    )
}

export default Products;