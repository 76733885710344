import React, { useState, useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import axios from 'axios';
// import emailjs from 'emailjs-com';
import Notiflix from 'notiflix';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

import dataLogo from '../images/tech_data.svg';
import dataImg from '../images/tech-data.jpg';

import { useTranslation } from 'react-i18next';

// partners
import foodilyImg from '../images/casestudy/foodily-log.jpg';
import squidLogo from '../images/squid-192.svg';
import wearLogo from '../images/casestudy/wewear-logo.jpg';
import printLogo from '../images/casestudy/printLogo.png';
import pureLogo from '../images/casestudy/pure-logo.jpg';
import roveroLogo from '../images/casestudy/rovero-logo.jpg';
import adelLogo from '../images/casestudy/adellogo.jpg';
import kingLogo from '../images/casestudy/kingLogo.jpg';
import parkLogo from '../images/casestudy/parkLogo.jpg';
import lanasLogo from '../images/casestudy/lanasLogo.jpg';
import trachtLogo from '../images/casestudy/trachtLogo.jpg';
import shukLogo from '../images/casestudy/shukLogo.png';
import petsLogo from '../images/casestudy/petsLogo.png';
import qspaceLogo from '../images/casestudy/qspaceLogo.jpg';
import saaslyLogo from '../images/casestudy/saaslyLogo.jpg';
import ipsumLogo from '../images/casestudy/ipsumLogo.jpg';
import grabLogo from '../images/casestudy/grabLogo.png';
import healthLogo from '../images/casestudy/health-logo.jpg';
import ridesLogo from '../images/casestudy/ridesLogo.jpg';
import netpayLogo from '../images/casestudy/netpayLogo.jpg';


import mapImg from '../images/casestudy/mapImg.jpg'
import aboutImg from '../images/aboutImg.jpg'

import aboutCEO from '../images/casestudy/aboutCEO.jpg'
import aboutCTO from '../images/casestudy/aboutCTO.jpg'
import aboutCOO from '../images/casestudy/aboutCOO.jpg'
import aboutCFO from '../images/casestudy/aboutCFO.jpg'
import aboutCRO from '../images/casestudy/aboutCRO.jpg'
import aboutVPD from '../images/casestudy/aboutVPDesign.jpg'

// import aiVideo2 from '../videos/cloud2.mp4'
// import aiVideo3 from '../videos/cloud3.mp4'


import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import './About.css';

const swiperOptions = {
    modules: [Autoplay, Pagination, Navigation],
    slidesPerView: 6,
    spaceBetween: 30,
    navigation: false,
    autoplay: {
        delay: 500,
        disableOnInteraction: true,
        reverseDirection: false,
    },
    loop: true,

    breakpoints: {
        320: {
            slidesPerView: 1,
            // spaceBetween: 30, 225
        },
        575: {
            slidesPerView: 1,
            // spaceBetween: 30, 162
        },
        767: {
            slidesPerView: 2,
            // spaceBetween: 30, 
        },
        991: {
            slidesPerView: 3,
            // spaceBetween: 30,
        },
        1199: {
            slidesPerView: 4,
            // spaceBetween: 30,
        },
        1350: {
            slidesPerView: 6,
            // spaceBetween: 30,
        },
    }


};

const swiperOptions2 = {
    modules: [Autoplay, Pagination, Navigation],
    slidesPerView: 6,
    spaceBetween: 30,
    navigation: false,
    autoplay: {
        delay: 500,
        disableOnInteraction: true,
        reverseDirection: true,
    },
    loop: true,

    breakpoints: {
        320: {
            slidesPerView: 1,
            // spaceBetween: 30, 225
        },
        575: {
            slidesPerView: 1,
            // spaceBetween: 30, 162
        },
        767: {
            slidesPerView: 2,
            // spaceBetween: 30, 
        },
        991: {
            slidesPerView: 3,
            // spaceBetween: 30,
        },
        1199: {
            slidesPerView: 4,
            // spaceBetween: 30,
        },
        1350: {
            slidesPerView: 6,
            // spaceBetween: 30,
        },
    }


};

const About = () => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [errors, setErrors] = useState([])

    const { t } = useTranslation()



    const {

        about_title,
        about_subtitle,
        about_started_title,
        about_started_description,
        about_history,
        about_history_title,
        about_history_description,
        about_history_description2,

        about_global_title,
        about_global_description,

        about_leadership_title,
        about_leadership_description,

        about_partners_title,
        about_partners_description,


    } = t("company_pages.about");


    const {


        tech_data_title,
        tech_data_description1,
        tech_data_description2,
        tech_data_description3,
        tech_data_description4,
        tech_data_card1,
        tech_data_card1_description,
        tech_data_card1_description2,
        tech_data_card2,
        tech_data_card2_description,
        tech_data_card3,
        tech_data_card3_description,
        tech_data_bigdata1,
        tech_data_bigdata2,
        tech_data_mlai1,
        tech_data_mlai2,
        tech_data_letstalk,

    } = t("tech_pages.tech_data");


    const { ux_techstack,
        uxui_stack,
        ux_techstack1,
        ux_techstack2,
        ux_techstack3,
        ux_techstack4,
        ux_techstack5, } = t("portfolio_all.pages.ux");

    const { ui_techstack,
        ui_techstack1,
        ui_techstack2,
        ui_techstack3,
        ui_techstack4,
        ui_techstack5, } = t("portfolio_all.pages.ui");



    useEffect(() => {

        window.scrollTo(0, 0);
    }, []);

    const clearErrors = () => {
        setErrors([])
    }

    const clearInput = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPhone('')
        setMessage('')
    }

    const sendEmail = (e) => {
        e.preventDefault();
        document.getElementById('submitBtn').disabled = true;
        document.getElementById('submitBtn').innerHTML = 'Loading...';
        let fData = new FormData();
        fData.append('first_name', firstName)
        fData.append('last_name', lastName)
        fData.append('email', email)
        fData.append('phone_number', phone)
        fData.append('message', message)

        axios({
            method: "post",
            url: process.env.REACT_APP_CONTACT_API,
            data: fData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(function (response) {
                document.getElementById('submitBtn').disabled = true;
                document.getElementById('submitBtn').innerHTML = 'send message';
                clearInput()
                //handle success
                Notiflix.Report.success(
                    'Success',
                    response.data.message,
                    'Okay',
                );
            })
            .catch(function (error) {
                document.getElementById('submitBtn').disabled = true;
                document.getElementById('submitBtn').innerHTML = 'send message';
                //handle error
                const { response } = error;
                if (response.status === 500) {
                    Notiflix.Report.failure(
                        'An error occurred',
                        response.data.message,
                        'Okay',
                    );
                }
                if (response.data.errors !== null) {
                    setErrors(response.data.errors)
                }

            });
    }
    return (
        <>

            <link rel="canonical" href="https://anbelectronics.com//about"></link>
            <Helmet>
                <title>ANB | About</title>
                <meta
                    name='description'
                    content='Our Data experts will help plan and impleent for your business data-based and ml/ai solutions'
                />
                <meta
                    name='keywords'
                    content="data, database, artificial intelligence, ai, machine learning, ml, server, aws, gcp, azure, cloud, cloud-based solutions, cloud solutions, מידע, בינה מלאכותית, ענןֿ, דאטה, אחסון בענן"
                />
            </Helmet>



            <div>
                <NavBar />
            </div>


            <section class="py-10 bg-black sm:py-16 lg:py-24  ">
                <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 my-24"  >
                </div>
                <div className="container mx-auto  px-4 lg:px-20" data-aos="zoom-in">

                    <div className="w-full bg-black border border-[#45E6FC] p-8  md:px-12 lg:w-12/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">

                        <section class="pt-10 pb-8 overflow-hidden sm:pt-16 lg:pt-24">

                            <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">

                                <div class="max-w-2xl mx-auto text-center">
                                    <h2 class="text-3xl font-bold leading-tight text-white sm:text-4xl lg:text-5xl uppercase">{about_title}</h2>
                                </div>


                                <div class="max-w-2xl mx-auto text-center">

                                    <p class="max-w-xl mx-auto mt-6 text-lg leading-relaxed text-[#45E6FC]">{about_subtitle}</p>
                                </div>

                                <div className="flex lg:justify-end w-full mt-16" data-aos="fade-up" data-aos-delay="700">
                                    <img alt="card img" className="rounded-t float-right duration-1000 w-full hover:scale-110" src={aboutImg} />
                                </div>

                            </div>


                            <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl mt-12">

                                <div class="max-w-2xl mx-auto text-center my-12 ">
                                    <h4 class="text-3xl font-bold leading-tight text-white sm:text-4xl lg:text-4xl ">
                                        {about_history_title}</h4>

                                    <div className='border border-white rounded-xl p-2 mt-12'>
                                        <p class="max-w-xl mx-auto mt-6 text-lg leading-relaxed text-[#45E6FC]">{about_history_description}</p>
                                        <p class="max-w-xl mx-auto mt-6 mb-6 text-lg leading-relaxed text-[#45E6FC]"><b> {about_history_description2} </b></p>

                                    </div>
                                </div>
                            </div>



                            <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">

                                <div class="max-w-2xl mx-auto text-center my-12">
                                    <h4 class="text-3xl font-bold leading-tight text-white sm:text-4xl lg:text-4xl">
                                        {about_global_title}</h4>


                                    <div className='border border-white rounded-xl p-2 mt-12'>
                                        <p class="max-w-xl mx-auto mt-6 text-lg leading-relaxed text-[#45E6FC]">{about_global_description}</p>
                                        <p class="max-w-xl mx-auto text-lg leading-relaxed text-[#45E6FC]">{about_global_description}</p>
                                        <p class="max-w-xl mx-auto mb-6 text-lg leading-relaxed text-[#45E6FC]">{about_global_description}</p>

                                    </div>

                                </div>

                                {/* google map  */}

                                <section className='pt-24 '>
                                    <div className="container-fluid border-8 border-[#45E6FC] p-0">
                                        <div className="row">
                                            {/* Google Map HTML Codes */}
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3877.563732613751!2d100.61927197604302!3d13.623436200250588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d5f007e366ebb%3A0x842f5c43a789c009!2sA.N.B.%20Electronics%20Co.%2C%20LTD.!5e0!3m2!1sen!2sil!4v1738133738565!5m2!1sen!2sil" width="100%" height="450" frameborder="0" allowfullscreen=""></iframe>
                                        </div>
                                    </div>
                                </section>

                            </div>

                            {/* ---  Team  --- */}


                            {/* <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">

                                <div class="max-w-2xl mx-auto text-center my-12">
                                    <h4 class="text-3xl font-bold leading-tight text-white sm:text-4xl lg:text-4xl">
                                        {about_leadership_title}</h4>

                                    <p class="max-w-xl mx-auto mt-12 text-lg leading-relaxed text-white">{about_leadership_description}</p>
                                </div>


                                <div class="grid max-w-xl grid-cols-1 mx-auto mt-8 text-center lg:max-w-full  sm:mt-12 lg:mt-20 lg:grid-cols-3  w-full gap-x-12 gap-y-6">


                                    <div class="overflow-hidden bg-white rounded-2xl shadow-2xl ">
                                        <div class="px-8 py-12">

                                            <img class="flex justify-center mx-auto hover:scale-105 scale-100" src={aboutCEO} alt=""  />

                                            <p class="text-base font-semibold tex-tblack mt-9">Blue Pattrawdee</p>
                                            <p class="mt-1 text-base text-gray-600"> CEO & Co-Founder</p>
                                        </div>
                                    </div>


                                    <div class="overflow-hidden bg-white rounded-2xl shadow-2xl">
                                        <div class="px-8 py-12">

                                            <img class="flex justify-center mx-auto hover:scale-105 scale-100" src={aboutCTO} alt="" />

                                            <p class="text-base font-semibold tex-tblack mt-9">Evgeny Zapisotsky</p>
                                            <p class="mt-1 text-base text-gray-600">CTO & Co-Founder</p>
                                        </div>
                                    </div>

                                    <div class="overflow-hidden bg-white rounded-2xl shadow-2xl">
                                        <div class="px-8 py-12">

                                            <img class="flex justify-center mx-auto hover:scale-105 scale-100" src={aboutVPD} alt="" />

                                            <p class="text-base font-semibold tex-tblack mt-9">Debora Levy</p>
                                            <p class="mt-1 text-base text-gray-600">CDO</p>
                                        </div>
                                    </div>

                                </div>

 
                            </div> */}


                            {/* ---  Partners Stack --- */}


                            {/* <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">

                                <div class="max-w-2xl mx-auto text-center my-24">
                                    <h4 class="text-3xl font-bold leading-tight text-white sm:text-4xl lg:text-4xl">
                                        {about_partners_title}</h4>

                                    <p class="max-w-xl mx-auto mt-12 text-lg leading-relaxed text-gray-600">{about_partners_description}</p>
                                </div>


                            </div> */}



                            <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl my-28">
                                <div class="max-w-2xl mx-auto text-center my-12">
                                </div>
                            </div>

                            {/* ---  1st line  --- */}

                            {/* <div className="auto-container " >
                                <Swiper {...swiperOptions} className="clients-carousel owl-carousel owl-theme ">

                                    <SwiperSlide className="client-block">
                                        <div className='flex-col justify-center items-center'>
                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={foodilyImg} style={{ height: 76, width: 76 }} />
                                            </div>

                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className="client-block">
                                        <div className='flex-col justify-center items-center'>
                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={squidLogo} style={{ height: 76, width: 76 }} />
                                            </div>

                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className="client-block">
                                        <div className='flex-col justify-center items-center'>
                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={wearLogo} style={{ height: 76, width: 76 }} />
                                            </div>

                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className="client-block">
                                        <div className='flex-col justify-center items-center'>
                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={printLogo} style={{ height: 76, width: 76 }} />
                                            </div>

                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className="client-block">
                                        <div className='flex-col justify-center items-center'>
                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={pureLogo} style={{ height: 76, width: 76 }} />
                                            </div>


                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className="client-block">
                                        <div className='flex-col justify-center items-center'>
                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={roveroLogo} style={{ height: 76, width: 76 }} />
                                            </div>

                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className="client-block">
                                        <div className='flex-col justify-center items-center'>
                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={kingLogo} style={{ height: 76, width: 76 }} />
                                            </div>


                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div> */}



                            {/* ---  2nd line  --- */}

                            {/* <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl my-14">
                                <div class="max-w-2xl mx-auto text-center my-12">
                                </div>
                            </div> */}

                            {/* <div className="auto-container " >
                                <Swiper {...swiperOptions2} className="clients-carousel owl-carousel owl-theme ">

                                    <SwiperSlide className="client-block">
                                        <div className='flex-col justify-center items-center'>
                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={healthLogo} style={{ height: 76, width: 76 }} />
                                            </div>
                                        </div>
                                    </SwiperSlide>



                                    <SwiperSlide className="client-block">
                                        <div className='flex-col justify-center items-center'>
                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={parkLogo} style={{ height: 76, width: 76 }} />
                                            </div>


                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className="client-block">
                                        <div className='flex-col justify-center items-center'>
                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={lanasLogo} style={{ height: 76, width: 76 }} />
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className="client-block">
                                        <div className='flex-col justify-center items-center'>
                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={trachtLogo} style={{ height: 76, width: 76 }} />
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className="client-block">
                                        <div className='flex-col justify-center items-center'>
                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={shukLogo} style={{ height: 76, width: 76 }} />
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className="client-block">
                                        <div className='flex-col justify-center items-center'>
                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={petsLogo} style={{ height: 76, width: 76 }} />
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className="client-block">
                                        <div className='flex-col justify-center items-center'>
                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={netpayLogo} style={{ height: 76, width: 76 }} />
                                            </div>

                                        </div>
                                    </SwiperSlide>

                                </Swiper>
                            </div> */}



                            <div class="max-w-2xl mx-auto text-center lg:pt-2">

                                <Link to="/contact" class="inline-flex items-center justify-center px-8 py-4 text-base font-semibold text-[#45E6FC] transition-all duration-200 border-2 border-[#45E6FC] rounded-md mt-9 hover:text-white hover:border-white focus:bg-gray-900 focus:text-white focus:border-gray-900">
                                    {tech_data_letstalk}
                                </Link>

                            </div>
                        </section>



                    </div>
                </div >



            </section>







            <Footer />
        </>


    )
}

export default About;