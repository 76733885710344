import { React, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';                     /* Translations - RTL handling */
import { HashLink } from "react-router-hash-link";
import heroVideo from '../images/background/hero-background.jpg';
import './HeroVideo.css'
import { Link } from 'react-router-dom';

const HeroVideo = () => {

    const [isOpen, setOpen] = useState(false)

    //https://youtu.be/-79ahr9CrY0

    const { t } = useTranslation()

    useEffect(() => {
        const dir = i18n.dir(i18n.language);
        document.documentElement.dir = dir;
    }, [i18n, i18n.language]);

    return (
        <>

            <section
                // className="overflow-hidden bg-[url('/src/images/background/hero-background.jpg')] bg-cover bg-top bg-no-repeat h-screen"
               id='hero-video'  className="overflow-hidden bg-[url('/src/videos/hero-video-background.gif')] bg-cover bg-top bg-no-repeat h-screen"
            >

                <div className=" p-8 md:p-12 lg:px-40 lg:py-48">

                    <div className="text-center ltr:sm:text-center rtl:sm:text-center pb-28">

                        {/* <div className="grid sm:grid-cols-2 lg:grid-cols-3 mt-36" > */}
                        <div className="flex justify-center mt-36" >

                            <div className="border border-[#45E6FC] rounded-xl p-12 ">
                                <h1 className="text-5xl font-extrabold text-white sm:text-5xl md:text-5xl">{t("title")}</h1>

                                <h2 className="text-4xl font-bold text-[#45E6FC] sm:text-5xl md:text-4xl pt-6">{t("title2")}</h2>

                                <div className="text-3xl font-bold text-white sm:text-3xl md:text-3xl pt-2">{t("title3")}</div>

                                <p className="pt-4 max-w-lg text-white/90 md:mt-6 md:block md:text-lg md:leading-relaxed">
                                {t("title4")}
                                </p>
                            </div>

                        </div>


                        <div className="video-box z-1 pt-4 " >
                            <figure className="video-image pt-32 pb-40">
                                <div className="flex align-bottom ">
                                    <HashLink smooth to="/#hero" className="lightbox-image overlay-box-hero"><span className="flaticon-down-icon" ><i className="ripple"></i></span> <i className="icon fas fa-play p-60" aria-hidden="true"></i> </HashLink>
                                </div>
                            </figure>
                        </div>



                    </div>


                </div>


            </section>


            {/* <div className="relative text-center ">
                <img className="h-screen w-full"
                    src={heroVideo}
                />


                <div className="  absolute top-0  text-center mt-10">

                    <h2 className="text-4xl font-bold text-red-500 text-center">
                        TailwindCSS + React
                    </h2>

                    <button className="mt-10 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Test Button
                    </button>

                    <div className="video-box z-1">
                        <figure className="video-image">
                            <div className="flex align-bottom ">
                            <HashLink smooth to="/#hero" className="lightbox-image "><span className="flaticon-down-icon" ><i className="ripple"></i></span> <i className="icon fas fa-play p-60" aria-hidden="true"></i> </HashLink>
                            </div>
                        </figure>
                    </div>

                </div>
            </div> */}

            {/* <div className="flex items-center justify-center " id='herovideo'>
                <div className="relative block w-full h-screen bg-gray-900 ">

                    <div className="video-box z-1">

                        <figure className="video-image">

                            <div className="absolute text-white">
                                test
                            </div>


                            <div className="flex align-bottom ">
                                <HashLink smooth to="/#hero" className="lightbox-image overlay-box-hero"><span className="flaticon-down-icon" ><i className="ripple"></i></span> <i className="icon fas fa-play p-60" aria-hidden="true"></i> </HashLink>
                            </div>

                            <img className="video-hero" src={heroVideo} />

                        </figure>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default HeroVideo;

