import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';


i18n.use(LanguageDetector).use(initReactI18next).init({
    debug: true,
    lng: "en",
    fallbackLng: "en",
    returnObjects: true,
    resources: {

        en: {
            translation: {
                title: "LED THE GAMES BEGIN!",
                title2: "ANB's LED DISPLAYS",
                title3: "Tailored Solutions",
                title4: "Our customized hardware and software solutions deliver seamless reliability, scalable to immersive visuals.",
                

                description: "SIMPLE AND RELIABLE",
                subtitle: "We offer dependable LED display solutions designed to meet your basic needs with ease and efficiency.",
                learnmore: "Learn more",
                casestudy: "Our products",
                introtitle: "BREATHTAKING AND IMMERSIVE",
                introdescription: {
                    line1: "We customize stunning LED formats that seamlessly integrate into your environment,",
                    //line2: "Our team is well vast in software development and is ready to help develop the applications of your choice.",
                    line2: "delivering unforgettable, immersive experiences.",
                    contact: "Contact us",

                },

                navbar: {
                    home: "Home",
                    products: "Products",
                    services: "Services",
                    tech: "Technologies",
                    industries: "Industries",
                    projects: "Projects",
                    company: "Company",
                    company_about: "About us",
                    company_process: "Process",
                    company_faq: "FAQ",
                    contact: "Contact Us",
                    lang: "English",
                    schedule: "Contact us",
                },
                

                products: {
                    title: "OUR Products",
                    description: "From simple to stunning",
                    description2: "Contact us to explore our versatile range of product solutions",
                    
                    webdev: {
                        web_title: "INDOOR",
                        web_description: "We specialize in creating and optimizing high-quality, custom websites and saas-based web applications for businesses and organizations of all sizes. Building mobile-friendly and easy-to-use websites and applications for clients.",
                    },
                    mobile: {
                        mobile_title: "OUTDOOR",
                        mobile_description: "We develop high-quality, custom cross-platform mobile applications that are robust and optimized for performance, scalability, and accessibility.",
                    },
                    design: {
                        design_title: "SMART LIGHTING (IOT)",
                        design_description: "We create and consult on making inspiring ux/ui designs. Our experienced and creative designers will help enriching the experience of your users inside your products.",
                    },

                    entertainment: {
                        entertainment_title: "ENTERTAINMENT",
                        entertainment_description: "We create and consult on making inspiring ux/ui designs. Our experienced and creative designers will help enriching the experience of your users inside your products.",
                    },

                    linear: {
                        linear_title: "LINEAR / STRIP / NEON FLEX",
                        linear_description: "We create and consult on making inspiring ux/ui designs. Our experienced and creative designers will help enriching the experience of your users inside your products.",
                    },

                    media: {
                        media_title: "MEDIA FACADE",
                        media_description: "We create and consult on making inspiring ux/ui designs. Our experienced and creative designers will help enriching the experience of your users inside your products.",
                    },



                    consult: {
                        consult_title: "WE CONSULT",
                        consult_description: "Our IT consultations service provides expert guidance and support for businesses and individuals looking to optimize their technology solutions.",
                    },
                    build: {
                        build_title: "WE BUILD",
                        build_description: "With over 20 years of experience in software analysis and design, and a comprehensive understanding of the latest IT trends and solutions, we offer tailored recommendations and strategies to enhance your operations, optimize costs, and drive efficiency.",
                    },
                    collaborate: {
                        collaborate_title: "COLLABORATION",
                       
                        collaborate_description: "We collaborate with your existing technology team to scale current software applications or design custom solutions tailored to your daily requirements, streamlining and simplifying various processes.",
                       // collaborate_description: "We can collaborate with your existing tech team to scale existing software applications or design customized software applications that suits your everyday need and simplifies various processes.",
                    },
                },

                services: {
                    title: "OUR SERVICES",
                    description: "Expert and Reliable",
                    description2: "Contact us to discover our comprehensive services",

                

                    webdev: {
                        web_title: "INSTALLATIONS",
                        web_description: "We specialize in creating and optimizing high-quality, custom websites and saas-based web applications for businesses and organizations of all sizes. Building mobile-friendly and easy-to-use websites and applications for clients.",
                    },
                    mobile: {
                        mobile_title: "MAINTENANCE",
                        mobile_description: "We develop high-quality, custom cross-platform mobile applications that are robust and optimized for performance, scalability, and accessibility.",
                    },
                    design: {
                        design_title: "CONSULTATION",
                        design_description: "We create and consult on making inspiring ux/ui designs. Our experienced and creative designers will help enriching the experience of your users inside your products.",
                    },
                    consult: {
                        consult_title: "REGULATIONS",
                        consult_description: "Our IT consultations service provides expert guidance and support for businesses and individuals looking to optimize their technology solutions.",
                    },


                    build: {
                        build_title1: "WE DELIVER",
                        build_title2: "Build",
                        build_description: "With over 20 years of experience in software analysis and design, and a comprehensive understanding of the latest IT trends and solutions, we offer tailored recommendations and strategies to enhance your operations, optimize costs, and drive efficiency.",
                    },
                    collaborate: {
                        collaborate_title1: "WE CONSULT",
                        collaborate_title2: "Collaborate",
                       
                        collaborate_description: "We collaborate with your existing technology team to scale current software applications or design custom solutions tailored to your daily requirements, streamlining and simplifying various processes.",
                       // collaborate_description: "We can collaborate with your existing tech team to scale existing software applications or design customized software applications that suits your everyday need and simplifies various processes.",
                    },
                },

                company_pages: {

                    about: {
                        about_title: "About us",
                        about_subtitle: "We are a team of experienced people with a shared passion to provide our customers with the best software solutions.",

                        about_history_title: "How it started",
                        about_history_description: "During 2022 Yuval Zohar and Evgeny Zapisotsky, joined forces with a group of developers from Ukraine to build Web and Mobile software solutions for multiple clients in Europe and the US.",
                        about_history_description2: "Since then, CodeSuits has steadily grown, increasing its expertise with updated technologies and helping more clients reach their business goals.", 
                         
                        about_global_title: "We are a Thai company working globally",
                        about_global_description: "Both our clients and company's team are globally distributed",

                        about_leadership_title: "Our Leadership",
                        about_leadership_description: "",

                        about_partners_title: "Our Customers",
                        about_partners_description: "",
                    },


                    process: {
                        process_title: "Work Process",
                        process_subtitle: "We have built a flexible and customized work process in order to adapt ourselves to the requirements of each client with minimum time and maximum quality.",
                        process_phase1_title: "Phase 1: Understanding customer's requirements (Discovery)",
                        process_phase1_description1: "Our product, R&D and UX/UI managers will meet with your team to understand your business goals and software requirements.",
                        process_phase1_description2: "We will dive deep into your goals and requirements to avoid possible delays during the work.",

                        process_phase2_title: "Phase 2: Providing an implementation offer",
                        process_phase2_description1: "Research, technical architecture and design prototype.",
                        process_phase2_description2: "We conduct the relevant research in terms of the required architecture and the technical stack. If relevant to the requirements, our UX/UI designer will create an initial prototype for the solution.",
                        process_phase2_schedule_title: "Working time: In average 2-4 weeks",
                        process_phase2_schedule_description: "*Depend on the complexity of the requirements and needed implementation",

                        process_phase3_title: "Phase 3: Start working on your product!",
                        process_phase3_description1: "CodeSuits'es R&D and Desiners start work",
                        process_phase3_description2: "Based on the plans prepared in phase 2, we start working on the solution. The work can be either to extend the existing code with your teams, or to build everything ourselves. The work included constant synchronization meetings.",
                        process_phase3_schedule_title: "Working time: will be calculated during phase 2",
                        process_phase3_schedule_description: "",

                        process_phase4_title: "Phase 4: Testing (QA)",
                        process_phase4_description1: "Testing the product before any release.",
  
                        process_phase4_description2: "Our testing engineers perform a various tests, like, Unit testing, E2E, regression, load and perforance to verify compliance with the original requirements. We support manual and automation testings.",

//                        process_phase4_description2: "Our testers perform a series of tests to make sure the product meets the various requirements. We perform various tests according to the set of tests defined in advance. From the level of unit testing of each programmer to end-to-end tests and regression to make sure that previous development is not broken. In addition to functionality, we verify compliance with loads, performance, etc.. The tests include manual and automatic tests.",
                        
                        process_phase4_schedule_title: "Working time: will be calculated during phase 2",
                        process_phase4_schedule_description: "",

                        process_phase5_title: "Phase 5: Release",
                        process_phase5_description1: "Preparing the software before its release.",
                        process_phase5_description2: "During the final process of testing and fixing the bugs, we will help you as necessary to prepare the product for its release to customers. Our design people will sit down with you and help prepare the details required for this stage of the process (for example: graphics for release notes, emails to customers, campaigns and documentation)",
                    
                    },



                    faq: {
                        faq_title: "Frequently Asked Questions",
                        faq_subtitle: "",

                        faq_general_title: "General",
                        faq_general_q1: "Are you GDPR compliant?",
                        faq_general_a1: "Yes. We've put systems and processes in place at CodeSuits to comply.",
                        faq_general_q2: "How well does your team know English?",
                        faq_general_a2: "The level of English proficiency is at least upper Intermediate. Our team constantly improves English skills by studying with native speaker tutor.",
                        faq_general_q3: "I'm looking for one developer. Can you help?",
                        faq_general_a3: "Yes. You may hire just one developer. Let us know if you are interested in building out your team in the future.",
                        faq_general_q4: "Where will my software development team be located?",
                        faq_general_a4: "CodeSuits is a global company. Our team is located mostly in Israel and Ukraine. We encourage our clients to pay us a visit and bring their team together for a workshop.",

                        faq_pricing_title: "Pricing",
                        faq_pricing_q1: "Do you charge fixed price or rate per hour?",
                        faq_pricing_a1: "We will send you our indicative rates per hour by request. Contact us for project estimation.",
                        faq_pricing_q2: "What are the average rates for software developers?",
                        faq_pricing_a2: "Pricing is very rely on the required task, but in general, IT nearshoring prices in Ukraine vary between $45 and $75 per hour.",
                        faq_pricing_q3: "What are the common payment terms?",
                        faq_pricing_a3: "Primarily we use Time and Material model with monthly invoicing. You will get invoice based on the efforts during the previous month.",
                        faq_pricing_q4: "How to estimate my project budget?",
                        faq_pricing_a4: "Please send us a request with a description of your project and we'll provide initial cost estimation.",

                        faq_support_title: "Support",
                        faq_support_q1: "What tech professionals can you provide to extend my team?",
                        faq_support_a1: "Developers, QA engineers, business analysts, scrum masters, project managers, DevOps engineers, UX/UI designers, support engineers to name a few.",
                        faq_support_q2: "How quickly will I get a response from CodeSuits?",
                        faq_support_a2: "We will do our best to contact you within 12 hours. In exceptional cases we might need 24 hours.",
                        faq_support_q3: "I have a startup idea. Can you develop custom product for me?",
                        faq_support_a3: "Absolutely. We deliver full-cycle custom product development with further product support and maintenance.",
                        faq_support_q4: "What kind of tech support do you provide?",
                        faq_support_a4: "We provide thorough third-line product support: modifications and updates after the software product delivery.",
                         

                    },


                },


                services_pages: {

                    webdev: {
                        web_title: "Web Development",
                        web_subtitle1: "Web development, with its various solutions, is important for every business. From a small coffee shop, having a simple website to grow their audience, to an online banking system that allows its users to make secure transactions worldwide.",
                        web_subtitle2: "WE BUILD TAILOR-MADE WEB APPS TO TARGET OUR CUSTOMER'S GOALS",

                        websites: {
                            website_title: "Websites",
                            website_subtitle: "User friendly attractive sites",
                            website_description: "We build different types of websites (e.g. Business, E-commerce, Booking) integrated with tools to change its content dynamically and expose it to a large scale of customers.",
                        },

                        ecommerce: {
                            ecommerce_title: "E-commerce sites",
                            ecommerce_subtitle: "Your online shop",
                            ecommerce_description: "At CodeSuits we build custom e-commerce sites, related to payment, content and other services to help your business increase its sales.",
                        },

                        webapp: {
                            webapp_title: "Web applications",
                            webapp_subtitle: "Cloud-based solutions",
                            webapp_description: "Our cloud team will help your business build a cloud-base web application, including: cloud architecture design, configurations and development.",
                        },

                        saas: {
                            saas_title: "SaaS",
                            saas_subtitle: "Software as a Service solution",
                            saas_description: "We build B2C and B2B SaaS-based products with a focus on performance, load balancing, security and other cloud-based concerns.",
                        },

                        webmobile: {
                            webmobile_title: "Web Mobile apps",
                            webmobile_subtitle: "Responsive Web applications",
                            webmobile_description: "We develop at CodeSuits responsive-wise Web applications to scale it with mobile and tablet devices.",
                        },

                        webdata: {
                            webdata_title: "Web data management",
                            webdata_subtitle: "Data-driven Web applications",
                            webdata_description: "We build data-driven solutions using big data, ML and AI technologies to improve the performance of our customers' products.",
                        },
                        web_case_study: "check our case studies",
                    },

                    mobile: {
                        mobile_title: "Mobile Development",
                        mobile_subtitle1: "Nowdays, a mobile app is the best way to engage with your users.",
                        mobile_subtitle2: "Why? because they are always there..",
                        mobile_subtitle3: "We build at CodeSuits an amazing tailor-made Android and iPhone mobile apps.",

                        native: {
                            native_title: "Native applications",
                            native_description: "We develop android and ios apps using native languages (e.g. kotlin, swift).",
                        },
                        cross: {
                            cross_title: "Cross-platform applications",
                            cross_description1: "A cross-platform solution, allows developing once and deploy simultaneously on a multiple platforms. This is why it is a great way to reduce the delevelopment time of your products, and assist with your company's go-to-market strategy.",
                            cross_description2: "CodeSuits uses cross-platforms solutions like React Native and Flutter.",
                        },
                        sdk: {
                            sdk_title: "SDK",
                            sdk_description: "We create android and ios sdk (e.g. java, kotlin and swift), and mobile libraries in native languages (e.g. c/c++ and python).",
                        },
                        hollistic: {
                            hollistic_title: "Holistic solution",
                            hollistic_description: "A whole cross-platform customized solution with your mobile and cloud-based interface & services.",
                        },
                        contact: "LET'S TALK!",
                    },

                },


                tech: {
                    title: "Our Technologies",
                    description: "From proven reliability to cutting-edge innovation",
                    description2: "Contact us to explore our full range of available technologies",
                    data: {
                        data_title: "Data, ML & AI",
                        data_description: "Nowdays, data is a crucial part for every business that needs to be handled properly. We specialize in developing, managing and analyzing data. Whether your business needs a big-data solutions, or, an AI features, our data scientists, data developers and BI experts, will build, or, help your teams with planning and implementing it properly.",
                    },
                    cloud: {
                        cloud_title: "Cloud & DevOps Technologies",
                        cloud_description: "We help SMBs and large corporations target and resolve their cloud technology challanges. As AWS certified partners, our cloud experts will help your business building a cloud-based solution taking in mind performance, security, migration, transformation and computing strartegies.",
                    },
                    cross: {
                        cross_title: "Cross Platforms",
                        cross_description: "Cross platform solutions are great to reduce development time and help your business move faster with its go-to-market strategy. At CodeSuits, we work with multiple cross-platform technologies.",
                    },
                    security: {
                        security_title: "Security and Privacy",
                        security_description: "Security and data privacy are crucial aspects to handle carefully, while your business is keep growing. Aligned with most being used security and privacy standards, our experts will help you building the right solutions.",
                    },
                    creative: {
                        creative_title1: "We",
                        creative_title2: "Creative",
                        creative_description: "Our highly skilled and innovative UX/UI designers create and enhance designs that integrate industry best practices and modern trends, delivering experiences that captivate and engage your customers and partners.",

                        //                        creative_description: "Our well experienced and creative UX/UI designers, will create and improve designs, combined with the worlds best practices and modern trends, that will inspire your customers and partners.",
                    },
                    innovative: {
                        innovative_title1: "We",
                        innovative_title2: "Innovative",
                        innovative_description: "The skills and experience we've developed at CodeSuits during many years in the IT industry and an endless projects we've helped to release, allow our IT & UX/UI experts assist your business grow faster.",
                    },
                },


                portfolio: {
                    title: "Our Projects",
                    description: "From simple displays to striking visuals",
                    description2: "Our dedicated team is committed to your success",


                    item1_title: "RETAIL",
                    item1_description: "restaurants, coffeeshops,dept. stores",

                    item2_title: "BILLBOARDS",
                    item2_description: "restaurants, coffeeshops,dept. stores",

                    item3_title: "IMMERSIVE ENVIRONMENT",
                    item3_description: "Showrooms, exhibitions, nightclubs",

                    item4_title: "",
                    item4_description: "",

                    all_cases: "See All",
                
                },

                portfolio_all: {
                    title: "PORFOLIO",
                    subtitle: "This page contains examples for some of our projects and services in different fields using a tailored-made technologies that we customized for the needs of each customer.",
                    
                },

                general: {
                    cta: {
                        cta_title: "Are you ready to scale your business?",
                        cta_subtitle1: "Get in touch and let us build something amazing",
                        cta_subtitle2: " together!",
                        cta_messgae: "Send message",
                    },
                },

                footer: {
                    logo: {
                        logo_title: "LED Solutions & Consultant",
                        logo_address: "Bangkok, Thailnd.",

                    },


                    company: {
                        company_title: "Company",
                        company_about: "About us",
                        company_policy: "Privacy Policy",
                        company_terms: "Terms of Use",
                        company_contact: "Contact",
                    },
                    services: {
                        services_title: "Services",
                        services_web: "Products",
                        services_mobile: "Services",
                        services_uxui: "Technologies",
                        services_consult: "Projects",
                    },
                    social: {
                        social_title: "Social Media Links",
                        social_subtitle: "Follow us on social media",
                    },
                },

                contact: {
                    message: {
                        message_title: "SEND US A MESSAGE",
                        message_fname: "First Name*",
                        message_lname: "Last Name*",
                        message_email: "Email*",
                        message_phone: "Phone*",
                        message_text: "Message*",
                        message_button: "SEND MESSAGE",
                        message_button_loading: "loading...",
                        message_address_title: "Office Address",
                        message_address: "Bangkok. Thailand",
                        message_phone_title: "Call Us",
                        message_phone_number: "(+66)-098-2592690",
                        message_email_title: "Send an Email",
                        message_email_address: "sales@anbelectronics.com",
                        message_validation: "Please fill in all fields before sending your message",

                    },
                },

                demo: {
                    form: {
                        form_title: "SEND US A MESSAGE",
                        form_cb_web: "Web Application/Websites/E-commerce",
                        form_cb_mobile: "Mobile Develoopment",
                        form_cb_cloud: "Cloud Solutions",
                        form_cb_uxui: "UX/UI Services",
                        form_cb_consult: "IT Consultant",

                        form_fname: "First Name*",
                        form_lname: "Last Name*",
                        form_email: "Email*",
                        form_phone: "Phone*",
                        form_text: "Message*",
                        form_button: "SEND MESSAGE",
                        form_button_loading: "loading...",
                        form_address_title: "Office Address",
                        form_address: "Tel Aviv. Israel",
                        form_phone_title: "Call Us",
                        form_phone_number: "+972546604257",
                        form_email_title: "Send an Email",
                        form_email_address: "info@anbelectronics.com",
                        form_validation: "Please fill in all fields before sending your message",

                    },
                },

            },
        },


        th: {
            translation: {


                title: "เรียบง่ายและเชื่อถือได้!",                  
                title2: "จอแสดงผล LED ของ ANB",
                title3: "โซลูชันที่เหมาะกับความต้องการ",
                title4: "โซลูชันฮาร์ดแวร์และซอฟต์แวร์ที่ปรับแต่งของเรามอบความน่าเชื่อถือที่ไร้รอยต่อและปรับขนาดได้เพื่อให้ภาพที่สมจริง",


                description: "เรียบง่ายและเชื่อถือได้",
                subtitle: "เรานำเสนอโซลูชันจอแสดงผล LED ที่เชื่อถือได้ซึ่งออกแบบมาเพื่อตอบสนองความต้องการพื้นฐานของคุณได้อย่างง่ายดายและมีประสิทธิภาพ",
                learnmore: "Learn more",
                casestudy: "สินค้าของเรา",
                introtitle: "น่าทึ่งและน่าดื่มด่ำ",
                introdescription: {
                    line1: "เราปรับแต่งรูปแบบ LED ที่สวยงามซึ่งผสานเข้ากับสภาพแวดล้อมของคุณได้อย่างราบรื่น,",
                    //line2: "Our team is well vast in software development and is ready to help develop the applications of your choice.",
                    line2: "มอบประสบการณ์ที่เต็มอิ่มและน่าจดจำ",
                    contact: "ติดต่อเรา",

                },

                navbar: {
                    home: "บ้าน",
                    products: "สินค้า",
                    services: "การบริการ",
                    tech: "เทคโนโลยี",
                    industries: "อุตสาหกรรม",
                    projects: "โครงการ",
                    company: "บริษัท",
                    company_about: "เกี่ยวกับเรา",
                    company_process: "Process",
                    company_faq: "FAQ",
                    contact: "ติดต่อเรา",
                    lang: "แบบไทย",
                    schedule: "ติดต่อเรา",
                },

                products: {
                    title: "OUR Products",
                    description: "จากความเรียบง่ายสู่ความน่าทึ่ง",
                    description2: "ติดต่อเราเพื่อสำรวจผลิตภัณฑ์โซลูชันอันหลากหลายของเรา",
                    
                    webdev: {
                        web_title: "ในร่ม",
                        web_description: "We specialize in creating and optimizing high-quality, custom websites and saas-based web applications for businesses and organizations of all sizes. Building mobile-friendly and easy-to-use websites and applications for clients.",
                    },
                    mobile: {
                        mobile_title: "กลางแจ้ง",
                        mobile_description: "We develop high-quality, custom cross-platform mobile applications that are robust and optimized for performance, scalability, and accessibility.",
                    },
                    design: {
                        design_title: "ระบบแสงสว่างอัจฉริยะ",
                        design_description: "เราสร้างและให้คำปรึกษาเกี่ยวกับการออกแบบ ux/ui ที่สร้างแรงบันดาลใจ นักออกแบบที่มีประสบการณ์และสร้างสรรค์ของเราจะช่วยเสริมสร้างประสบการณ์ของผู้ใช้ในผลิตภัณฑ์ของคุณ",
                    },

                    entertainment: {
                        entertainment_title: "ความบันเทิง",
                        entertainment_description: "เราสร้างและให้คำปรึกษาเกี่ยวกับการออกแบบ ux/ui ที่สร้างแรงบันดาลใจ นักออกแบบที่มีประสบการณ์และสร้างสรรค์ของเราจะช่วยเสริมสร้างประสบการณ์ของผู้ใช้ในผลิตภัณฑ์ของคุณ",
                    },

                    linear: {
                        linear_title: "LINEAR / STRIP / NEON FLEX",
                        linear_description: "เราสร้างและให้คำปรึกษาเกี่ยวกับการออกแบบ ux/ui ที่สร้างแรงบันดาลใจ นักออกแบบที่มีประสบการณ์และสร้างสรรค์ของเราจะช่วยเสริมสร้างประสบการณ์ของผู้ใช้ในผลิตภัณฑ์ของคุณ",
                    },

                    media: {
                        media_title: "สื่อหน้าอาคาร",
                        media_description: "เราสร้างและให้คำปรึกษาเกี่ยวกับการออกแบบ ux/ui ที่สร้างแรงบันดาลใจ นักออกแบบที่มีประสบการณ์และสร้างสรรค์ของเราจะช่วยเสริมสร้างประสบการณ์ของผู้ใช้ในผลิตภัณฑ์ของคุณ",
                    },



                    consult: {
                        consult_title: "เราให้คำปรึกษา",
                        consult_description: "Our IT consultations service provides expert guidance and support for businesses and individuals looking to optimize their technology solutions.",
                    },
                    build: {
                        build_title: "เราสร้าง",
                        build_description: "With over 20 years of experience in software analysis and design, and a comprehensive understanding of the latest IT trends and solutions, we offer tailored recommendations and strategies to enhance your operations, optimize costs, and drive efficiency.",
                    },
                    collaborate: {
                        collaborate_title: "การทำงานร่วมกัน",
                       
                        collaborate_description: "We collaborate with your existing technology team to scale current software applications or design custom solutions tailored to your daily requirements, streamlining and simplifying various processes.",
                       // collaborate_description: "We can collaborate with your existing tech team to scale existing software applications or design customized software applications that suits your everyday need and simplifies various processes.",
                    },
                },

                services: {
                    title: "บริการของเรา",
                    description: "ผู้เชี่ยวชาญและเชื่อถือได้",
                    description2: "ติดต่อเราเพื่อค้นพบบริการที่ครอบคลุมของเรา",

                

                    webdev: {
                        web_title: "การติดตั้ง",
                        web_description: "We specialize in creating and optimizing high-quality, custom websites and saas-based web applications for businesses and organizations of all sizes. Building mobile-friendly and easy-to-use websites and applications for clients.",
                    },
                    mobile: {
                        mobile_title: "การซ่อมบำรุง",
                        mobile_description: "We develop high-quality, custom cross-platform mobile applications that are robust and optimized for performance, scalability, and accessibility.",
                    },
                    design: {
                        design_title: "CONSULTATION",
                        design_description: "We create and consult on making inspiring ux/ui designs. Our experienced and creative designers will help enriching the experience of your users inside your products.",
                    },
                    consult: {
                        consult_title: "REGULATIONS",
                        consult_description: "Our IT consultations service provides expert guidance and support for businesses and individuals looking to optimize their technology solutions.",
                    },


                    build: {
                        build_title1: "WE DELIVER",
                        build_title2: "Build",
                        build_description: "With over 20 years of experience in software analysis and design, and a comprehensive understanding of the latest IT trends and solutions, we offer tailored recommendations and strategies to enhance your operations, optimize costs, and drive efficiency.",
                    },
                    collaborate: {
                        collaborate_title1: "WE CONSULT",
                        collaborate_title2: "Collaborate",
                       
                        collaborate_description: "We collaborate with your existing technology team to scale current software applications or design custom solutions tailored to your daily requirements, streamlining and simplifying various processes.",
                       // collaborate_description: "We can collaborate with your existing tech team to scale existing software applications or design customized software applications that suits your everyday need and simplifies various processes.",
                    },
                },

                company_pages: {

                    about: {
                        about_title: "About us",
                        about_subtitle: "We are a team of experienced people with a shared passion to provide our customers with the best software solutions.",

                        about_history_title: "How it started",
                        about_history_description: "During 2022 Yuval Zohar and Evgeny Zapisotsky, joined forces with a group of developers from Ukraine to build Web and Mobile software solutions for multiple clients in Europe and the US.",
                        about_history_description2: "Since then, CodeSuits has steadily grown, increasing its expertise with updated technologies and helping more clients reach their business goals.", 
                         
                        about_global_title: "We are a Thai company working globally",
                        about_global_description: "Both our clients and company's team are globally distributed",

                        about_leadership_title: "Our Leadership",
                        about_leadership_description: "",

                        about_partners_title: "Our Customers",
                        about_partners_description: "",
                    },


                    process: {
                        process_title: "Work Process",
                        process_subtitle: "We have built a flexible and customized work process in order to adapt ourselves to the requirements of each client with minimum time and maximum quality.",
                        process_phase1_title: "Phase 1: Understanding customer's requirements (Discovery)",
                        process_phase1_description1: "Our product, R&D and UX/UI managers will meet with your team to understand your business goals and software requirements.",
                        process_phase1_description2: "We will dive deep into your goals and requirements to avoid possible delays during the work.",

                        process_phase2_title: "Phase 2: Providing an implementation offer",
                        process_phase2_description1: "Research, technical architecture and design prototype.",
                        process_phase2_description2: "We conduct the relevant research in terms of the required architecture and the technical stack. If relevant to the requirements, our UX/UI designer will create an initial prototype for the solution.",
                        process_phase2_schedule_title: "Working time: In average 2-4 weeks",
                        process_phase2_schedule_description: "*Depend on the complexity of the requirements and needed implementation",

                        process_phase3_title: "Phase 3: Start working on your product!",
                        process_phase3_description1: "CodeSuits'es R&D and Desiners start work",
                        process_phase3_description2: "Based on the plans prepared in phase 2, we start working on the solution. The work can be either to extend the existing code with your teams, or to build everything ourselves. The work included constant synchronization meetings.",
                        process_phase3_schedule_title: "Working time: will be calculated during phase 2",
                        process_phase3_schedule_description: "",

                        process_phase4_title: "Phase 4: Testing (QA)",
                        process_phase4_description1: "Testing the product before any release.",
  
                        process_phase4_description2: "Our testing engineers perform a various tests, like, Unit testing, E2E, regression, load and perforance to verify compliance with the original requirements. We support manual and automation testings.",

//                        process_phase4_description2: "Our testers perform a series of tests to make sure the product meets the various requirements. We perform various tests according to the set of tests defined in advance. From the level of unit testing of each programmer to end-to-end tests and regression to make sure that previous development is not broken. In addition to functionality, we verify compliance with loads, performance, etc.. The tests include manual and automatic tests.",
                        
                        process_phase4_schedule_title: "Working time: will be calculated during phase 2",
                        process_phase4_schedule_description: "",

                        process_phase5_title: "Phase 5: Release",
                        process_phase5_description1: "Preparing the software before its release.",
                        process_phase5_description2: "During the final process of testing and fixing the bugs, we will help you as necessary to prepare the product for its release to customers. Our design people will sit down with you and help prepare the details required for this stage of the process (for example: graphics for release notes, emails to customers, campaigns and documentation)",
                    
                    },



                    faq: {
                        faq_title: "Frequently Asked Questions",
                        faq_subtitle: "",

                        faq_general_title: "General",
                        faq_general_q1: "Are you GDPR compliant?",
                        faq_general_a1: "Yes. We've put systems and processes in place at CodeSuits to comply.",
                        faq_general_q2: "How well does your team know English?",
                        faq_general_a2: "The level of English proficiency is at least upper Intermediate. Our team constantly improves English skills by studying with native speaker tutor.",
                        faq_general_q3: "I'm looking for one developer. Can you help?",
                        faq_general_a3: "Yes. You may hire just one developer. Let us know if you are interested in building out your team in the future.",
                        faq_general_q4: "Where will my software development team be located?",
                        faq_general_a4: "CodeSuits is a global company. Our team is located mostly in Israel and Ukraine. We encourage our clients to pay us a visit and bring their team together for a workshop.",

                        faq_pricing_title: "Pricing",
                        faq_pricing_q1: "Do you charge fixed price or rate per hour?",
                        faq_pricing_a1: "We will send you our indicative rates per hour by request. Contact us for project estimation.",
                        faq_pricing_q2: "What are the average rates for software developers?",
                        faq_pricing_a2: "Pricing is very rely on the required task, but in general, IT nearshoring prices in Ukraine vary between $45 and $75 per hour.",
                        faq_pricing_q3: "What are the common payment terms?",
                        faq_pricing_a3: "Primarily we use Time and Material model with monthly invoicing. You will get invoice based on the efforts during the previous month.",
                        faq_pricing_q4: "How to estimate my project budget?",
                        faq_pricing_a4: "Please send us a request with a description of your project and we'll provide initial cost estimation.",

                        faq_support_title: "Support",
                        faq_support_q1: "What tech professionals can you provide to extend my team?",
                        faq_support_a1: "Developers, QA engineers, business analysts, scrum masters, project managers, DevOps engineers, UX/UI designers, support engineers to name a few.",
                        faq_support_q2: "How quickly will I get a response from CodeSuits?",
                        faq_support_a2: "We will do our best to contact you within 12 hours. In exceptional cases we might need 24 hours.",
                        faq_support_q3: "I have a startup idea. Can you develop custom product for me?",
                        faq_support_a3: "Absolutely. We deliver full-cycle custom product development with further product support and maintenance.",
                        faq_support_q4: "What kind of tech support do you provide?",
                        faq_support_a4: "We provide thorough third-line product support: modifications and updates after the software product delivery.",
                         

                    },


                },


                services_pages: {

                    webdev: {
                        web_title: "Web Development",
                        web_subtitle1: "Web development, with its various solutions, is important for every business. From a small coffee shop, having a simple website to grow their audience, to an online banking system that allows its users to make secure transactions worldwide.",
                        web_subtitle2: "WE BUILD TAILOR-MADE WEB APPS TO TARGET OUR CUSTOMER'S GOALS",

                        websites: {
                            website_title: "Websites",
                            website_subtitle: "User friendly attractive sites",
                            website_description: "We build different types of websites (e.g. Business, E-commerce, Booking) integrated with tools to change its content dynamically and expose it to a large scale of customers.",
                        },

                        ecommerce: {
                            ecommerce_title: "E-commerce sites",
                            ecommerce_subtitle: "Your online shop",
                            ecommerce_description: "At CodeSuits we build custom e-commerce sites, related to payment, content and other services to help your business increase its sales.",
                        },

                        webapp: {
                            webapp_title: "Web applications",
                            webapp_subtitle: "Cloud-based solutions",
                            webapp_description: "Our cloud team will help your business build a cloud-base web application, including: cloud architecture design, configurations and development.",
                        },

                        saas: {
                            saas_title: "SaaS",
                            saas_subtitle: "Software as a Service solution",
                            saas_description: "We build B2C and B2B SaaS-based products with a focus on performance, load balancing, security and other cloud-based concerns.",
                        },

                        webmobile: {
                            webmobile_title: "Web Mobile apps",
                            webmobile_subtitle: "Responsive Web applications",
                            webmobile_description: "We develop at CodeSuits responsive-wise Web applications to scale it with mobile and tablet devices.",
                        },

                        webdata: {
                            webdata_title: "Web data management",
                            webdata_subtitle: "Data-driven Web applications",
                            webdata_description: "We build data-driven solutions using big data, ML and AI technologies to improve the performance of our customers' products.",
                        },
                        web_case_study: "check our case studies",
                    },

                    mobile: {
                        mobile_title: "Mobile Development",
                        mobile_subtitle1: "Nowdays, a mobile app is the best way to engage with your users.",
                        mobile_subtitle2: "Why? because they are always there..",
                        mobile_subtitle3: "We build at CodeSuits an amazing tailor-made Android and iPhone mobile apps.",

                        native: {
                            native_title: "Native applications",
                            native_description: "We develop android and ios apps using native languages (e.g. kotlin, swift).",
                        },
                        cross: {
                            cross_title: "Cross-platform applications",
                            cross_description1: "A cross-platform solution, allows developing once and deploy simultaneously on a multiple platforms. This is why it is a great way to reduce the delevelopment time of your products, and assist with your company's go-to-market strategy.",
                            cross_description2: "CodeSuits uses cross-platforms solutions like React Native and Flutter.",
                        },
                        sdk: {
                            sdk_title: "SDK",
                            sdk_description: "We create android and ios sdk (e.g. java, kotlin and swift), and mobile libraries in native languages (e.g. c/c++ and python).",
                        },
                        hollistic: {
                            hollistic_title: "Holistic solution",
                            hollistic_description: "A whole cross-platform customized solution with your mobile and cloud-based interface & services.",
                        },
                        contact: "LET'S TALK!",
                    },

                },


                tech: {
                    title: "Our Technologies",
                    description: "From proven reliability to cutting-edge innovation",
                    description2: "Contact us to explore our full range of available technologies",
                    data: {
                        data_title: "Data, ML & AI",
                        data_description: "Nowdays, data is a crucial part for every business that needs to be handled properly. We specialize in developing, managing and analyzing data. Whether your business needs a big-data solutions, or, an AI features, our data scientists, data developers and BI experts, will build, or, help your teams with planning and implementing it properly.",
                    },
                    cloud: {
                        cloud_title: "Cloud & DevOps Technologies",
                        cloud_description: "We help SMBs and large corporations target and resolve their cloud technology challanges. As AWS certified partners, our cloud experts will help your business building a cloud-based solution taking in mind performance, security, migration, transformation and computing strartegies.",
                    },
                    cross: {
                        cross_title: "Cross Platforms",
                        cross_description: "Cross platform solutions are great to reduce development time and help your business move faster with its go-to-market strategy. At CodeSuits, we work with multiple cross-platform technologies.",
                    },
                    security: {
                        security_title: "Security and Privacy",
                        security_description: "Security and data privacy are crucial aspects to handle carefully, while your business is keep growing. Aligned with most being used security and privacy standards, our experts will help you building the right solutions.",
                    },
                    creative: {
                        creative_title1: "We",
                        creative_title2: "Creative",
                        creative_description: "Our highly skilled and innovative UX/UI designers create and enhance designs that integrate industry best practices and modern trends, delivering experiences that captivate and engage your customers and partners.",

                        //                        creative_description: "Our well experienced and creative UX/UI designers, will create and improve designs, combined with the worlds best practices and modern trends, that will inspire your customers and partners.",
                    },
                    innovative: {
                        innovative_title1: "We",
                        innovative_title2: "Innovative",
                        innovative_description: "The skills and experience we've developed at CodeSuits during many years in the IT industry and an endless projects we've helped to release, allow our IT & UX/UI experts assist your business grow faster.",
                    },
                },


                portfolio: {
                    title: "โครงการของเรา",
                    description: "จากการแสดงผลแบบเรียบง่ายไปจนถึงภาพที่สะดุดตา",
                    description2: "ทีมงานที่ทุ่มเทของเราพร้อมมุ่งมั่นเพื่อความสำเร็จของคุณ",

                    item1_title: "ขายปลีก",
                    item1_description: "ร้านอาหาร, ร้านกาแฟ, แผนก. ร้านค้า",

                    item2_title: "ป้ายโฆษณา",
                    item2_description: "ร้านอาหาร, ร้านกาแฟ, แผนก. ร้านค้า",

                    item3_title: "สภาพแวดล้อมที่ดื่มด่ำ",
                    item3_description: "โชว์รูม, นิทรรศการ, ไนท์คลับ",

                    item4_title: "",
                    item4_description: "",

                    all_cases: "See All",
                
                },

                portfolio_all: {
                    title: "พอร์โฟลิโอ",
                    subtitle: "This page contains examples for some of our projects and services in different fields using a tailored-made technologies that we customized for the needs of each customer.",
                    
                },

                general: {
                    cta: {
                        cta_title: "คุณพร้อมที่จะขยายธุรกิจของคุณหรือยัง?",
                        cta_subtitle1: "ติดต่อเราและให้เราสร้างสิ่งที่น่าอัศจรรย์",
                        cta_subtitle2: " ด้วยกัน!",
                        cta_messgae: "ส่งข้อความ",
                    },
                },

                footer: {
                    logo: {
                        logo_title: "บริษัท แอลอีดี โซลูชั่น แอนด์ คอนซัลแทนท์",
                        logo_address: "Bangkok, Thailnd.",

                    },


                    company: {
                        company_title: "บริษัท",
                        company_about: "เกี่ยวกับเรา",
                        company_policy: "นโยบายความเป็นส่วนตัว",
                        company_terms: "เงื่อนไขการใช้งาน",
                        company_contact: "ติดต่อ",
                    },
                    services: {
                        services_title: "การบริการ",
                        services_web: "สินค้า",
                        services_mobile: "การบริการ",
                        services_uxui: "เทคโนโลยี",
                        services_consult: "โครงการ",
                    },
                    social: {
                        social_title: "Social Media Links",
                        social_subtitle: "Follow us on social media",
                    },
                },

                contact: {
                    message: {
                        message_title: "ส่งข้อความถึงเรา",
                        message_fname: "First Name*",
                        message_lname: "Last Name*",
                        message_email: "Email*",
                        message_phone: "Phone*",
                        message_text: "Message*",
                        message_button: "ส่งข้อความ",
                        message_button_loading: "กำลังโหลด...",
                        message_address_title: "Office Address",
                        message_address: "Bangkok. Thailand",
                        message_phone_title: "โทรหาเรา",
                        message_phone_number: "(+66)-098-2592690",
                        message_email_title: "ส่งอีเมล์",
                        message_email_address: "sales@anbelectronics.com",
                        message_validation: "กรุณากรอกข้อมูลให้ครบถ้วนก่อนส่งข้อความของคุณ",

                    },
                },

                demo: {
                    form: {
                        form_title: "SEND US A MESSAGE",
                        form_cb_web: "Web Application/Websites/E-commerce",
                        form_cb_mobile: "Mobile Develoopment",
                        form_cb_cloud: "Cloud Solutions",
                        form_cb_uxui: "UX/UI Services",
                        form_cb_consult: "IT Consultant",

                        form_fname: "First Name*",
                        form_lname: "Last Name*",
                        form_email: "Email*",
                        form_phone: "Phone*",
                        form_text: "Message*",
                        form_button: "SEND MESSAGE",
                        form_button_loading: "loading...",
                        form_address_title: "Office Address",
                        form_address: "Tel Aviv. Israel",
                        form_phone_title: "Call Us",
                        form_phone_number: "+972546604257",
                        form_email_title: "Send an Email",
                        form_email_address: "info@anbelectronics.com",
                        form_validation: "Please fill in all fields before sending your message",

                    },
                },

            },
        },



        // he: {
        //     translation: {
        //         description: "פתרונות תוכנה בהתאמה אישית לצרכים של העסק שלכם",
        //         subtitle: "אנחנו צוות של מפתחים ומעצבים עם ניסיון רב בהייטק, עם רון ותשוקה לספק את המוצר הטוב ביותר עבור הלקוחות שלנו.",
        //         learnmore: "קראו עוד",
        //         casestudy: "תיק עבודות",
        //         introtitle: "אנו מפתחים פתרונות תוכנה באיכות גבוהה עבור ארגונים גדולים, מוסדות וחברות קטנות",
        //         contact: "יצירת קשר",
        //         introdescription: {
        //             line1: "הצוותים שלנו מנוסים בפיתוח תוכנה ועיצוב חויית משתמש ונכונים לעזור לכם בבניית התוכנה שרלבנטית עבורכם.",
        //             line2: "נבנה לכם פתרון בהתאמה אישית תוך כדי סנכרון ושיתוף פעולה מלא עם הצוותים האורגניים שלכם.",
        //             contact: "יצירת קשר",
        //         },

        //         navbar: {
        //             home: "דף הבית",
        //             services: "שירותים",
        //             tech: "טכנולוגיות",
        //             industries: "תעשיות",
        //             casestudy: "תיק עבודות",
        //             company: "חברה",
        //             company_about: "אודות",
        //             company_process: "התהליך",
        //             company_faq: "שאלות",
        //             contact: "צרו קשר",
        //             lang: "עברית",
        //             schedule: "בואו נדבר",
        //         },



        //         services: {
        //             title: "שירותים",
        //             description: "סל השירותים שלנו כולל מספר תחומים בתוכנה, עיצוב וייעוץ",
        //             webdev: {
        //                 web_title: "פיתוח מוצרי Web",
        //                 web_description: "אנחנו מתמחים ביצירה ופיתוח של מוצרים חדשים וקיימים תוך כדי שמירה על התאמה אישית לצרכי הלקוח. החל מאתרי תדמית, חנויות אונליין ואפליקציות ענן",
        //             },
        //             mobile: {
        //                 mobile_title: "פיתוח אפליקציות למובייל",
        //                 mobile_description: "אנו מפתחים אפליקציות נייטיב וקרוס פלאטפורם. חיבור לענן ולדאטה תוך שמירה על אבטחת המידע, ביצועים ויציבות",
        //             },


        //             design: {
        //                 design_title: "שירותי UX/UI",
        //                 design_description: "אנחנו מייצרים ומייעצבים עיצובים מעוררי השראה גם ברמת חוויית המשתמש וגם ברמת ממשק המשתמש. המעצבים שלנו ייעזרו לכם להעשיר את המוצר שלכם בעיצובים מרשימים שירצו למשתמשים שלכם להשאר יותר במוצר",
        //             },


        //             consult: {
        //                 consult_title: "ייעוץ בנושאי IT",
        //                 consult_description: "אם אתם חברה גדולה, סטארטאפ בראשית דרכו או, יזמים פרטיים, המהנדסים שלנו יעזרו לכם להבין את הפתרון הטכני שאתם מחפשים עבור המוצר או הרעיון שלכם",
        //             },



        //             build: {
        //                 build_title1: "",
        //                 build_title2: "פיתוח",
        //                 build_description: "עם ניסיון של יותר מ-20 שנה בפיתוח מוצרים טכנולוגים של אפליקציות ענן, מובייל, וחומרה, עם דגש על ממשק וחווית המשתמש, אנו יודעים איך לפתח מוצרים בהתאמה אישית העונים על יעדי העסק שלכם ",
        //             },


        //             collaborate: {
        //                 collaborate_title1: "שיתוף",
        //                 collaborate_title2: "פעולה",
        //                 collaborate_description: "שיטות העבודה שפתחנו מאפשרות לנו להשתלב בצורה יעילה עם פרויקטים קיימים שצריכים הרחבת פיתוח, או יצירת מוצר חדש ביחד עם הצוותים שלכם, או, בעצמנו תוך סנכרון מלא ",
        //             },
        //         },

        //         company_pages: {

        //             about: {
        //                 about_title: "על החברה",
        //                 about_subtitle: "אנחנו צוות של אנשים מנוסים עם תשוקה משותפת לספק ללקוחותינו את פתרונות התוכנה הטובים ביותר.",

        //                 about_history_title: "איך התחלנו",
        //                 about_history_description: "במהלך 2022 יובל זוהר ויבגני זפיסוצקי, איחדו כוחות עם קבוצת מפתחים מאוקראינה לבניית פתרונות תוכנה לאינטרנט ומובייל עבור לקוחות מאירופה וארצות הברית",
        //                 about_history_description2: "מאז קודסוויטס גודלת בהתמדה. הגדלנו את המומחיות שלנו בעבודה עם טכנולוגיות מעודכנות ועזרה ליותר לקוחות להגיע ליעדים העסקיים שלהם בהצלחה", 
                         
        //                 about_global_title: "אנחנו חברה בינלאומית",
        //                 about_global_description: "הלקוחות שלנו, וגם חלק מאנשי הצוות שלנו, ממוקמים במקומות שונים בעולם",

        //                 about_leadership_title: "ההנהלה שלנו",
        //                 about_leadership_description: "",

        //                 about_partners_title: "הלקוחות והשותפים שלנו",
        //                 about_partners_description: "",
        //             },

        //             process: {
        //                 process_title: "תהליך העבודה שלנו",
        //                 process_subtitle: "בנינו תהליך עבודה אישי ומסודר המבוסס על ניסיון רב של עבודה מול לקוחות והבנת צרכי הלקוח",

        //                 process_phase1_title: " שלב א': הבנת הדרישות וחשיפת הפרויקט (Discovery)",
        //                 process_phase1_description1: "יושבים ביחד ובונים מסמך דרישות לתחילת העבודה",
        //                 process_phase1_description2: "זוהי הפגישה הראשונה שלנו בה אתם מסבירים לנו מה בעצם אתם צריכים. אנחנו שואלים שאלות ויורדים לפרטים כדי להכין כמה שיותר דרישות רלבנטיות למוצר על מנת שנוכל להתחיל לעבוד ללא עיכובים.",

        //                 process_phase2_title: "שלב ב': הכנת הצעה לתחילת עבודה",
        //                 process_phase2_description1: "מחקר, בניית פרוטוטייפ והכנת הצעה לתחילת העבודה",
        //                 process_phase2_description2: "בשלב הבא, הצוותים שלנו יושבים על הדרישות ומתחילים לעבוד! בשלב הזה העבודה שלנהו כוללת מחקר בו אנו בוחרים את הפתרון הנכון למוצר שלכם. בהתאם למורכבות הדרישות, נבנה לכם ארכיטקטורת תוכנה ברמה התחלתית והמעבים שלנו , במידה ורלבנטי לפרויקט, ייצרות את חווית המשתמש עליה אנו ממליצים לממש. בסוף התהליך נפגש שוב ונציג בפניכים את ההצעה עליה עבדנו ותקבלו מאיתנו את תיק ההצעה לעיונכם.",
        //                 process_phase2_schedule_title: "לו״ז: השלב הזה לוקח כ-2-4 שבועות",
        //                 process_phase2_schedule_description: "תלוי במורכבות הפרויקט והדרישות הלוות. בניית אתר למשל, תהיה כנראה יותר קצרה*",

        //                 process_phase3_title: "שלב ג': שלוש ארבע ו..לעבודה",
        //                 process_phase3_description1: "צוותי הפיתוח והעיצוב שלנו מתחילים לעבודה על המוצר",
        //                 process_phase3_description2: "בהתאם לצרכי הלקוח והסכמות מוקדמות קיימות שתי אפשרויות: 1. אנו משתלבים בעבודה משותפת מול הצוותים של הלקוח ועובדים כחלק אורגני מהחברה ברמת העבודה מול מנהלי המוצר ואנשי הפיתוח והעיצוב במידת הצורך . 2. אנו מפתחים ללקוח פתרון מאפס על פי לוחות זמנים של הלקוח כולל פגישות סנכרון",
        //                 process_phase3_schedule_title: "לו״ז: זמן העבודה משתנה מפרויקט לפרויקט בהתאם לדרישות",
        //                 process_phase3_schedule_description: "",

        //                 process_phase4_title: " שלב ד': בדיקות איכות (QA)",
        //                 process_phase4_description1: "בדיקות איכות של המוצר לפני שחרורו ללקוח",
        //                 process_phase4_description2: "אנשי הבדיקות שלנו מבצעים סדרה של בדיקות לוודא שהמוצר עומד בדרישות השונות. אנו מבצעים בדיקות שונות בהתאם למערך הבדיקות שהוגדר מראש. מרמת היוניט טסטינג של כל מתכנת ועד לבדיקות קצה לקצה וריגרשן לוודא שפיתוח קודם לא נשבר. בנוסף לפונקציונליות אנו מוודאים עמידה בעומסים, ביצועים ועוד..הבדיקות כוללות בדיקות ידניות ואוטומטיות",
        //                 process_phase4_schedule_title: "לו״ז: זמן העבודה משתנה מפרויקט לפרויקט בהתאם לדרישות. הבדיקות נעשות בחלקן במקביל לתהליכי הפיתוח כדי לחסוך זמן יקר",
        //                 process_phase4_schedule_description: "",

        //                 process_phase5_title: " שלב ה': שחרור הגרסה (Release)",
        //                 process_phase5_description1: "הכנת התוכנה לקראת שחרור גרסה",
        //                 process_phase5_description2: "במהלך תהליך הבדיקות ותיקון הבאגים נעזור לכם לפי הצורך הבכנת המוצר לקראת שחרורו ללקוחות. אנשי העיצוב שלנו יישבו אתכם ויעזרו להכין את העיובים הנדרשים לשלב זה של התהליך (למשל: גרפיקות לרליס נוטס, מיילים ללקוחות, קמפיינים ודוקומנטציה)",
                        

        //             },

        //             faq: {
        //                 faq_title: "שאלות ותשובות",
        //                 faq_subtitle: "",

        //                 faq_general_title: "כללי",
        //                 faq_general_q1: "?GDPR האם אתם תואמים",
        //                 faq_general_a1: "כן. הגדרנו בקודסויטס מערכות ותהליכים העומדים בדרישות התקן ותקנים אחרים הדורשים לשמור על פרטיות משתמי הקצה.",
        //                 faq_general_q2: "כמה טוב הצוות שלך יודע אנגלית?",
        //                 faq_general_a2: "רמת השליטה באנגלית היא לפחות בינונית עליונה. הצוות שלנו משפר כל הזמן את כישורי האנגלית על ידי לימוד עם מורה דובר שפת אם.",
        //                 faq_general_q3: "אני מחפש מפתח אחד. אתה יכול לעזור?",
        //                 faq_general_a3: "כֵּן. אתה יכול לשכור מפתח אחד בלבד. ספר לנו אם אתה מעוניין לבנות את הצוות שלך בעתיד.",
        //                 faq_general_q4: "היכן יתמקם צוות פיתוח התוכנה שלי?",
        //                 faq_general_a4: "קודסויטס היא חברה גלובלית. הצוות שלנו ממוקם בעיקר בישראל ובאוקראינה. אנו מעודדים את לקוחותינו לבקר אותנו ולהפגיש את הצוות שלהם לסדנה.",

        //                 faq_pricing_title: "תמחור",
        //                 faq_pricing_q1: "האם אתם גובים מחיר קבוע או תעריף לשעה?",
        //                 faq_pricing_a1: "אנו נשלח לך את התעריפים האינדיקטיביים שלנו לשעה לפי בקשה. צור איתנו קשר להערכת פרויקט.",
        //                 faq_pricing_q2: "מהם התעריפים הממוצעים למפתחי תוכנה?",
        //                 faq_pricing_a2: "התמחור מסתמך מאוד על המשימה הנדרשת, אבל באופן כללי, מחירי Nearshoring של IT באוקראינה נעים בין $45 ל-$75 לשעה.",
        //                 faq_pricing_q3: "מהם תנאי התשלום הנפוצים?",
        //                 faq_pricing_a3: "בעיקר אנו משתמשים במודל זמן וחומר עם חשבונית חודשית. תקבל חשבונית על סמך המאמצים במהלך החודש הקודם.",
        //                 faq_pricing_q4: "כיצד להעריך את תקציב הפרויקט שלי?",
        //                 faq_pricing_a4: "אנא שלח לנו בקשה עם תיאור של הפרויקט שלך ואנו נספק הערכת עלות ראשונית.",

        //                 faq_support_title: "תמיכה ושירות",
        //                 faq_support_q1: "אילו אנשי מקצוע טכנולוגיים אתה יכול לספק כדי להרחיב את הצוות שלי?",
        //                 faq_support_a1: "מפתחים, מהנדסי QA, אנליסטים עסקיים, מאסטרים של Scrum, מנהלי פרויקטים, מהנדסי DevOps, מעצבי UX/UI, מהנדסי תמיכה, למנות כמה.",
        //                 faq_support_q2: "באיזו מהירות אקבל תגובה מקודסויטס?",
        //                 faq_support_a2: "אנו נעשה כמיטב יכולתנו ליצור איתך קשר תוך 12 שעות. במקרים חריגים אנו עשויים להזדקק ל-24 שעות",
        //                 faq_support_q3: "יש לי רעיון לסטארט-אפ. האם אתה יכול לפתח עבורי מוצר מותאם אישית?",
        //                 faq_support_a3: "בְּהֶחלֵט. אנו מספקים פיתוח מוצר מותאם אישית במחזור מלא עם תמיכה נוספת ותחזוקה של מוצרים.",
        //                 faq_support_q4: "איזה סוג של תמיכה טכנית אתה מספק?",
        //                 faq_support_a4: "אנו מספקים תמיכה יסודית במוצר קו שלישי: שינויים ועדכונים לאחר אספקת מוצר התוכנה.",
                         

        //             },
        //         },



        //         services_pages: {
        //             webdev: {
        //                 web_title: "Web פיתוח",
        //                 web_subtitle1: "פיתוח ווב, על סוגיו השונים, חשוב לכל עסק. מבית קפה קטן, עם אתר אינטרנט פשוט להגדלת הקהל שלו, ועד למערכת בנקאית מקוונת המאפשרת למשתמשים שלה לבצע עסקאות מאובטחות ברחבי העולם.",
        //                 web_subtitle2: "אנו בונים אפליקציות אינטרנט המותאמות אישית להשגת יעדי לקוחותינו",

        //                 websites: {
        //                     website_title: "אתרים",
        //                     website_subtitle: "אתרים ידידותיים למשתמש ואטרקטיביים",
        //                     website_description: "אנו בונים סוגים שונים של אתרי אינטרנט (כגון עסקים, מסחר אלקטרוני, בוקינג) המשולבים בכלים לשינוי התוכן באופן דינמי וחשיפה להיקף גדול של לקוחות",
        //                 },

        //                 ecommerce: {
        //                     ecommerce_title: "E-commerce אתרי",
        //                     ecommerce_subtitle: "חנויות מסחר    אונליין",
        //                     ecommerce_description: "אנו בונים אתרי מסחר אלקטרוני מותאמים אישית, הקשורים לתשלום, תוכן ושירותים אחרים כדי לעזור לעסק שלך להגדיל את המכירות שלו.",
        //                 },

        //                 webapp: {
        //                     webapp_title: "Web אפליקציות",
        //                     webapp_subtitle: "פתרונות מבוססי ענן",
        //                     webapp_description: "צוות הענן שלנו יעזור לעסק שלכם לבנות יישום אינטרנט מבוסס ענן הכולל תכנון ארכיטקטורת ענן, הגדרות ופיתוח.",
        //                 },

        //                 saas: {
        //                     saas_title: "SaaS",
        //                     saas_subtitle: "Software as a Service פתרונות",
        //                     saas_description: "אנו מפתחים מוצרי עסק-ללקוח ועסק-לעסק עם התמקדות בביצועים, טיפול בעומס משתמשים, אבטחת המידע ושירותי ענן אחרים.",
        //                 },

        //                 webmobile: {
        //                     webmobile_title: "אפליקציות ווב למובייל",
        //                     webmobile_subtitle: "אפליקציות ריספונסיביות",
        //                     webmobile_description: "אנו מפתחים יישומי אינטרנט רספונסיביים המותאמים אוטומטית גם למכשירים ניידים וטאבלטים.",
        //                 },

        //                 webdata: {
        //                     webdata_title: "ניהול המידע ",
        //                     webdata_subtitle: "אפליקציות ווב מבוססות מידע",
        //                     webdata_description: "אנו בונים פתרונות מבוססי נתונים תוך שימוש בטכנולוגיות ביג דאטה ובינה מלאכותית כדי לשפר את ביצועי המוצרים של הלקוחות שלנו.",
        //                 },
        //                 web_case_study: "התרשמו מתיק העבודות שלנו",

        //             },

        //             mobile: {
        //                 mobile_title: "פיתוח מובייל",
        //                 mobile_subtitle1: "כיום, אפליקציה לנייד היא הדרך הטובה ביותר לתקשר עם המשתמשים שלך.",
        //                 mobile_subtitle2: "מַדוּעַ? כי הם תמיד שם",
        //                 mobile_subtitle3: "אנו בונים אפליקציות אטרקטיביות לאנדרואיד ואייפון בהתאמה אישית.",

        //                 native: {
        //                     native_title: "Native אפליקציות",
        //                     native_description: "אנו מפתחים אפליקציות אנדרואיד ו-אייפון באמצעות שפות כמו קוטלין וסויפט.",
        //                 },
        //                 cross: {
        //                     cross_title: "אפליקציות מרובות פלטפורמות",
        //                     cross_description1: "פתרון חוצה פלטפורמות, מאפשר לפתח פעם אחת ולפרוס בו זמנית במספר פלטפורמות. זו הסיבה שזו דרך מצוינת לצמצם את זמן הפיתוח של המוצרים שלך, ולסייע באסטרטגיית היציאה לשוק של החברה שלך.",
        //                     cross_description2: "אנו משתמשים בטכנולוגיות קרוס-םלטפורמות כמו פלאטר וריאקט-נייטיב",
        //                 },
        //                 sdk: {
        //                     sdk_title: "SDK",
        //                     sdk_description: "אנחנו מפתחים ספריות לאנדרואיד ואייפון בקוטלין, ג'אווה וסוויפט. בנוסף, אנחנו מפתחים ספריות נייטיב בפייטון וסי++",
        //                 },
        //                 hollistic: {
        //                     hollistic_title: "פתרון הוליסטי",
        //                     hollistic_description: "פתרון שלם בהתאמה אישית חוצת פלטפורמות עם ממשק ושירותים מבוססי מובייל ושירותי ענן.",
        //                 },
        //                 contact: "!בואו נדבר",
        //             },
        //         },


        //         tech: {
        //             title: "טכנולוגיות",
        //             description: "ארגז הכלים הטכנולוגי שלנו כולל את התחומים הבאים",
        //             data: {
        //                 data_title: "בסיסי נתונים ובינה מלאכותית",
        //                 data_description: "כיום, לעסק בצמיחה, עם כמות גדולה של לקוחות, ניהול נכון של המידע הוא רכיב קריטי להצלחתו של המוצר. אנחנו מומחים בפיתוח , ניהול וניתוח המידע. אם העסק שלכם צריך פתרון ביג-דאטה, או פיתוח מוצר מבוסס בינה מלאכותית, אנשי הדאטה וחוקרי הבינה המלאכותית שלנו יעזרו בפיתוח הפתרון שהכי נכון לכם",

        //             },
        //             cloud: {
        //                 cloud_title: "טכנולוגיות ענן ו-DevOps",
        //                 cloud_description: "אנחנו עוזרים לעסקים קטנים וגדולים לפתור את אתגרי הענן שלהם. כשותפים מוסמכים של אמזון, מומחי הענן שלנו יעזרו לעסק שלכם לבנות פתרון מבוסס ענן תוך השמת דגש על שיקולים חשובים כגון: ביצועים, תמיכה משמעותית במשתמשי קצה, אבטחת מידע, חיבור עם מערכות נוספות , עלויות ענן ועוד",
        //             },
        //             cross: {
        //                 cross_title: "פיתוח Cross-Platform",
        //                 cross_description: "פתרון מרובה פלטפורמות מאפשר לפתח פעם אחת ולהפיץ כמעט באותו זמן על מספר פלטפורמות שונות. למשל, אפליקציית ווב, אנדרואיד ו-אייפון. שימוש בפתרון מסוג הזה, שהולך וצובר יותר פופולאריות בשנים האחרונות, חוסך משאבים רבים של כסף, מפתחים וזמן פיתוח. עבור חברות עם משאבים מוגבלים, המעוניינות לשחרר מוצר על יותר מפלטפורמה אחת בזמן קצר יחסית, פתרון מסוג זה יכול להיות מאוד משמעותי",

        //             },
        //             security: {
        //                 security_title: "אבטחת מידע",
        //                 security_description: "אבטחת המידע ושמירה על פרטיות המשתמשים קריטיים להצלחתו של המוצר ולמוניטין שלו. תוך שימוש בסטנדרטיים פופולארים בעולמות הבנקים, הרכב, ועוד, מומחי האבטחה שלנו ייתפרו לכם את הפתרון הבטוח למוצר שלכם",

        //             },
        //             creative: {
        //                 creative_title1: "",
        //                 creative_title2: "יצירתיות",
        //                 creative_description: "הניסיון הרב של אנשי הפיתוח והעיצוב שלנו מאפשר לנו לבוא עם פתרון יצירתי ומהיר מבחינה טכנית או עיצובית עבור המוצר שלך. קראו עוד על הפתרונות שלנו בדף תיק העבודות",
        //             },
        //             innovative: {
        //                 innovative_title1: "",
        //                 innovative_title2: "חדשנות",
        //                 innovative_description: "כחלק מהגישה שלנו בקודסויטס, אנו מקפידים לעבוד עם הטכנולוגיות המתקדמות בעולם התוכנה ובתעשיות השונות. ע״י שימוש בגישה זו, אנו יודעים מהם הפתרונות היעילים ביותר עבור המוצר שלכם, ויכולות האינטגרציה שלנו עם קוד קיים יותר רחבה וגמישה",
        //             },
        //         },

        //         tech_pages:
        //         {

        //             tech_data: {

        //                 tech_data_title: 'פיתוח דאטה',
        //                 tech_data_description1: 'אנו חיים היום בעולם מבוסס נתונים.',
        //                 tech_data_description2: 'עסק שרוצה לשרוד במציאות הזו חייב לנהל את הנתונים שלו כמו שצריך.',
        //                 tech_data_description3: 'זה מתייחס לאופן איסוף, מאורגן, מניפולציה, ניתוח ומאובטח.',
        //                 tech_data_description4: 'המפתחים שלנו מנוסים היטב ביצירת מוצרים המותאמים לדרישות אלה',
        //                 tech_data_card1: "מנועי בינה מלאכותית",
        //                 tech_data_card1_description: 'AI אינטגרציה עם מנועי ',
        //                 tech_data_card1_description2: '',
        //                 tech_data_card2: "AI מותאם אישית",
        //                 tech_data_card2_description: 'פתרון בינה מלאכותית מותאם אישית ללקוח',
        //                 tech_data_card3: "שירותי ענן",
        //                 tech_data_card3_description: 'ארכיטקטורה, הגדרות, פיתוח וניהול שירותי ענן',
        //                 tech_data_bigdata1: "BIG DATA",
        //                 tech_data_bigdata2: "מומחי הענן שלנו, מוסמכי פתרונות הענן של אמזון, גוגל ומייקרוספט, ייעזרו לעסק שלך לבנות ולנהל את מוצרי הביג-דאטה שלו",
        //                 tech_data_mlai1: "ML & AI",
        //                 tech_data_mlai2: "AI שפרו את המוצרים שלכם בפיצ'רים מבוססי ",
        //                 tech_data_letstalk: "!בואו נדבר",

        //             },

        //             tech_cloud: {

        //                 tech_cloud_title: 'ענן ו-DevOps',
        //                 tech_cloud_subtitle: 'כיום, רוב החברות משתמשות בפתרונות מבוססי ענן ובכלי CI/CD.',
        //                 tech_cloud_description1: 'בין אם העסק שלך הוא מוצר B2C או B2B, הוא כנראה צריך להשתמש בשירותים מבוססי ענן. אם לוקחים בחשבון שיקולים כגון: ביצועים, אחסון, זמן פיתוח, אינטגרציה, אבטחה, שיעורי שימוש ועוד, פיתוח ענן יכול להיות מסובך.',
        //                 tech_cloud_description2: 'מומחי הענן וה-DevOps של CodeSuits ייעצו, יעצבו או יישמו פתרון ענן מותאם אישית לעסק שלכם.',
        //                 tech_cloud_solutions_title: 'פתרונות ענן',
        //                 tech_cloud_solutions_description: 'הרחבת פתרונות הלקוח לשירותי הענן (AWS, GCP, Azure)',
        //                 tech_cloud_ci_title: 'אינטגרציה רציפה (CI)',
        //                 tech_cloud_ci_description: 'שליטה מבוקרת בתהליך מיזוג הקוד ע״י שימוש בטכנולוגיות שונות',
        //                 tech_cloud_cd_title: 'שחרור רציף (CD)',
        //                 tech_cloud_cd_description: 'אוטומציה של תהליכי שחרור גרסאות ע״י שימוש בכלים פופולאריים',
        //                 tech_cloud_delevelopment_title: 'פיתוח בענן',
        //                 tech_cloud_delevelopment_subtitle: 'מומחי הענן המוסמכים של AWS, GCP,  יעזרו לעסק שלך לנהל את שירותי הענן שלו באופן יעיל',
        //                 tech_cloud_devops_title: 'DevOps (CI/CD)',
        //                 tech_cloud_devops_subtitle: 'שיפור תהליכי שחרור הגרסאות של המוצר ע״י שימוש והגדרה נכונה של כלי האוטומציה הרלבנטיים',
        //                 tech_cloud_talk: "בואו נדבר!",


        //             },

        //             tech_cross: {

        //                 tech_cross_title: 'פיתוח מרובה פלטפורמות',
        //                 tech_cross_subtitle1: 'פיתוח מרובה פלטפורמות הופך עם הזמן לפתרון פופולרי יותר עבור חברות המעוניינות להפיץ את התוכנה שלהן במספר פלטפורמות תחת מגבלות זמן וכח אדם.',
        //                 tech_cross_subtitle2: 'טכנולוגיות מרובות פלטפורמות מאפשרות למפתחים לקודד פעם אחת, ולהפיץ את התוכנה שנבנתה על מספר פלטפורמות במקביל. היכולות של טכנולוגיות כאלה משתפרות כל הזמן, ולכן הביקוש לפתרון מרובה פלטפורמות הולך וגדל.',
        //                 tech_cross_subtitle3: 'CodeSuits מנוסה היטב בפיתוח מהיר של אפליקציות ותוכנה תוך שימוש בטכנולוגיות מרובות פלטפורמות.',

        //                 tech_cross_brief_flutter_title: 'Flutter',
        //                 tech_cross_brief_flutter_description: 'אנו מפתחים אפליקציות חוצות פלטפורמות המבוססות שפת Flutter של גוגל',

        //                 tech_cross_brief_react_title: 'React Native',
        //                 tech_cross_brief_react_description: 'אנו מנוסים בפיתוח אפליקציות React Native של פייסבוק',

        //                 tech_cross_brief_xamarine_title: 'Xamarine',
        //                 tech_cross_brief_xamarine_description: "אנו תומכים גם בפתרון Xamarin חוצה פלטפורמות של מיקרוסופט",

        //                 tech_cross_flutter_title: 'Flutter',
        //                 tech_cross_flutter_description: 'מומחי CodeSuits Flutter יצטרפו לצוות שלך כדי לפתח קוד קיים, או לבנות אפליקציית Flutter מאפס. אנחנו גם מנוסים בכלים של Flutter כמו: FlutterFlow.',

        //                 tech_cross_react_title: 'React Native',
        //                 tech_cross_react_description: 'גם React Native היא טכנולוגיה לפיתוח אפליקציות חוצות פלטפורמות. על ידי שימוש ב-React Native, קל יותר לשלב אותו עם אפליקציות אחרות מבוססות React כמו, Node JS ו-React JS.',

        //                 tech_cross_xamarine_title: 'Xamarine',
        //                 tech_cross_xamarine_description1: "Xamarin של מיקרוסופט הוא פתרון חוצה פלטפורמות נוסף שניתן לפתח בו זמנית במספר פלטפורמות (למשל Windows Desktop, Web, Android, iOS...).",
        //                 tech_cross_xamarine_description2: "צוות CodeSuits מנוסה היטב בבניית אפליקציות Xamarin מאפס, או על ידי הרחבת קוד קיים.",

        //                 tech_cross_talk: 'בואו נדבר!',

        //             },


        //             tech_security: {

        //                 tech_security_title: 'פתרונות אבטחה ופרטיות',
        //                 tech_security_subtitle1: 'ככל שהטכנולוגיות ממשיכות לצמוח, הן מגיעות יחד עם פרצות קוד.',
        //                 tech_security_subtitle2: 'מומחי האבטחה שלנו יעזרו להקשיח את הקוד שלכם על ידי איתור ותיקון של חורים אבטחה.',

        //                 tech_security_mobile_title: 'מובייל',
        //                 tech_security_mobile_subtitle1: 'CodeSuits מגנה על אפליקציות מובייל מפני התקפות סייבר וגניבת נתונים.',
        //                 tech_security_mobile_subtitle2: 'בהתאם לתקני אנדרואיד ו-iOS, אנו יוצרים אפליקציות מאובטחות למובייל.',

        //                 tech_security_mobile_obfuscation_title: 'ערפול קוד',
        //                 tech_security_mobile_obfuscation_description: 'ערפול נעשה כדי למנוע מהתוקף לבצע הנדסה לאחור של קוד תוכנה',

        //                 tech_security_mobile_encryption_title: 'הצפנת נתונים',
        //                 tech_security_mobile_encryption_description: 'אלגוריתמי הצפנת נתונים מערבלים טקסט רגיל כך שרק האדם עם מפתח הפענוח יכול לקרוא אותו',

        //                 tech_security_mobile_jailbreak_title: 'Jailbreak וזיהוי Root',
        //                 tech_security_mobile_jailbreak_description: 'זיהוי של ניסיון להעביר את מערכת ההפעלה למצב Root',

        //                 tech_security_mobile_mitm_title: 'מניעת התקפות MITM',
        //                 tech_security_mobile_mitm_description: 'משמש לגניבה או מניפולציה של נתונים על ידי יירוט תקשורת בין שני צדדים',

        //                 tech_security_cloud_title: 'שירותי ענן ואינטרנט',
        //                 tech_security_cloud_subtitle: 'איומי אבטחה בענן הם כל נזק אפשרי שיכול להיגרם למערכות הענן שלך. CodeSuits מספקת אמצעי מניעה כדי להתמודד עם איומי אבטחה אלה',

        //                 tech_security_cloud_malware_title: 'תוכנה זדונית',
        //                 tech_security_cloud_malware_description: 'תוכנה זדונית משמשת לעתים קרובות כדי לקבל גישה לא מורשית למערכת, לגנוב נתונים או לגרום נזק',

        //                 tech_security_cloud_ransomware_title: 'תוכנת כופר',
        //                 tech_security_cloud_ransomware_description: 'תוכנת כופר היא סוג מסוים של תוכנות זדוניות המצפינות קבצים של משתמש ודורשת כופר כדי לפתוח אותם',

        //                 tech_security_cloud_data_title: 'פרצות נתונים',
        //                 tech_security_cloud_data_description: 'פריצת מידע/דליפה היא שחרור של נתונים רגישים, סודיים או מוגנים לסביבה לא מהימנה',

        //                 tech_security_cloud_vuln_title: 'פגיעויות בענן',
        //                 tech_security_cloud_vuln_description: 'פגיעויות בענן הפכו למקור משמעותי לאיומי אבטחת סייבר',

        //                 tech_security_cloud_api_title: 'ממשקי API לא מאובטחים',
        //                 tech_security_cloud_api_description: 'ממשק API לא מאובטח בשירותי ענן מאפשר גישה לא מורשית ופרצות נתונים למכשירי IoT לרוב חסרים אמצעי אבטחה של מחשבים מסורתיים, מה שהופך אותם לפגיעים יותר להתקפות',

        //                 tech_security_cloud_iot_title: 'התקפות IoT',
        //                 tech_security_cloud_iot_description: 'מכשירי IoT חסרים אמצעי אבטחה של מחשבים מסורתיים, מה שהופך אותם לפגיעים יותר להתקפות',

        //                 tech_security_cloud_ddos_title: 'התקפות DDoS',
        //                 tech_security_cloud_ddos_description: 'התקפת מניעת שירות מבוזרת כרוכה בהצפה של מערכת או רשת בתעבורה, מה שהופך אותה ללא זמינה',

        //                 tech_security_cloud_zero_title: 'אפס אמון',
        //                 tech_security_cloud_zero_description: 'אנו עוזרים לארגונים לאמץ את הרעיון של זירו-טראסט, לפיו אסור לו לסמוך באופן אוטומטי על שום דבר בתוך או מחוץ לפרמטרים שלו',

        //                 tech_security_talk: "בואו נדבר!",
        //             },

        //         },


        //         industries: {
        //             title: "תעשיות",
        //             description: "אנשי הפיתוח שלנו מנוסים במתן פתרונות בתעשיות שונות",
        //             marketing: {
        //                 marketing_title: "מרקטינג ומדיה",
        //                 marketing_description: "אנו מפתחים מוצרים וכלי תוכנה למוצרי שיווק ומדיה המאפשרים להגדיל את ההתממשקות מול הלקוחות שלכם ולנתח בצורה טובה יותר את הביצועים",



        //             },
        //             retail: {
        //                 retail_title: "קמעונות ומסחר",
        //                 retail_description: "אנחנו בקוד-סוויטס עוזרים לקמעוניים וחברות סחר למצוא פתרונות חדשים כדי להגדיל את הממשק מול הלקוחות ולהגדיל רווחים",
        //             },
        //             finance: {
        //                 finance_title: "כספים ופיננסים",
        //                 finance_description: "בתחום הפיננסי והביטוחי אנו מתמחים בייעוץ ופיתוח כלי תשלומים וסליקה תוך דגש על בנייה נכונה של בסיסי הנתונים, אבטחת המידע ושמירה על פרטיות הלקוח",

        //             },
        //             automotive: {
        //                 auto_title: "תעשיית הרכב",
        //                 auto_description: "אנו עובדים עם תקנים ופרוטוקולים המקובלים בפיתוח תוכנה בעולם הרכב",

        //             },
        //             share: {
        //                 share_title1: "",
        //                 share_title2: "שקיפות",
        //                 share_description: "חשוב לנו בקוד-סויטס לעבוד בצורה שקופה מול הלקוחות שלנו. מעבר לאני מאמין שלנו בשקיפות ואמינות, אנו מאמינים שעבודה בה כל הצדדים יודעים מה יתר הצוותים עושים, מביאה לתוצאות טובות יותר ",
        //             },

        //             agile: {
        //                 agile_title1: "",
        //                 agile_title2: "זריזות",
        //                 agile_description: "הניסיון העשיר והמקצועיות של אנשי הפיתוח והעיצוב שלנו מאפשר לנו להכנס מהר לכל פרויקט, אם הוא חדש, או, קוד קיים, תוך הקפדה על בניית פתרון איכותי ועמידה בלוחות הזמנים",
        //             },
        //         },

        //         portfolio: {
        //             title: "תיק עבודות",
        //             all_cases: "לכל העבודות",
        //             squid: {
        //                 squid_title: "Squid",
        //                 squid_subtitle: "מערכת CRM ",
        //                 squid_description: "מערכת סאאס (שירות כפתרון) למערכת קשרי לקוחות. המערכת נבנתה לאנדרואיד, אייפון ואפליקציית ווב עם שירותי ענן. הוספנו יכולות בינה מלאכותית לשיפור הביצועים",
        //                 squid_learn: "עוד פרטים",

        //             },
        //             health: {
        //                 health_title: "Healthness",
        //                 health_subtitle: "מערכת שירותי בריאות ואורך חיים בריא",
        //                 health_description: "פיתחנו אפליקציית אנדרואיד ואייפון עם יכולות בינה מלאכותית המאפשרות זיהוי מצבי הרוח של משתמשי הקצה ע״י מערכת לזיהוי פנים שבנינו. בהתאם לזיהוי מצח הרוח המשתמשים קבלו המלצות שונות בהתאמה אישית.",
        //                 health_learn: "עוד פרטים",
        //             },
        //             wewear: {
        //                 wewear_title: "WeWear",
        //                 wewear_subtitle: "חנות אונליין למכירת בגדים",
        //                 wewear_description: "בנינו חנות אונליין למכירת בגדים. פיתחנו צ'אטבוט עם יכולות של בינה מלאכותית המאפשרים לתת מענה יעיל למשתמשי הקצה ולחסוך ללקוח גיוס נוסף של אנשי תמיכת לקוחות. בנוסף פתחנו כלים מבוססי פרסונליציה כדי לצפות מתי הזמן הנכון להקפיץ למשתמשים קמפיינים על סמך קניות עבר שלהם",
        //                 wewear_learn: "פרטים נוספים",
        //             },
        //             netpay: {
        //                 netpay_title: "NetPay",
        //                 netpay_subtitle: "אפליקציית ארנק דיגיטאלי",
        //                 netpay_description: "פיתחנו עבור גוף פיננסי אפליקציית ארנק דיגיטאלי המאפשרת לבצע תשלומים לבתי עסק ולהעביר כספים לחשבונות אחרים. בנוסף, בנינו לבקשת הלקוח, כלים המנתחים את ההתנהגות הפיננסית של המשתמש כדי לעזור לו להפחית את ההוצאות ולקבל תנאים טובים יותר בהמשך ע״י אותו גוף פיננסי",
        //                 netpay_learn: "עוד פרטים",
        //             },

        //         },

        //         portfolio_all: {
        //             title: "תיק עבודות",
        //             subtitle: "הדף מכיל דוגמאות לפרויקטים שלנו לפי תחומים שונים. בכל פרויקט נעשתה התאמה אישית של הטכנולוגיות הרלבנטיות",


        //             websites: {
        //                 web_title: "אתרי תדמית וחנויות אונליין",

        //                 adel_title: "אדל ביוטי",
        //                 adel_subtitle: "טיפוח ובריאות",
        //                 adel_description: "אתר אינטרנט אטרקטיבי לעסקים קטנים לקידום השירות והמוצרים שלו בתעשיית היופי והבריאות.",
        //                 adel_learn: "פרטים נוספים",

        //                 rovero_title: "מלון Rovero",
        //                 rovero_subtitle: "תיירות",
        //                 rovero_description: "CodeSuits פיתחה אתר אינטרנט עבור מלון רוברו עם הזמנת חדר ופתרון תשלום מקוון, כולל תוכן דינמי (CMS), צ'אט חי המופעל על ידי בינה מלאכותית, נגישות ועוד.",
        //                 rovero_learn: "פרטים נוספים",

        //                 relax_title: "PureRelax",
        //                 relax_subtitle: "טיפוח ובריאות",
        //                 relax_description: "פיתחנו אתר מסחר אלקטרוני עבור PureRelax, חברת מוצרי ושירותי יופי ובריאות. הוא כלל תכונות נוספות כגון תוכן דינמי, מערכת הזמנות, פתרונות SEO ועוד.",
        //                 relax_learn: "פרטים נוספים",

        //                 foodily_title: "פודילי",
        //                 foodily_subtitle: "קופי בר",
        //                 foodily_description: "אתר אינטרנט לבית קפה עם מידע על מוצריו, התפריט והאירועים שלו. אפשרנו ללקוח להגביר את המעורבות שלו עם לקוחותיו.",
        //                 foodily_learn: "פרטים נוספים",

        //                 solomon_title: "מלון המלך שלמה - נתניה",
        //                 solomon_subtitle: "תיירות",
        //                 solomon_description: "יצרנו אתר חדש עבור לקוח, שנבנה עם הטכנולוגיות העדכניות ביותר ומערכות עיצוב כדי להחליף ולשדרג את האתר בסגנון הישן שלו במינימום פונקציונליות.",
        //                 solomon_learn: "פרטים נוספים",

        //                 park_title: "מלון פארק",
        //                 park_subtitle: "תיירות",
        //                 park_description: "דוגמה נוספת לאתר שיצרנו עבור לקוח בענף המלונאות והתיירות שרצה להחליף את האתר הישן שלו. המעצבים והמפתחים שלנו יצרו אתר חדשני ואטרקטיבי שעזר ללקוח להגדיל הזמנות.",
        //                 park_learn: "פרטים נוספים",

        //                 lena_title: "לנה ניילס",
        //                 lena_subtitle: "טיפוח ובריאות",
        //                 lena_description: "יצרנו אתר עבור עסק קטן בתעשיית היופי והבריאות כדי להגביר את המעורבות שלו עם לקוחות ומכירות.",
        //                 lena_learn: "פרטים נוספים",

        //                 tracht_title: "טראכטנברג",
        //                 tracht_subtitle: "מזקקה",
        //                 tracht_description: "CodeSuits פיתחה אתר למזקקה של טרכטנברג. הוא סיפק אפשרות הזמנה, תוכן דינמי (CMS) ופתרונות SEO.",
        //                 tracht_learn: "פרטים נוספים",

        //                 wewear_title: "WeWear",
        //                 wewear_subtitle: "חנות אונליין עם AI",
        //                 wewear_description: "אתר אופנה למסחר אלקטרוני מבוסס בינה מלאכותית. הוספנו פונקציונליות של Chatbot כדי להגביר את התמיכה של הלקוחות ולמנף את המכירות על ידי הצעת קמפיינים מבוססי חיזוי.",
        //                 wewear_learn: "פרטים נוספים",
                        
        //                 print_title: "JUSTPRINT",
        //                 print_subtitle: "חנות הדפסות אונליין ",
        //                 print_description: "בניית חנות אונליין להזמנת הדפסות על גבי בגדים, כובעים ואביזרים. במסגרת הפרויקט בנינו מערכת הזמנות, תשלומים וסליקה.",
        //                 print_learn: "פרטים נוספים",


        //                 bfood_title: "הבריאפוד",
        //                 bfood_subtitle: "אתר תדמית למסעדה",
        //                 bfood_description: ". אתר תדמית למסעדה של אוכל בריא וטרי מהחקלאי ללקוח. הוספנו מערכת הזמנות חכמה שחסכה ללקוח כח אדם לקבלת הזמנות",
        //                 bfood_learn: "פרטים נוספים",

        //                 pets_title: "PETS",
        //                 pets_subtitle: "חנות דיגיטאלית למכירת אוכל ואביזרים לבע״ח",
        //                 pets_description: "בנינו עבור הלקוח חנות דיגיטאלית למכירת אוכל ואביזרים לבעלי חיים. בנינו ממשק וחוויית משתמש קלים ונוחים שאפשרו למשתמשים לבצע רכישות בקלות כולל אפשרות לבצע הזמנות חוזרות ולחסוך זמן",
        //                 pets_learn: "פרטים נוספים",


        //             },

        //             saas: {
        //                 saas_title: "אפליקציות Web ו-SaaS",

        //                 squid_title: "SQUID",
        //                 squid_subtitle: "מערכת CRM ",
        //                 squid_description: "מערכת סאאס (שירות כפתרון) למערכת קשרי לקוחות. המערכת נבנתה לאנדרואיד, אייפון ואפליקציית ווב עם שירותי ענן. הוספנו יכולות בינה מלאכותית לשיפור הביצועים",
        //                 squid_learn: "פרטים נוספים",

        //                 qspace_title: "QUICKSPACE",
        //                 qspace_subtitle: "מערכת לניהול נדל״ן",
        //                 qspace_description: "פתחנו מערכת המבוססת בינה מלאכותית עבור חברה לחיפוש נדל״ן. הפתרון אפשר ללקחותיה לאתר נחכסים ביתר קלות ובהתאמה אישית מה שהגדיל את ההמכירות. בנוסף בנינו ללקוח כלים המאפשרים לנתח את התנהגות באתר ולשפר את הקמפיינים והמכירות ע״ס נתונים אלה",
        //                 qspace_learn: "פרטים נוספים",

        //                 saasly_title: "SAASLY",
        //                 saasly_subtitle: "פלטפורמה מתקדמת לניתוח ביצועים",
        //                 saasly_description: "פתחנו פלטפורמת ענן מתקדמת שמאפשרת לחברות ללמוד ולנתח את ביצועי המוצר שלהם תוך הבנה של איזה דברים טוב ואיפה צריך להשתפר כדי להגיע ליעדי החברה",
        //                 saasly_learn: "פרטים נוספים",

        //                 ipsum_title: "LOGOIPSUM",
        //                 ipsum_subtitle: "פלטפורמה לניהול עסקים",
        //                 ipsum_description: "הצטרפנו לצוותי הפרוקדט והפיתוח של הלקוח כדי לעזור בפיתוח פלטפורמה לניהול עסקים. מומחי הענן שלנו עזרו בפיתוח פתרונות ענן כדי לאפשר למוצר לתמוך במספר רב של משתמשי קצה",
        //                 ipsum_learn: "פרטים נוספים",


        //                 health_title: "Healthness",
        //                 health_subtitle: "מערכת שירותי בריאות ואורך חיים בריא",
        //                 health_description: "פיתחנו אפליקציית אנדרואיד ואייפון עם יכולות בינה מלאכותית המאפשרות זיהוי מצבי הרוח של משתמשי הקצה ע״י מערכת לזיהוי פנים שבנינו. בהתאם לזיהוי מצח הרוח המשתמשים קבלו המלצות שונות בהתאמה אישית.",
        //                 health_learn: "פרטים נוספים",
        //             },

        //             mobile: {

        //                 mobile_title: "אפליקציות מובייל",


        //                 grab_title: "FASTGRAB",
        //                 grab_subtitle: "אפליקציה לשליחויות בתחום המזון",
        //                 grab_description: "פתחנו אפליקציה לאנדרואיד ואייפון המיועדת לשליחויות של אוכל מבתי עסק ללקוחות. האפליקציה כללה יכולות של הזמנה, תשלום, שיחת צאט ועוד",
        //                 grab_learn: "פרטים נוספים",


        //                 health_title: "HEALTHNESS",
        //                 health_subtitle: "מערכת שירותי בריאות ואורך חיים בריא",
        //                 health_description: "פיתחנו אפליקציית אנדרואיד ואייפון עם יכולות בינה מלאכותית המאפשרות זיהוי מצבי הרוח של משתמשי הקצה ע״י מערכת לזיהוי פנים שבנינו. בהתאם לזיהוי מצח הרוח המשתמשים קבלו המלצות שונות בהתאמה אישית.",
        //                 health_learn: "פרטים נוספים",


        //                 rides_title: "RIDES",
        //                 rides_subtitle: "אפליקציה עבור חנות אופניים",
        //                 rides_description: "פתחנו בפלאטר אפליקציה לאנדרואיד ואייפון עבור רשת אופניים קטנה. האפליקציה חוברה לענן וכללה יכולות של הזמנה ותשלום, הכנסת תוכן דינאמי וניתוח ביצועים ע״י הלקוח",
        //                 rides_learn: "פרטים נוספים",


        //                 netpay_title: "NETPAY",
        //                 netpay_subtitle: "אפליקציית ארנק דיגיטאלי",
        //                 netpay_description: "פיתחנו עבור גוף פיננסי אפליקציית ארנק דיגיטאלי המאפשרת לבצע תשלומים לבתי עסק ולהעביר כספים לחשבונות אחרים. בנוסף, בנינו לבקשת הלקוח, כלים המנתחים את ההתנהגות הפיננסית של המשתמש כדי לעזור לו להפחית את ההוצאות ולקבל תנאים טובים יותר בהמשך ע״י אותו גוף פיננסי",
        //                 netpay_learn: "פרטים נוספים",
        //             },


        //             design: {
        //                 design_title: "UX/UI פתרונות",


        //                 ux_title: "חוויית משתמש",
        //                 ux_description: "גם אפליקציה מוצלחת, עם התמורה הטובה ביותר עבור המשתמשים שלה, עשויה להתמודד עם נטישה מוגברת אם המשתמשים יאבדו את עצמם במוצר. אנו יודעים ליצור חווית משתמש ידידותית כדי להשאיר את המשתמש בתוך האפליקציה. בדוק את דוגמאות ה-UX שלנו על מוצרים שונים.",
        //                 ux_learn: "פרטים נוספים",

        //                 ui_title: "ממשק משתמש",
        //                 ui_description: "על ידי יצירת ממשק אינטואיטיבי וקל לשימוש, עיצוב ממשק המשתמש משפר את חווית המשתמש הכוללת והופך את האפליקציה למהנה יותר לאינטראקציה. הצלחתה של אפליקציה מסתמכת במידה רבה על מעורבות המשתמש. אנו בונים תפיסות עיצוב ממשק משתמש מותאמות אישית לכל לקוח עם מערכות העיצוב ומדריך הסגנונות הרלוונטיים.",
        //                 ui_learn: "פרטים נוספים",

        //                 media_title: "עיצובים למרקטינג",
        //                 media_description: "עבודת העיצוב שלנו אינה מסתיימת בגבולות התוכנה. אנו עוזרים ללקוחותינו ליידע את המשתמשים על כך על ידי יצירת קמפיינים שיווקיים חזותיים אטרקטיביים. לחצו על הלחצן למטה כדי לסקור חלק מהעבודות שלנו.",
        //                 media_learn: "פרטים נוספים",

        //                 docs_title: "עיצובים לדוקמנטציה",
        //                 docs_description: "ייתכן שהמוצר שלך לא יהיה שלם ללא תיעוד ברור שינחה את המשתמשים שלך כיצד להשתמש בכל תכונה. בדקו כמה מהתיעוד והערות השחרור שלנו בפעולה.",
        //                 docs_learn: "פרטים נוספים",
        //             },


        //             pages: {


        //                 justprint: {

        //                     jp_page_title: "JUSTPRINT",
        //                     jp_subtitle1: "חנות אי-קומרס להדפסות",
        //                     jp_subtitle2: "חנות אונליין עם מערכת הזמנות, סליקה ותוכן דינאמי הנשלט ע״י הלקוח",
        //                     jp_description: "תיאור הפרויקט",
        //                     jp_client_title: "הלקוח",
        //                     jp_product_title: "המוצר",
        //                     jp_work_title: "העבודה",
        //                     jp_client_description: "חנות להדפסות שרצתה אתר כדי להגדיל את היקף המכירות שלה",
        //                     jp_product_description: "אתר מכירות אונליין עם עיצוב וחוויית משתמש ידידותיים ונוחים המאפשרים חווית קניות קלה וכייפית עבור המשתמש. מערכת הזמנות וסליקה ויכולת שינוי תוכן באופן דינאמי ע״י הלקוח",
        //                     jp_work_description: "לאחר קבלת הדרישות מהלקוח בנינו עיצוב ראשוני ופרוטוטייפ שהוצג ללקוח. לאחר קבלת אור ירוק מהלקוח התחלנו בפיתוח האתר תוך כדי סנכרון בעבודה מול הלקוח כדי לוודא שאנחנו בכיוון ולמנוע בזבוז זמן מיותר",

        //                     jp_profile: "פרופיל",
        //                     jp_profile_country_title: "מדינה",
        //                     jp_profile_country_value: "ישראל",
        //                     jp_profile_industry_titile: "תעשייה",
        //                     jp_profile_industry_value: "הדפסות",
        //                     jp_profile_team_titile: "הצוות שלנו",
        //                     jp_profile_team_value: "מ. פרויקט, מעצבת, מפתח",

        //                     jp_goals: "יעדים ומטרות",
        //                     jp_goal1: "בניית אתר מכירות",
        //                     jp_goal2: "הלקוח יכול לשנות את התוכן",
        //                     jp_goal3: "מספר דרכי התקשרות",
        //                     jp_goal4: " SEO הגדלת החשיפה לאתר ע״ ",
        //                     jp_goal5: "תמיכה בדומיין ואחסון",

        //                     jp_challanges: "אתגרים",
        //                     jp_challange_title1: "לפשט חוויית קניות",
        //                     jp_challange_1: "להקטין את מספר הצעדים הנדרדשים כדי להשלים את תהליך הקניה",
        //                     jp_challange_title2: "שינוי תוכן קל",
        //                     jp_challange_2: "לייצר ממשק ידידותי עבור הלקוח בו הוא יכול לשנות בקלות תוכן כמו פריטים ומחירים",
        //                     jp_challange_title3: "הגדלת חשיפת האתר",
        //                     jp_challange_3: "בניית אסטרטגיה לחשיפת האתר בחיפוש ע״ משתמשים",

        //                     jp_solution_title: "הפתרון",
        //                     jp_solution_description1: "המעצבת שלנו יצרה עיצוב רלבנטי ע״ס הדרישות ופרוטוטייפ כדי שהלקוח ייאשר",
        //                     jp_solution_description2: "המפתח שלנו התחיל לעבוד על האתר לפי העיצוב שאושר תוך שימוש בכלים רלוונטים",
        //                     jp_solution_description3: "השתמשנו בכלים חיצוניים ופופולאריים , כמו למשל, וו-קומרס , כדי לבצע סליקה ומעקב מכירות",

        //                     jp_techstack: "טכנולוגיות",

        //                     jp_techstack1: "PHP",
        //                     jp_techstack2: "WooCommerce",
        //                     jp_techstack3: "WordPress",
        //                     jp_techstack4: "Joomla",

        //                     jp_results_title: "תוצאות",
        //                     jp_result_title1: "⭡ הגדלת חשיפה",
        //                     jp_result_description1: "דירוג האתר הלך והשתפר עם הזמן והחשיפה של האתר עלתה",
        //                     jp_result_title2: "⭡ הגדלת מכירות",
        //                     jp_result_description2: "בניית האתר ללקוח ע״י קודסוויטס העלתה את היקף המכירות של הלקוח בכ-25% בחצי שנה הראשונה",
        //                     jp_result_title3: "שינוי תוכן יעיל",
        //                     jp_result_description3: "הלקוח מעדכן מדי פעם את התוכן באתר בקלות ובשביעות רצון",


        //                 },

        //                 barifood: {

        //                     bf_title: "הבריא-פוד",
        //                     bf_subtitle1: "אתר למסעדה",
        //                     bf_subtitle2: "בנינו אתר למסעדת אוכל בריא שהדגשים היו עיצוב חדשני ואטרקטיבי, טיפול בבעיות סנכרון של הזמנות אונליין ולהוסיף כלים שיעזרו לחשוף את האתר יותר בחיפושי גולשים באינטרנט",
        //                     bf_description: "תיאור הפרויקט",
        //                     bf_client_title: "הלקוח",
        //                     bf_product_title: "המוצר",
        //                     bf_work_title: "העבודה",
        //                     bf_client_description: "הלקוח הוא מסעדה ששמה דגש על אוכל בריא ורכיבים טריים בשיטת מהשדה לסועד. הלקוח רצה לעשות שינויים באתר הנוכחי עקב בעיות טכניות ועיצוב לא אטרקטיבי שהיו באתר הקיים",
        //                     bf_product_description: "הצוות שלנו עיצב ובנה אתר חדש שמצד אחד מציד את תפריט המסעדה, עם אפשרות לשנות תוכן באופן דינאמי ע״י הלקוח, ויכולת ניהול טובה של מערכת הזמנות הלקוחות",
        //                     bf_work_description: "לאחר הבנת הדרישות, המעצב שלנו יצר עיצוב חדש לאתר שכלל חוויית משתמש חדשה וממשק משתמש אטרקטיבי. יצרנו פרוטוטייפ בפיגמה שהוצג ללקוח. לאחר אישור הלקוח התחלנו תהליך של פיתוח האתר שנמשך כ-3 שבועות בהן בנינו את האתר עם יכולות חדשות של ניהול מערכת ההזמנות",

        //                     bf_profile: "פרופיל",
        //                     bf_profile_country_title: "מדינה",
        //                     bf_profile_country_value: "ישראל",
        //                     bf_profile_industry_titile: "תעשיה",
        //                     bf_profile_industry_value: "מסעדנות",
        //                     bf_profile_team_titile: "הצוות שלנו",
        //                     bf_profile_team_value: "3 אנשי צוות",

        //                     bf_goals: "ייעדים ומטרות",
        //                     bf_goal1: "עיצוב מחודש ואטרקטיבי לאתר",
        //                     bf_goal2: "בניית פתרון לניהול הזמנות",
        //                     bf_goal3: "מערכת לתוכן דינאמי",
        //                     bf_goal4: "הוספת כלי חשיפה של האתר ",

        //                     bf_challanges: "אתגרים",
        //                     bf_challange_title1: "פתרון בעיית הזמנות",
        //                     bf_challange_1: "מציאת פתרון לבעיות הסנכרון של מערכת ההזמנות באתר ומקומות פנויים במסעדה",
        //                     bf_challange_title2: "עלייה החשיפת האתר",
        //                     bf_challange_2: "הוספת כלים שיעזרו לאתר להופיע יותר בחיפושי גולשים ברשת",
        //                     bf_challange_title3: "שינוי תוכן דינאמי",
        //                     bf_challange_3: "הוספת יכולות לשינוי תוכן ע״י הלקוח",

        //                     bf_solution_title: "פתרונות",
        //                     bf_solution_description1: "פתחנו מערכת מבוססת בינה מלאכותית שמזהה מתי יש עומס בהזמנות ולפי זה נותנת ללקוח התראה בזמן על צפי גדול של לקוחות",
        //                     bf_solution_description2: "חיבור נכון של מערכת ההזמנות באתר עם מערכת הזמנות במסעדה",
        //                     bf_solution_description3: "הוספת כלי שיווק מתקדמים",

        //                     bf_techstack: "טכנולוגיות",
        //                     bf_techstack1: "WordPress",
        //                     bf_techstack2: "Joomla",
        //                     bf_techstack3: "Python",
        //                     bf_techstack4: "AWS",


        //                     bf_results_title: "תוצאות",
        //                     bf_result_title1: "⭡ עלייה בהזמנות",
        //                     bf_result_description1: "עלייה של כ-35% בהזמנות דרך האתר",
        //                     bf_result_title2: "⭣ ירידה ברישום כפול",
        //                     bf_result_description2: "ירידה במקרים של דאבל בוקינג אודות לפתרון חדש של סנכרון בין האתר למערכת במסעדה",
        //                     bf_result_title3: "⭡ עלייה בחשיפה",
        //                     bf_result_description3: "עלייה של כ-20-25% במספר הלקוחות שבצעו הזמנות באתר לאחר שהגיעו אליו מחיפוש שעשו במנוע חיפוש",


        //                 },

        //                 wewear: {

        //                     ww_title: "WeWear",
        //                     ww_subtitle1: "חנות אופנה אונליין ",
        //                     ww_subtitle2: "צ'אטבוט מבוסס בינה מלאכותית עבור מתן תמיכה ומענה ללקוחות באתר",
        //                     ww_description: "תיאור הפרויקט",
        //                     ww_client_title: "הלקוח",
        //                     ww_product_title: "המוצר",
        //                     ww_work_title: "העבודה",
        //                     ww_client_description: "הלקוח הוא חברה בתחום האופנה. החברה מוכרת פריטי לבוש ואופנה באנגליה בעיקר דרך הרשת ע״י מספר אתרים",
        //                     ww_product_description: "אתר מכירות מבוסס בינה מלאכותית עם צ'אטבוט שנועד לתת מענה לפניות לקוחות. בנוסף, הוא מקפיץ הצעות ללקוח באופן אישי בהתאם לזיהוי הרגלי הקניות של כל לקוח ",
        //                     ww_work_description: "בניית אתר מכירות עם יכולות שליטה בתוכן באופן דינאמי, בניית עיצוב אטרקטיבי ומודלים מבוססי בינה מלאכותית שיענו על דרישות המוצר",

        //                     ww_profile: "פרופיל",
        //                     ww_profile_country_title: "מדינה",
        //                     ww_profile_country_value: "אנגליה",
        //                     ww_profile_industry_titile: "תעשייה",
        //                     ww_profile_industry_value: "אופנה",
        //                     ww_profile_team_titile: "הצוות שלנו",
        //                     ww_profile_team_value: "מ.פרויקט, חוקר, מפתחים ,מעצבת",

        //                     ww_goals: "ייעדים ומטרות",
        //                     ww_goal1: "בניית אתר מכירות אונליין",
        //                     ww_goal2: "הלקוח יכול לשנות את התוכן",
        //                     ww_goal3: "חיבור למערכת סליקה",
        //                     ww_goal4: "הכנסת אוטומציה בתמיכת לקוחות",
        //                     ww_goal5: "להכניס צ'אטבוט שיש עומס פניות",

        //                     ww_challanges: "אתגרים",
        //                     ww_challange_title1: "איחוד פורמט המידע",
        //                     ww_challange_1: "עיבוד המידע מהאתר בפורמט שהצ'אטבוט יכול לעבוד איתו. זה כולל ניתוח המידע ביצוע המרות והתאמות ואחסון בענן",
        //                     ww_challange_title2: "תגובות מדויקות",
        //                     ww_challange_2: "חידוד התשובות של הצ'אטבוט לפניות ושאלות של לקוחות.",
        //                     ww_challange_title3: "גמישות של הצ'אטבוט",
        //                     ww_challange_3: "הצ'אטבוט צריך להתמודד עם מספר רב של פניות בשעות העומס או מספר קטן יחסית בשעות רגילות. לכן, צריך לוודא שהוא יכול לעבוד בכל עומס של פניות",

        //                     ww_solution_title: "פתרונות",
        //                     ww_solution_description1: "בהתחלה עבודה על הארכיטקטורה של הצ'אטבוט. פתחנו מנגנון שיודע לתרגם בקשות שמגיעות מהצ'אטבוט.  ",
        //                     ww_solution_description2: "פתחנו ממשק שמאפשר לאתר לשלוח בקשות של הצ'אטבוט לענן ולעבד את הבקשות מול המידע ולהפיק תוצאות חזרה לאתר",
        //                     ww_solution_description3: "לבסוף, שחררנו את האתר עם פתרונות בענן של אמזון עם יכולות לעמוד בעומס פניות",

        //                     ww_techstack: "טכנולוגיות",
        //                     ww_techstack1: "WPS",
        //                     ww_techstack2: "PHP",
        //                     ww_techstack3: "N.JS",
        //                     ww_techstack4: "AWS",
        //                     ww_techstack5: "Python",

        //                     ww_results_title: "תוצאה",
        //                     ww_result_title1: "השעייה קטנה מ-5 שניות",
        //                     ww_result_description1: "הצ'אטבוט יכול לתת מענה לכל פניה בפחות מ-5 שניות ב-99% מהמקרים בכל עומס נתון",
        //                     ww_result_title2: "מאה פניות בשניה",
        //                     ww_result_description2: "הצ'אטבוט יכול לתת מענה בשניה לכמאה פניות בלי עכובים או חריגות",
        //                     ww_result_title3: "עליית מכירות ",
        //                     ww_result_description3: "ע״י בניית מנגנון זיהוי של זמנים אידיאליים להצעת קמפיינים ללקוחות המכירות עלו ביותר מחמישים אחוז",


        //                 },



        //                 pets: {

        //                     pets_title: "PETS",
        //                     pets_subtitle1: "חנות אונליין למכירת ציוד ,אוכל וחיות מחמד",
        //                     pets_subtitle2: "בנינו ללקוח אתר עבור מכירת המוצרים שלו. יצרנו ממשק נוח וידידותי למשתמשים וחיברנו את האתר עם מערכת מבוססת בינה מלאכותית לזיהוי הרגלי קנייה של הלקוחות כדי להציע מבצעים עתידיים",
        //                     pets_description: "תיאור הפרויקט",
        //                     pets_client_title: "הלקוח",
        //                     pets_product_title: "המוצר",
        //                     pets_work_title: "העבודה",
        //                     pets_client_description: "הלקוח הוא חנות לממכר חיות מחמד ומיוד נלווה אשר רצה לשדרג את האתר תדמית שלו באתר מכירות אטרקטיבי עם ממשק ידידותי שיעזור לעלות את המכירות",
        //                     pets_product_description: "אתר מכירות אונליין עם מערכת חכמה שלומדת את הרגלי הקניה של הלקוחות כדי להציע קמפיינים עתידיים על סמך קניות עבר של כל לקוח. חיבור של האתר עם מערכת לניהול מלאי והזמנה אוטומטית של מוצרים חדשים",
        //                     pets_work_description: "העבודה כללה איפיון של האתר ברמה העיצובית וברמה הטכנית. מבחינת אלמנטים של סליקה ושינוי תוכן באופן דינאמי ומבחינת פתרון בענן מבוסס בינה מלאכותית ללמידה של הרגלי הקניה של המשתמשים",

        //                     pets_profile: "פרופיל",
        //                     pets_profile_country_title: "מדינה",
        //                     pets_profile_country_value: "ישראל",
        //                     pets_profile_industry_titile: "תעשייה",
        //                     pets_profile_industry_value: "חיות מחמד",
        //                     pets_profile_team_titile: "הצוות שלנו",
        //                     pets_profile_team_value: "5 - מ.פרויקט, מתכנתים, מעצבת, חוקר",

        //                     pets_goals: "ייעדים ומטרות",
        //                     pets_goal1: "חנות אונליין עם פתרונות סליקה",
        //                     pets_goal2: "מערכת ניהול מלאי אוטומטית",
        //                     pets_goal3: "מערכת חכמה לקמפיינים",
        //                     pets_goal4: "מערכת לניהול תוכן דינאמי",

        //                     pets_challanges: "אתגרים",
        //                     pets_challange_title1: "אוטומציה לניהול המלאי",
        //                     pets_challange_1: "חיבור של הפתרון החדש שלנו עם מערכת קיימת לניהול המלאי. וידוא ששתי המערכות מסונכרנות באופן מדויק כדי למנוע כשל בהזמנות של המשתמשים באתר ומצד שני של אספקה חדשה",
        //                     pets_challange_title2: "דיוק בקמפיינים",
        //                     pets_challange_2: "לייצר מערכת מבוססת בינה מלאכותית היודעת  לצפות מתי הזמן הנכון להציע ללקוחות מבצעים עתידיים על סמך קניות עבר",
        //                     pets_challange_title3: "תוכן דינאמי וסליקה",
        //                     pets_challange_3: "הטמעת כלים לניהול ועדכון תוכן באופן דינאמי ומדויק וחיוב ללא טעויות של המשתמשים באתר",

        //                     pets_solution_title: "פתרונות",
        //                     pets_solution_description1: "העבודה החלה בניית העיצוב לאתר ביצירת ממשקי משתמש נוחים לשימוש. בנוסף,  התחלנו ביצירת ארכיטקטורה טכנית לפתרון הנדרש ",
        //                     pets_solution_description2: "התחלנו בפיתוח של התכנון התיאורטי בשלב הראשון. הוספנו בענן יכולות של בינה מלאכותית ללימוד הרגלי הקנייה של הלקוח. הוספנו ממשק בין האתר לענן כדי לשלוח נתונים בצורה מאובטחת. חיברנו את המערכת לניהול המלאי של הלקוח עם הפתרון החדש שלנו",
        //                     pets_solution_description3: "עשינו בדיקות קצה לקצה כדי לוודא שהכל עובד כמו שצריך",

        //                     pets_techstack: "טכנולוגיות",
        //                     pets_techstack1: "WPS",
        //                     pets_techstack2: "React",
        //                     pets_techstack3: "Node.JS",
        //                     pets_techstack4: "WooCommerce",
        //                     pets_techstack5: "Joomla",

        //                     pets_results_title: "תוצאה",
        //                     pets_result_title1: "עלייה במכירות",
        //                     pets_result_description1: "סה״כ המכירות של הלקוח עלו בכ-30% אודות לפיתוח של האתר החדש עם העיצוב החדש והפתרונות הנלווים",
        //                     pets_result_title2: "עליה בייעול הזמן",
        //                     pets_result_description2: "פתרון האוטומציה שהצוות שלנו פתח עבור הלקוח עזר לצמצם את זמן הטיפול בניהול המלאי עבור מוצרים שנמכרו באתר הקודם בכ-38 אחוז",
        //                     pets_result_title3: "עלייה בהתקשרות הלקוח",
        //                     pets_result_description3: "ע״י העיצוב החדש והפיצ'רים שהתווספו, האתר החדש עזר לעלות את ההתקשרות מול המשתמשים וזמן השהייה שלהם באתר ",


        //                 },


        //                 squid: {

        //                     squid_title: "SQUID",
        //                     squid_subtitle1: "CRM מערכת",
        //                     squid_subtitle2: "מערכת מבוססת סאאס שפתחנו לדרישת הלקוח כאפליקציית ווב ומובייל על אנדרואיד ואייפון",
        //                     squid_description: "תיאור הפרויקט",
        //                     squid_client_title: "הלקוח",
        //                     squid_product_title: "המוצר",
        //                     squid_work_title: "העבודה",
        //                     squid_client_description: "הלקוח הוא סאטרטאפ שפיתח את המוצר והיה צריך תגבור מבחינת תכנון הארכיטקטורה , העיצובים והמימוש",
        //                     squid_product_description: "מערכת לניהול קרי לקוחות המבוססת בינה מלאכותית לייעול תהליכים. המוצר בנוי מממשק המאפשר להגדיר לקוחות ולפתוח כרטיסים, לשלוח קמפיינים בשיטת אומני צ'אנל.",
        //                     squid_work_description: "הצטרפנו לצוות האורגני של החברה כדי לעזור בבניית הארכיטקטורה למוצר. המעצבת שלנו יצרה עיצוב לפי הדרישות ובנתה פרוטוטייפ שבעזרתו כל הצוותים יכלו להתקדם במימוש בהמשך.",

        //                     squid_profile: "פרופיל",
        //                     squid_profile_country_title: "מדינה",
        //                     squid_profile_country_value: "USA",
        //                     squid_profile_industry_titile: "תעשייה",
        //                     squid_profile_industry_value: "CRM",
        //                     squid_profile_team_titile: "הצוות שלנו",
        //                     squid_profile_team_value: "6 - מ.פרויקט, מתכנתים, מעצבת, חוקרים",

        //                     squid_goals: "ייעדים ומטרות",
        //                     squid_goal1: "פיתוח אפליקציית ווב מבוססת ענן",
        //                     squid_goal2: "סנכרון עם אפליקציות אנדרואיד ואייפון",
        //                     squid_goal3: "פיצ'רים מבוססי בינה מלאכותית",
        //                     squid_goal4: "יכולת תמיכה במיליוני משתמשים",

        //                     squid_challanges: "אתגרים",
        //                     squid_challange_title1: "חוויית משתמש",
        //                     squid_challange_1: "שיפור חוויית המשתמש בכל תהליך במוצר עם מספר רב של צעדים ופעולות הנדרשים ע״י המשתמש. למשל, תהליך הוספת לקוח או יצירת כרטיס חדש",
        //                     squid_challange_title2: "המרת סגנון עבודה",
        //                     squid_challange_2: "בניית המוצר בצורה כזאת שיוכל לתמוך בלקוחות שעבדו עד עכשיו באמצעים ידניים או מרובי אפליקציות לניהול הלקוחות שלהם ולדעת לייבא ולרכז את כל המידע שנאגר עד עכשיו למערכת החדשה בלי לשבש נתונים",
        //                     squid_challange_title3: "תמיכה מרובה",
        //                     squid_challange_3: "בניית מערכת עם שירותים מבוססי ענן היכולה לספק תמיכה במיליוני משתמשים תוך כדי שמירה על תקציב סביר ",

        //                     squid_solution_title: "פתרונות",
        //                     squid_solution_description1: "לאחר תכנון הארכיטקטורה באמצעות שירותים מבוססי ענן AWS, היא הוצגה ללקוח ואושרה.",
        //                     squid_solution_description2: "מתכנת הבקהנד שלנו התחיל לעבוד על ההגדרה בעוד שאר הצוות עבד על הפרונטהנד, אפליקציות לנייד ומודלים של נתונים לשימוש.",
        //                     squid_solution_description3: "דגמי הנתונים, שנכתבו ב-פייטון, שולבו כשירות ענן המשמש את יישומי האינטרנט והנייד.",

        //                     squid_techstack: "טכנולוגיות",
        //                     squid_techstack1: "PHP",
        //                     squid_techstack2: "N.JS",
        //                     squid_techstack3: "AWS",
        //                     squid_techstack4: "Kotlin",
        //                     squid_techstack5: "Swift",
        //                     squid_techstack6: "Python",

        //                     squid_results_title: "תוצאה",
        //                     squid_result_title1: "⭡ יצירת לידים",
        //                     squid_result_description1: "הודות לעיצוב שהצוות שלנו עשה, הן בהיקפים הטכניים והן בהיקפים של ממשק המשתמש, הוא הפך את תהליך היצירה והניהול של לידים חדשים ליעיל וקל יותר.",
        //                     squid_result_title2: "פחות זמן ניהול",
        //                     squid_result_description2: "השימוש בפתרונות הבינה המלאכותית שלנו עזר להפחית את זמן ההוצאה לניהול משימות בכ-20%.",
        //                     squid_result_title3: "אורקסטריישן טוב יותר",
        //                     squid_result_description3: "פיתחנו כלים המופעלים על ידי בינה מלאכותית כדי לשפר את הניהול הרב-ערוצי.",


        //                 },


        //                 quickspace: {

        //                     qs_title: "QUICKSPACE",
        //                     qs_subtitle1: "אתר נדל״ן מבוסס-ענן",
        //                     qs_subtitle2: "פתרון מבוסס ענן עם יכולות בינה מלאכותית כדי לתת תוצאות יותר טובות עבור המשתמשים",
        //                     qs_description: "תיאור הפרויקט",
        //                     qs_client_title: "הלקוח",
        //                     qs_product_title: "המוצר",
        //                     qs_work_title: "העבודה",
        //                     qs_client_description: "הלקוח הוא חברת נדל״ן שרצתה לשדרג את האתר הישן שלה באתר מתקדם עם יכולות ביצוע יותר טובות ומראה יותר אטרקטיבי שמעודכן לטכנולוגיות עדכניות",
        //                     qs_product_description: "בניית אפליקציית ווב המאפשרת למשתמש מצד אחד לחפש נכסים לפי קריטריונים מסויימים ומצד שני להציע התאמות דומות ע״ס נתונים שהכניס למערכת תוך משם דגש על התאמה אישית",
        //                     qs_work_description: "בנינו אתר שמחובר לשירותי ענן רלבנטים , יצרנו מודלים להפקת התוצאות הנדרשות תוך הקפדה על זמן ביצוע מינימלי",

        //                     qs_profile: "פרופיל",
        //                     qs_profile_country_title: "מדינה",
        //                     qs_profile_country_value: "USA",
        //                     qs_profile_industry_titile: "תעשייה",
        //                     qs_profile_industry_value: "Real Estate",
        //                     qs_profile_team_titile: "הצוות שלנו",
        //                     qs_profile_team_value: "4 - מהנדסי תוכנה , מעצבת ומ. פרויקט ",

        //                     qs_goals: "ייעדים ומטרות",
        //                     qs_goal1: "עיצוב ובניית האתר",
        //                     qs_goal2: "יכולת תוכן דינאמי",
        //                     qs_goal3: "ניהול המידע בענן",
        //                     qs_goal4: "AI פיתוח מודלי ",


        //                     qs_challanges: "אתגרים",
        //                     qs_challange_title1: "השהייה בתוצאות",
        //                     qs_challange_1: "לקחנו בחשבון יתכנות של עיכובים בהצגת הנתונית למשתמש כתוצאה של ביצועי רשת וניהול המידע והמודלים בענן",
        //                     qs_challange_title2: "אינטגרצית מידע",
        //                     qs_challange_2: "היה חשוב שהמידע והתוצאות שהפתרון שלנו מציג ללקוח או מועבר למערכת הניהול לקוחות של הלקוח נכונים, גם מהסיבה שהם מבוססים על אינטגרציה מול המערכות של הלקוח ואינטגרציה לא נכונה עשויה להוביל לטעויות",
        //                     qs_challange_title3: "תמיכה מרובה",
        //                     qs_challange_3: "היה חשוב שהפתרון שלנו ידע לתמוך במיליוני משתמשי קצה בעומסים שונים ובזמנים שונים מבלי לאבד מיעילות הביצוע שלו",

        //                     qs_solution_title: "פתרונות",
        //                     qs_solution_description1: "הצוותים שלנו יצרו את הארכיטקטורה ברמה גבוהה ואת עיצוב . הגדרנו באילו שירותים בתוך אמזון צריך להשתמש.",
        //                     qs_solution_description2: "באמצעות מערכי נתונים ושימוש בכלים כמו טנסרפלו, מהנדס הבינה מלאכותית שלנו פיתח מודלים כדי לספק תוצאות דומות עבור דגימות נתונות",
        //                     qs_solution_description3: "שילבנו את מסד הנתונים שלנו עם מקורות משתמשים קיימים, שילבנו את כל הפתרונות לפריסה ובדקנו שכל הדרישות מיושמות כהלכה.",

        //                     qs_techstack: "טכנולוגיות",
        //                     qs_techstack1: "PHP",
        //                     qs_techstack2: "N.JS",
        //                     qs_techstack3: "Python",
        //                     qs_techstack4: "WPS",


        //                     qs_results_title: "תוצאה",
        //                     qs_result_title1: "⭡ עלייה ברווח",
        //                     qs_result_description1: "על ידי מתן פתרון להתאמה אישית, החיפוש של המשתמשים הביא לשיעור גבוה יותר עבור התאמת הנכס שלהם, מה שהוביל לעלייה בהכנסות הלקוחות.",
        //                     qs_result_title2: "שיפור המדידה",
        //                     qs_result_description2: "הכלים שפיתחנו אפשרו ללקוח לשפר את האופן שבו הם מודדים את ביצועי המשתמשים בתוך האתר.",
        //                     qs_result_title3: "שיפור ניהול לקוחות",
        //                     qs_result_description3: "הפתרון שפיתחנו, כולל אינטגרציה עם מערכת הניהול לקוחות שלהם, אפשר ללקוח לשפר את ניהול הלקוחות שלו דרך האתר.",


        //                 },


        //                 saasly: {

        //                     saasly_title: "SAASLY",
        //                     saasly_subtitle1: "פלטפורמה מתקדמת לניתוח מידע",
        //                     saasly_subtitle2: "מערכת עסק לעסק מבוססת ענן עם יכולות מתקדמות לעזור לעסקים לנתח את המידע והביצועים של המוצרים והמשתמשים שלהם ",
        //                     saasly_description: "תיאור הפרויקט",
        //                     saasly_client_title: "הלקוח",
        //                     saasly_product_title: "המוצר",
        //                     saasly_work_title: "העבודה",
        //                     saasly_client_description: "הלקוח הוא סטראטאפ בתחילת דרכו , בשלבים ראשונים של בניית המוצר עם תקציב וכח אדם דל",
        //                     saasly_product_description: "מוצר מונחה מידע המבוסס על שירותי ענן המשתלב עם מוצרים של לקוחות ע״י ממשקי תוכנה ומנתח את הביצועים שלהם ברמות שונות של מידע",
        //                     saasly_work_description: "אספנו דרישות מהלקוח ובנינו ארכיטקטורה טכנית . המעצב שלנו בנו עיצוב ופרוטוטייפ שעזר להתקדם בפיתוח.",

        //                     saasly_profile: "פרופיל",
        //                     saasly_profile_country_title: "מדינה",
        //                     saasly_profile_country_value: "UK",
        //                     saasly_profile_industry_titile: "תעשייה",
        //                     saasly_profile_industry_value: "תוכנה וניתוח מידע",
        //                     saasly_profile_team_titile: "הצוות שלנו",
        //                     saasly_profile_team_value: "5 - מ.פרויקט, מתכנתים ומעצב",

        //                     saasly_goals: "ייעדים ומטרות",
        //                     saasly_goal1: "פיתוח דשבורד ועמודים נוספים עם פיצ'רים של ניתוח המידע",
        //                     saasly_goal2: "בנינו מוניטור וכלי ניהול עבור הלקוח",
        //                     saasly_goal3: "בניית ממשק תוכנה מול מוצרי משתמשים",
        //                     saasly_goal4: "פיתוח שירותי ענן לניהול המידע",

        //                     saasly_challanges: "אתגרים",
        //                     saasly_challange_title1: "דיוק המידע",
        //                     saasly_challange_1: "מאחר ותכלית המוצר העיקרית היא להצגיע נתונים, מאוד חשוב שהמידע יהיה מדויק",
        //                     saasly_challange_title2: "ביצועים",
        //                     saasly_challange_2: "חשוב שהמידע המחושב בענן בזמן אמת יוצג למשתמש בלי דיליי",
        //                     saasly_challange_title3: "חויית משתמש",
        //                     saasly_challange_3: "מאחר וזה מוצר מונחה נתונים , עמוס במספרים וחישובים, נדרש לייצר חוויית משתמש ידודתית שהמשתמש יוכל לעבוד במוצר בקלות",

        //                     saasly_solution_title: "פתרונות",
        //                     saasly_solution_description1: "יצרנו ארכיטקטורה עבור ניהול המידע בין שירותי הענן וממשק הפרונטהנד",
        //                     saasly_solution_description2: "יצרנו עיצוב למוצר אשר עזר לפתח את הפונקציונליות הנדרשת",
        //                     saasly_solution_description3: "בדקנו את המוצר מול כלים שונים ובעזרת לקוחות פוטנציאליים של הלקוח לוודא שהנתונים עוברים היטב באינטגרציה מול המוצר שלהם",

        //                     saasly_techstack: "טכנולוגיות",
        //                     saasly_techstack1: "React",
        //                     saasly_techstack2: "N.JS",
        //                     saasly_techstack3: "AWS",
        //                     saasly_techstack4: "MySQL",
        //                     saasly_techstack5: "Python",

        //                     saasly_results_title: "תוצאה",
        //                     saasly_result_title1: "שחרור מוצר",
        //                     saasly_result_description1: "עזרנו ללקוח לשחרר לשוק מוצר שעובד ונותן ערך ללקוחות שלו תוך פרק זמן של כ-9 חודשים",
        //                     saasly_result_title2: "דיוק תוצאות",
        //                     saasly_result_description2: "המוצר הצליח לייצר בדרסה הראשונה דיוק נתונים של ניתוח אנליטי עם שיעור דיוק של 92% שהשתפר עם הזמן.",
        //                     saasly_result_title3: "הורדת זמן השהייה",
        //                     saasly_result_description3: "שיפרנו את הפתרון שלנו במהלך הזמן שבו הוא הצליח לספק תוצאות בפחות מ-3 שניות.",
        //                 },

        //                 logoipsum: {

        //                     logo_title: "LOGOIPSUM",
        //                     logo_subtitle1: "פלטפורמת ניהול לעסקים",
        //                     logo_subtitle2: "הצטרפנו לצוותים של הלקוח כדי לפתח כלי ניהול המיועד לארגונים שמוכנים לשפר את גישת העבודה שלהם.",
        //                     logo_description: "תיאור הפרויקט",
        //                     logo_client_title: "הלקוח",
        //                     logo_product_title: "המוצר",
        //                     logo_work_title: "העבודה",
        //                     logo_client_description: "הלקוח הוא חברת תוכנה צעירה עם משאבים נמוכים שרצתה לזרז את תהליך הפיתוח של המוצר שלה. הצטרפנו עם הידע והניסיון שלנו וסייענו בפיתוח וייעוץ לקיצור תהליך השחרור.",
        //                     logo_product_description: "המוצר הוא פלטפורמת 'ניהול עבודה' באינטרנט ובמובייל שנועדה לעזור לצוותים לארגן, לעקוב ולנהל את עבודתם.",
        //                     logo_work_description: "הצטרפנו לצוות האורגני ועזרנו לתכנן את פתרון הארכיטקטורה. המעצב שלנו סיפק את העיצובים ואת אב הטיפוס עבור כל פלטפורמה. לבסוף, חיברנו את פתרון הענן עם יישומי המובייל והאינטרנט.",

        //                     logo_profile: "פרופיל",
        //                     logo_profile_country_title: "מדינה",
        //                     logo_profile_country_value: "USA",
        //                     logo_profile_industry_titile: "תעשייה",
        //                     logo_profile_industry_value: "SW",
        //                     logo_profile_team_titile: "הצוות שלנו",
        //                     logo_profile_team_value: "6 - מ.פרויקט,מובייל, פולסטאק,מעצב",

        //                     logo_goals: "ייעדים ומטרות",
        //                     logo_goal1: "בניית מוצר מרובה פלטפורמות",
        //                     logo_goal2: "יצירת כלים מבוססי בינה מלאכותית",
        //                     logo_goal3: "ניהול מידע בענן",
        //                     logo_goal4: "API ממשק ",

        //                     logo_challanges: "אתגרים",
        //                     logo_challange_title1: "סנכרון פלטפורמות",
        //                     logo_challange_1: "בניית פתרון חוצה פלטפורמות מעוררת מספר אתגרים. לדוגמה, ייתכן שפיתוח תכונה באנדרואיד לא יעבוד ב-אייפון עקב מגבלות פלטפורמה.",
        //                     logo_challange_title2: "סנכרון מידע",
        //                     logo_challange_2: "זה היה מאתגר לסנכרן את נתוני הענן עם כל פלטפורמה כראוי. השרת שבנינו היה צריך לתמוך במיליוני בקשות מכל פלטפורמה ולהשוות את התגובה בין השתיים.",
        //                     logo_challange_title3: "איזון עומסים",
        //                     logo_challange_3: "The server had to properly support and manage millions of requests with scaling capabilities.",

        //                     logo_solution_title: "פתרונות",
        //                     logo_solution_description1: "תכננו והטמענו את ניהול מסד הנתונים באמצעות שירותי אמזון'.",
        //                     logo_solution_description2: "השתמשנו בדאטה-סימק של אמזון כדי לשפר את הסנכרון של שלושת הפלטפורמות",
        //                     logo_solution_description3: "בהתבסס על הדרישות של צוות המוצר של הלקוח, המעצב שלנו, באמצעות כלים כמו פיגמה ואילוסטרייטור, יצר את העיצובים ואבות הטיפוס הרלוונטיים.",

        //                     logo_techstack: "טכנולוגיות",
        //                     logo_techstack1: "R.Native",
        //                     logo_techstack2: "N.JS ",
        //                     logo_techstack3: "Python",
        //                     logo_techstack4: "AWS",

        //                     logo_results_title: "תוצאה",
        //                     logo_result_title1: "עמידה בזמנים",
        //                     logo_result_description1: "עזרנו ללקוח לשחרר את המוצר בזמן לאחר ~10 חודשים של עבודה אינטנסיבית.",
        //                     logo_result_title2: "⭡ משתמשים חדשים",
        //                     logo_result_description2: "הממשק הידידותי שהצוות שלנו יצר, והאפליקציה האחורית, יחד עם עבודת שיווק טובה של הלקוח שלנו, עזרו למוצר להיות מאומץ על ידי לקוחות רבים.",
        //                     logo_result_title3: "מדידת ביצועים",
        //                     logo_result_description3: "הכלי האנליטי שבנינו עוזר ללקוח למדוד את הצלחת המוצר שלו.",
        //                 },

        //                 fastgrab: {

        //                     fg_title: "FASTGRAB",
        //                     fg_subtitle1: "אפליקציית משלוחי אוכל",
        //                     fg_subtitle2: "אפליקציית משלוחי אוכל למכשירי אנדרואיד ואייפון. דוגמאות תכונה: הזמנת מזון, רכישה וחיסול. בקרת תוכן דינמית על ידי הלקוח. תכונת צ'אט. ניתוח ביצועי אפליקציה.",
        //                     fg_description: "תיאור הפרויקט",
        //                     fg_client_title: "הלקוח",
        //                     fg_product_title: "המוצר",
        //                     fg_work_title: "העבודה",
        //                     fg_client_description: "הלקוח הוא חברת משלוחי מזון חדשה שחיפשה פתרון נייד לעסק שלהם.",
        //                     fg_product_description: "המוצר הוא אפליקציה סלולרית למכשירי אנדרואיד ואייפון. לקוחות יכולים להזמין אוכל משותפי המסעדות ובתי הקפה של הפלטפורמה, או לאסוף את ההזמנה או לקבל אותה משותפי המשלוחים של הפלטפורמה.",
        //                     fg_work_description: "התאמת הדרישות עם הלקוח. הכנת עיצובי חוויית וממשקי משתמש וארכיטקטורה טכנית. המפתחים החלו לעבוד על הפתרון מבוסס הענן ופיתוח מובייל.",

        //                     fg_profile: "פרופיל",
        //                     fg_profile_country_title: "מדינה",
        //                     fg_profile_country_value: "UK",
        //                     fg_profile_industry_titile: "תעשייה",
        //                     fg_profile_industry_value: "מזון",
        //                     fg_profile_team_titile: "הצוות שלנו",
        //                     fg_profile_team_value: "5 - מ.פרויקט, מובייל, ענן,מעצב",

        //                     fg_goals: "ייעדים ומטרות",
        //                     fg_goal1: "בניית אפליקציית משלוחים",
        //                     fg_goal2: "עסקי מזון יכולים להרשם",
        //                     fg_goal3: "משתמשים יכולים להזמין",
        //                     fg_goal4: "שליחים יכולים לקבל הזמנות",


        //                     fg_challanges: "אתגרים",
        //                     fg_challange_title1: "סנכרון אומני-צ'אנל",
        //                     fg_challange_1: "זה היה אתגר לסנכרן את כל הישויות של האפליקציה כדי לגרום לה לעבוד ביחד כמו שצריך.",
        //                     fg_challange_title2: "הגבלת זמן",
        //                     fg_challange_2: "נדרש ליישם את האפליקציה מאפס תוך 10-12 חודשים.",
        //                     fg_challange_title3: "מוצר רב-פלטפורמות",
        //                     fg_challange_3: "חלק מהתכונות המשולבות בצורה חלקה באנדרואיד אינן נתמכות באייפון ולהיפך.",

        //                     fg_solution_title: "פתרונות",
        //                     fg_solution_description1: "מעצב ה-UX/UI שלנו יצר אב טיפוס של Figma המותאם לדרישות הלקוח. המהנדסים הטכניים שלנו עבדו על הארכיטקטורה הטכנית של הפתרון.",
        //                     fg_solution_description2: "במקביל, התחלנו לפתח פתרון מבוסס ענן באמצעות שירותי AWS, וכדי ליישר קו עם מגבלת הזמן פיתחנו פתרון מובייל חוצה פלטפורמות באמצעות React Native.",
        //                     fg_solution_description3: "התגברנו על בעיות הגבלה בפלטפורמות הניידות, והמצאנו גישה יצירתית ליישום התכונות הנדרשות בשתי הפלטפורמות.",

        //                     fg_techstack: "טכנולוגיות",
        //                     fg_techstack1: "React",
        //                     fg_techstack2: "N.JS",
        //                     fg_techstack3: "Python",
        //                     fg_techstack4: "AWS",

        //                     fg_results_title: "תוצאה",
        //                     fg_result_title1: "עמידה בזמנים",
        //                     fg_result_description1: "שחררנו אפליקציית משלוח חוצת פלטפורמות בזמן שתואמת את דרישות הלקוח שלנו ונתנה להם ערך.",
        //                     fg_result_title2: "⭡ משתמשים חדשים",
        //                     fg_result_description2: "הפתרון שפיתחנו עבור הלקוח עזר להם למוצר שפיתחנו",
        //                     fg_result_title3: "⭡ עלייה במכירות",
        //                     fg_result_description3: "העבודה שלנו עזרה ללקוח לייצר רווחים על ידי מתן ערך למשתמשי הקצה.",
        //                 },

        //                 healthness: {

        //                     health_title: "HEALTHNESS",
        //                     health_subtitle1: "אפליקציית בריאות",
        //                     health_subtitle2: "פיתוח אפליקציית אנדרואיד ואייםון מבוססת בינה מלאכותית עם ראייה ממוחשבת לניתוח בריאות הפנים.",
        //                     health_description: "תיאור הפרויקט",
        //                     health_client_title: "הלקוח",
        //                     health_product_title: "המוצר",
        //                     health_work_title: "העבודה",
        //                     health_client_description: "חברה הפועלת בתעשיית הבריאות. המשימה של החברה היא לעזור לאנשים לדאוג לבריאותם ולרווחתם, לספק משוב על תהליך ההזדקנות ולתת עצות שימושיות לגבי בריאות אסתטית.",
        //                     health_product_description: "אפליקציה לנייד לניטור בריאות ויופי, המיועדת לשרת בני 18-60 אמריקאים, שהם בעיקר בריאים אך רוצים לעקוב אחר בריאותם כדי להאריך את תוחלת חייהם.",
        //                     health_work_description: "עיצב מערכת פורצת דרך שיכולה לרכז נתוני בריאות ולהגיע עם ציון מספר אחד כדי לשקף את טווח הבריאות הכולל של המשתמש ולעזור לחיות חיים ארוכים יותר, בריאים ומאושרים יותר.",

        //                     health_profile: "פרופיל",
        //                     health_profile_country_title: "מדינה",
        //                     health_profile_country_value: "USA",
        //                     health_profile_industry_titile: "תעשייה",
        //                     health_profile_industry_value: "בריאות",
        //                     health_profile_team_titile: "הצוות שלנו",
        //                     health_profile_team_value: "6-8 - מ.פרויקט,בינה מלאכותית,פולסטאק, ומובייל",

        //                     health_goals: "ייעדים ומטרות",
        //                     health_goals_subtitle: "פיתוח אפליקציית אייפון המופעלת על ידי בינה מלאכותית עם ראייה ממוחשבת לניתוח בריאות הפנים",
        //                     health_goal1: "איחוד מדדי בריאות מרובים לציון יחיד",
        //                     health_goal2: "עזרה למשתמשים להבין את מצב הבריאות שלהם ביחס לעמיתיהם",
        //                     health_goal3: "לספק התראות בריאות בזמן",
        //                     health_goal4: "גישה לאנשי מקצוע ומרפאות בדירוג הגבוה ביותר",
        //                     health_goal5: "לספק למשתמשים הערכות מדויקות של מצב העור באמצעות נתונים חזותיים",

        //                     health_challanges: "אתגרים",
        //                     health_challange_title1: "צבירת נתונים",
        //                     health_challange_1: "מציאת דרך לאסוף ביעילות נתוני משתמשים ממכשירים חכמים באמצעות עכבה ביו-חשמלית כדי ליצור ניקוד המופעל על ידי בינה מלאכותית",
        //                     health_challange_title2: "שילוב חזון",
        //                     health_challange_2: "יישום המומחיות שלנו בחישובים ויזואליים כדי לפתח תכונות כולל ניתוח פגמים בפנים, מעקב אחר הזדקנות והערכת גיל לכאורה.",
        //                     health_challange_title3: "דיוק",
        //                     health_challange_3: "התמודדות עם כל האתגרים הקשורים ליישום צ'אטבוט  עם מודלים של בינה מלאכותית כדי להסביר את תוצאות ניתוח הפנים ולספק המלצות מותאמות אישית.",

        //                     health_solution_title: "פתרונות",
        //                     health_solution_description1: "במהלך תהליך הפיתוח, הצוות שלנו עבד בגיבוש סגור עם רופא בעל ידע מעמיק בבינה מלאכותית ולמידת מכונה, כמו גם מעצב חווית משתמש/ממשק משתמש בצד הלקוח.",
        //                     health_solution_description2: "כדי לבנות פתרון מדויק ביותר, השתמשנו במערך נתונים מותאם אישית המסומן בהנחיית מומחים רפואיים להדרכה של מודלי בינה מלאכותית שלנו. כדי להבטיח את האיכות והאמינות של הנתונים, ערכנו סקירות איכות נתונים קבועות בהנחיית מומחים רפואיים המעורבים בפרויקט.",
        //                     health_solution_description3: "הצוות שלנו סיפק את הפתרון בצורה של יישומי אנדרואיד ואייםון המחברים מכשירים חכמים של משתמשים ואיסוף נתונים המופעלים באמצעות מודלים של בינה מלאכותית עטופים בממשקי תוכנה .",

        //                     health_techstack: "טכנולוגיות",
        //                     health_techstack1: "AWS",
        //                     health_techstack2: "Kotlin",
        //                     health_techstack3: "Swift",
        //                     health_techstack4: "Python",

        //                     health_results_title: "תוצאה",
        //                     health_result_title1: "אינטגרציות",
        //                     health_result_description1: "הצוות שלנו סיפק את הפתרון הזה בצורה של אפליקציית אנדרואיד ואייפון המחברת מכשירים חכמים של משתמשים ומצבירה נתונים המופעלים באמצעות מודלי בינה מלאכותית עטופים בממשקי תוכנה",
        //                     health_result_title2: "85% דיוק המודלים",
        //                     health_result_description2: "הבטיח את קצב הדיוק הגבוה ביותר האפשרי של מודלים של למידת מכונה בליבת אפליקציית הבריאות שלנו, ותרם באופן משמעותי לחוויית משתמש מתקדמת ולאמינות הצעות בריאות.",
        //                     health_result_title3: "מומחיות בינה מלאכותית",
        //                     health_result_description3: "סיפקה את המומחיות הנרחבת שלנו בלמידת מכונה, ראיית מחשב, דור מוגבר של אחזור ומודלים של שפות גדולות כדי לפתח פונקציונליות ייחודית באפליקציית אנדרואיד ואייפון עוצמתית.",
        //                 },

        //                 rides: {

        //                     rides_title: "RIDES",
        //                     rides_subtitle1: "אפליקציה לחנות אופניים",
        //                     rides_subtitle2: "אפליקציה לאנדרואיד ואייפון המיועדת לחנות אופניים. דוגמאות לפיצ'רים: רכישה וסליקה, תוכן דינמי. שיתוף וצפייה במסלולי רעיבה וניתוח ביצועי אפליקציה.",
        //                     rides_description: "תיאור הפרויקט",
        //                     rides_client_title: "הלקוח",
        //                     rides_product_title: "המוצר",
        //                     rides_work_title: "העבודה",
        //                     rides_client_description: "חנות אופניים שרצתה להרחיב את הפעילות האופליין שלה ולהגדיל את החשיפה עם יותר משתמשים באמצעות אפליקציה סלולרית.",
        //                     rides_product_description: "אפליקצייה לנייד עבור אנדרואיד ואייפון. מאפשרת למשתמשים להזמין אופניים ואביזרים, אך גם ליצור קהילה של רוכבים על ידי מתן מידע על רכיבות ושבילים",
        //                     rides_work_description: "הצוות שלנו בנה את הארכיטקטורה והעיצוב עבור אנדרואיד ואייפון כאחד. פיתחנו פתרונות מבוססי ענן לניהול נתונים ותקשורת עם האפליקציה, תכננו פונקציות תשלום וסליקה ובנינו פתרון מבוסס מיקום לרכיבה שבילים.",

        //                     rides_profile: "פרופיל",
        //                     rides_profile_country_title: "מדינה",
        //                     rides_profile_country_value: "UK",
        //                     rides_profile_industry_titile: "תעשייה",
        //                     rides_profile_industry_value: "אופניים",
        //                     rides_profile_team_titile: "הצוות שלנו",
        //                     rides_profile_team_value: "5 - מ.פרויקט,מתכנתי מובייל וענן, מעצב",

        //                     rides_goals: "ייעדים ומטרות",
        //                     rides_goal1: "אפליקציית אי-קומרס",
        //                     rides_goal2: "מימוש באנדרואיד ואייפון בו״ז",
        //                     rides_goal3: "תוכן דינאמי",
        //                     rides_goal4: "ניתוח והצגה של שבילים על מפות",

        //                     rides_challanges: "אתגרים",
        //                     rides_challange_title1: "דיוק הצגת נתוני מפה",
        //                     rides_challange_1: "האפליקציה סיפקה ואיפשרה למשתמשים לשתף מפות רכיבה מבוססות לוויין. האתגר שעמד בפנינו היה לפתח מימוש של הצגת השבילים כמו שצריך בכל פלטפורמה והדיוק הנדרש.",
        //                     rides_challange_title2: "סנכרון דאטה",
        //                     rides_challange_2: "היינו צריכים לבנות פתרון מבוסס ענן שיסנכרן את הנתונים בשתי הפלטפורמות כראוי.",
        //                     rides_challange_title3: "אפליקציית חנות",
        //                     rides_challange_3: "נדרשים תשלומים ואישור משתי הפלטפורמות כדי לעבוד עם חשבון אחד של אותו פתרון.",

        //                     rides_solution_title: "פתרונות",
        //                     rides_solution_description1: "התאמנו את התוכנית הטכנית והעיצוב לדרישות המוצר שהגדרנו יחד עם הלקוח.",
        //                     rides_solution_description2: "הצוותים שלנו התחילו לעבוד על פתרון הענן באמצעות שירותי אמזון ובחרנו בפלאטר כפתרון רב-פלטפורמות להאצת הפיתוח במובייל.",
        //                     rides_solution_description3: "שילבנו את הפתרון עם מערכת תשלומים שתמכה בקלות בשתי הפלטפורמות והיא הייתה מותאמת לנתונים שניהלנו עם שירותי הענן שלנו.",
        //                     rides_solution_description4: "לאור הבקשה לבנות קהילת רוכבים, בנינו פתרון שאפשר למשתמשים לשתף ולהשתמש במפות רכיבה מבוססות מיקום-לווין וצפייה בתוך האפליקציה.",

        //                     rides_techstack: "טכנולוגיות",
        //                     rides_techstack1: "Flutter",
        //                     rides_techstack2: "Zelle",
        //                     rides_techstack3: "Python",
        //                     rides_techstack4: "AWS",

        //                     rides_results_title: "תוצאה",
        //                     rides_result_title1: "העלאת רווחים",
        //                     rides_result_description1: "פתרון אפליקציית-החנות שבנינו עבור אנדרואיד ואייפון עזרה ללקוח להגדיל את סך ההכנסות שלו ממשתמשי הקצה החדשים שהשתמשו באפליקציה ובצעו רכישות של אופניים ואביזרים.",
        //                     rides_result_title2: "עלייה במשתמשים",
        //                     rides_result_description2: "פתרון הקהילה שפיתחנו עזר ללקוח להגביר את המעורבות עם משתמשים קיימים ולרכוש משתמשים חדשים שמוכנים להשתמש במידע הרכיבה בתוך האפליקציה, לשתף מידע עם משתמשים אחרים ולמעשה לקנות פריטים מהאפליקציה.",
        //                     rides_result_title3: "עמידה בזמנים",
        //                     rides_result_description3: "פיתוח האפליקציות לתכונות הנדרשות, בשתי הפלטפורמות המחוברות לשירותי הענן, הותאם למסגרת הזמן של ~8 חודשים.",
        //                 },

        //                 netpay: {

        //                     netpay_title: "NETPAY",
        //                     netpay_subtitle1: "אפליקציית ארנק דיגיטלי לגוף פיננסי",
        //                     netpay_subtitle2: "פיתוח אפליקציה חדשנית לניהול הוצאות",
        //                     netpay_description: "תיאור הפרויקט",
        //                     netpay_client_title: "הלקוח",
        //                     netpay_product_title: "המוצר",
        //                     netpay_work_title: "העבודה",
        //                     netpay_client_description: "הלקוח הוא מוסד מוביל החלוץ בפתרונות ניהול פיננסיים חדשניים, הידוע במומחיות בתכנון אסטרטגי, ניהול סיכונים, ייעוץ השקעות ושילוב פינטק.",
        //                     netpay_product_description: "אפליקציית ארנק דיגיטלי המאפשרת למשתמשים לבצע עסקאות ולנהל הוצאות בצורה טובה יותר.",
        //                     netpay_work_description: "בנינו אפליקציית ארנק לאייפון ואנדרואיד, המאפשרת לבצע עסקאות במטבעות שונים ומסייעת למשתמשי הקצה לנהל את ההוצאות שלהם בצורה טובה יותר. כדי להתיישר עם תקנים של תעשיית הפינטק, היה נדרש לשמור על הנתונים שנאספו בטוחים ומאובטחים.",

        //                     netpay_profile: "פרופיל",
        //                     netpay_profile_country_title: "מדינה",
        //                     netpay_profile_country_value: "USA",
        //                     netpay_profile_industry_titile: "תעשייה",
        //                     netpay_profile_industry_value: "פינטק",
        //                     netpay_profile_team_titile: "הצוות שלנו",
        //                     netpay_profile_team_value: "5 - מ.פרויקט,מובייל,ענן,אבטחת מידע,מעצב",

        //                     netpay_goals: "ייעדים ומטרות",
        //                     netpay_goal1: "בניית אפליקציית ניהול כספים",
        //                     netpay_goal2: "הוספת אוטמציה לתמיכת לקוחות",
        //                     netpay_goal3: "פיתוח מודלי בינה מלאכותית להוצאות",
        //                     netpay_goal4: "אבטחת המידע במובייל ובענן",

        //                     netpay_challanges: "אתגרים",
        //                     netpay_challange_title1: "פתרון הוליסטי",
        //                     netpay_challange_1: "שלב את האפליקציה עם מערכות החברה כדי להגשים את הפתרון ההוליסטי הזה.",
        //                     netpay_challange_title2: "דיוק המידע",
        //                     netpay_challange_2: "בשל העדינות שלו, היה חשוב מאוד לטפל בנתונים שהועברו מהאפליקציה לשרתים, ולהיפך, בצורה המדויקת ביותר.",
        //                     netpay_challange_title3: "אבטחת המידע",
        //                     netpay_challange_3: "באשר לסיכונים הידועים של ארנק דיגיטלי, היה חיוני לפתח פתרון מאובטח שיבטיח את בטיחות הנתונים והפרטיות של המשתמשים.",

        //                     netpay_solution_title: "פתרונות",
        //                     netpay_solution_description1: "כדי להתמודד עם האתגרים של הלקוח, פיתחנו אפליקציה ידידותית למשתמש שמטרתה לפשט את מעקב ההוצאות והניהול הפיננסי באמצעות חבילה של תכונות אפקטיביות ביותר. אפליקציה זו אפשרה למשתמשים לקבל הודעה בזמן, לקבל תמונות ברורות של לוח המחוונים לתזכורות על הוצאות קרובות, ולקבל תובנות לגבי הכנסות, הוצאות ואיזון. עם פתרון מנהל ההוצאות משתמשים יכולים לנהל ללא מאמץ כרטיסי אשראי/חיוב באמצעות סריקה או הזנה ידנית, יחד עם אפשרויות נושא. הדגש העיקרי היה על פשטות ונוחות, שכן האפליקציה משמשת כלי רב ערך לשמירה על הארגון הפיננסי ובקרת ההוצאות.",
        //                     netpay_solution_description2: "שילבנו את האפליקציה עם מערכות החברה ואישרנו שהנתונים מסונכרנים כראוי בשני הכיוונים.",
        //                     netpay_solution_description3: "לבסוף, אבטחנו את הנתונים באמצעות סטנדרטים גבוהים ומקבולים והתאמנו את הפתרון לתקן התשלום המוביל",

        //                     netpay_techstack: "טכנולוגיות",
        //                     netpay_techstack1: "Swift",
        //                     netpay_techstack2: "Kotlin",
        //                     netpay_techstack3: "AWS",
        //                     netpay_techstack4: "Python",

        //                     netpay_results_title: "תוצאה",
        //                     netpay_result_title1: "שיפור התנהלות כספית",
        //                     netpay_result_description1: "שיפור בהרגלי הוצאות של משתמשי הקצה בכשלושים אחוז ע״י פתרון ניהול הוצאות שפתחנו באמצעות השימוש במודלים של הבינה המלאכותית.",
        //                     netpay_result_title2: "ירידה של כ-70 אחוז בעמלות על איחור",
        //                     netpay_result_description2: "ירידה בעמלות איחור ובקנסות עקב תזכורות לתשלום בזמן.",
        //                     netpay_result_title3: " עלייה באנגייג'מנט ",
        //                     netpay_result_description3: "ההתקשרות הכוללת של משתמשי הקצה במוצרי החברה גדלה ב-39% לאחר פרסום האפליקציה.",
        //                 },

        //                 goals: {
        //                     goal_title: "מטרות ויעדים",
        //                     goal1: "מתן פתרון איכותי",
        //                     goal2: "מותאם למפת הדרכים של הלקוח",
        //                     goal3: "מחויבות וסנכרון",
        //                     goal4: "זריזות וגמישות",
        //                 },

        //                 ux: {

        //                     ux_title: "חוויית משתמש",
        //                     ux_subtitle1: "אנו יוצרים עיצובי חוויית משתמש ברורים, פשוטים וידידותיים כדי לעזור למשתמשים לנווט בקלות בתוך המוצר",


        //                     ux_category_title1: "חוויית משתמש",
        //                     ux_category_description1: "אפליקציית הנייד או, הווב שלכם, יכולה לספק את התמורה הטובה ביותר למשתמשים שלה מבחינה פונקציונלית, אבל ללא חווית משתמש טובה, או ממשק משתמש אטרקטיבי, הם עלולים ללכת לאיבוד במוצר שלכם ולעבור למוצר מתחרה.",

        //                     ux_category_title2: "ממשק משתמש",
        //                     ux_category_description2: "אנו יוצרים עיצובי ממשק משתמש ברורים ואטרקטיביים כדי להעשיר את חווית המשתמש של המוצר על ידי התאמה לקונספטים עיצוביים ושימוש ביצירתיות שלנו.",

        //                     ux_category_title3: "עיצובים למרקטינג",
        //                     ux_category_description3: "אנו יוצרים ויזואליים אטרקטיביים עבור קמפיינים, באנרים, הערות פרסום וצרכים שיווקיים אחרים.",

        //                     ux_category_title4: "עיצובים לדוקמנטציה",
        //                     ux_category_description4: "אנו יוצרים ויזואליות וחווית משתמש בתיעוד, מדריכים למשתמש, בלוגים, מדריכים ועוד.",



        //                     ux_title2: "שמירה על עקביות של זרימת המשתמש לאורך כל המסע",
        //                     ux_bullet1: "פתרון בעיות ספציפיות של משתמש",
        //                     ux_bullet2: "לא להמציא את הגלגל מחדש",
        //                     ux_bullet3: "כמה שפחות צורך בעקומת למידה",

        //                     ux_title3: "המשתמש במרכז",
        //                     ux_bullet4: "הצרכים של המשתמש במרכז",
        //                     ux_bullet5: "בניית חוויית המשתמש סביב המשתמש",
        //                     ux_bullet6: "מחקר משתמש לפני הכל",

        //                     ux_title4: "קונטקסט",
        //                     ux_bullet7: "לא לבנות עיצוב בחלל ריק",
        //                     ux_bullet8: "איך המשתמש יתקשר עם המוצר",
        //                     ux_bullet9: "איך גורמים חיצוניים ישפיעו",
        //                     ux_bullet10: "איזה מצבים רגשיים נרצה להעביר",

        //                     ux_title5: "היררכיה",
        //                     ux_bullet11: "היררכיה חזותית של דפים בודדים",
        //                     ux_bullet12: "מפת אתר של המוצר להתמצאות",
        //                     ux_bullet13: "לעזור למשתמש לנווט במוצר שלך",
        //                     ux_bullet14: "כיצד אלמנטים מונחים על דף",

        //                     ux_title6: "נגישות",
        //                     ux_bullet15: "נגישות לכמה שיותר משתמשים",
        //                     ux_bullet16: "כולל אנשים עם מוגבלויות",
        //                     ux_bullet17: "קונטרסט חזק ללקויי ראייה",

        //                     ux_title7: "שימוש",
        //                     ux_bullet18: "כמה המוצר קל לשימוש",
        //                     ux_bullet19: "תהליך למידה קצר",
        //                     ux_bullet20: "זמן סיום משימה קצר ויעיל",
        //                     ux_bullet21: "שמשתמש חוזר כמה צריך להזכר",
        //                     ux_bullet22: "כמה טעויות משתמשים",
        //                     ux_bullet23: "כמה המשתמשים נהנים במוצר",

        //                     uxui_stack: "כלי העיצוב שלנו",
        //                     ux_techstack1: "Figma",
        //                     ux_techstack2: "Balsamiq",
        //                     ux_techstack3: "Axure",
        //                     ux_techstack4: "Miro",
        //                     ux_techstack5: "Sketch",

        //                 },


        //                 ui: {

        //                     ui_title: "ממשק משתמש",
        //                     ui_subtitle1: "אנו יוצרים עיצובי ממשק משתמש ברורים ואטרקטיביים כדי להעשיר את חווית המשתמש בתוך המוצר",

        //                     ui_title2: "בהירות",
        //                     ui_bullet1: "ניווט אינטואיטיבי של משתמשים",
        //                     ui_bullet2: "נראות ברורה של רכיבים",
        //                     ui_bullet3: "מטרתו של כל מרכיב ברורה",

        //                     ui_title3: "רספונסיביות",
        //                     ui_bullet4: "מתאים למגוון רחב יותר של מכשירים",
        //                     ui_bullet5: "חווית משתמש חלקה",
        //                     ui_bullet6: "אלמנטים שמישים ונגישים",

        //                     ui_title4: "הפחתת עומס קוגניטיבי",
        //                     ui_bullet7: "הימנע מצורך המשתמש לנתח",
        //                     ui_bullet8: "החלת כלל 3 קליקים",
        //                     ui_bullet9: "מזעור ריקול לטובת הכרה",

        //                     ui_title5: "יישורים",
        //                     ui_bullet10: "סידור חזותי של פריטים",
        //                     ui_bullet11: "הזמנה קלה עם ניווט למשתמש",

        //                     ui_title6: "רשתות",
        //                     ui_bullet12: "תן לממשק תחושת מבנה",
        //                     ui_bullet13: "השגת איזון חזותי",

        //                     ui_title7: "מינימליזם",
        //                     ui_bullet14: "שמירה על מינימליזם עם שימושיות",

        //                     ui_techstack: "Tech Stack",
        //                     ui_techstack1: "Figma",
        //                     ui_techstack2: "Photoshop",
        //                     ui_techstack3: "Illustrator",
        //                     ui_techstack4: "JIM.",
        //                     ui_techstack5: "Zeplin",
        //                 },



        //                 marketing: {

        //                     market_title: "מרקטינג ומדיה",
        //                     market_subtitle1: "אנחנו מייצרים גרפיקות אטרקטיביות לקמפיינים וצרכי שיווק ומגיה אחרים",

        //                     market_title2: "ניגודיות וחזרתיות",
        //                     market_bullet1: "משיכת עיניים לחלקים חשובים",
        //                     market_bullet2: "הדגשה ע״י סטייל של טקסט",
        //                     market_bullet3: "חזרה כדי לדחוף את הברנד",
        //                     market_bullet4: "חזרתיות עטזרת להתמצא",

        //                     market_title3: "יישור וקרבה",
        //                     market_bullet5: "שימוש ביישור נכון להקל על קריאה",
        //                     market_bullet6: "קירוב אל אלמנטים יחד",
        //                     market_bullet7: "קירוב חשוב במיוחד עם כיתוב",

        //                 },


        //                 dcos: {

        //                     dcos_title: "עיצובים וגרפיקות לדוקמנטציה ותיעודים כלליים",
        //                     dcos_subtitle1: "אנחנו מייצרים גרפיקות ועיצובים כולל חוויית משתמש נכונה לדוקמנטציות ומדריכים עבור תוכנות של לקוחות, בלוגים ועוד",

        //                     dcos_title1: "דוקמנטציה ןמדריך למשתמש",
        //                     dcos_bullet1: "עיצבנו דוקמנטציות ברורות ונוחות",
        //                     dcos_bullet2: "עזרנו לקוחות לתכנן את חוויית המשתמש",
        //                     dcos_bullet3: "יצרנו גרפיקות ואנימציות מתאימות לאופי החברה והמוצר",

        //                     dcos_title2: "מסמך שחרור גרסה",
        //                     dcos_bullet4: "הדגשת הטקסט הרלבנטי",
        //                     dcos_bullet5: "סידור נכון של הטקסט",
        //                     dcos_bullet6: "קישורים נכונים בטקסט",

        //                     dcos_title3: "בלוגים",
        //                     dcos_bullet7: "הכנת גרפיקות לבלוגים",
        //                     dcos_bullet8: "תכנון חוויית משתמש כללית",

        //                     dcos_techstack: "Tech Stack",
        //                     dcos_techstack1: "Readme",
        //                     dcos_techstack2: "Doxygen",
        //                     dcos_techstack3: "ClickUp",
        //                     dcos_techstack4: "Document360",
        //                     dcos_techstack5: "GitBook",
        //                     dcos_techstack6: "Tettra",


        //                 },
        //             },



        //         },

        //         general: {
        //             cta: {
        //                 cta_title: "האם אתם מוכנים לקדם את העסק שלכם?",
        //                 cta_subtitle1: "צרו קשר ובואו נבנה משהו מדהים",
        //                 cta_subtitle2: "ביחד",
        //                 cta_messgae: "שליחת הודעה",
        //             },
        //         },

        //         footer: {
        //             logo: {
        //                 logo_title: "פיתוח תוכנה וייעוץ",
        //                 logo_address: "תל אביב, ישראל",

        //             },
        //             company: {
        //                 company_title: "חברה",
        //                 company_about: "אודות",
        //                 company_aproach: "הגישה שלנו",
        //                 company_policy: "מדיניות",
        //                 company_terms: "תנאי שימוש",
        //                 company_contact: "יצירת קשר",
        //             },
        //             services: {
        //                 services_title: "שירותים",
        //                 services_web: "Web פיתוח",
        //                 services_mobile: "Mobile פיתוח",
        //                 services_uxui: "UX/UI פתרונות",
        //                 services_consult: "IT ייעוץ",
        //             },
        //             social: {
        //                 social_title: "רשתות חברתיות",
        //                 social_subtitle: "עקבו אחרנו ברשתות החברתיות",
        //             },
        //         },

        //         contact: {
        //             message: {
        //                 message_title: "שלחו לנו הודעה",
        //                 message_fname: "שם פרטי",
        //                 message_lname: "שם משפחה",
        //                 message_email: "מייל",
        //                 message_phone: "נייד",
        //                 message_text: "הודעה",
        //                 message_button: "שליחה",
        //                 message_button_loading: "שולח...",
        //                 message_address_title: "מיקום",
        //                 message_address: "תל אביב, ישראל",
        //                 message_phone_title: "טלפנו אלינו",
        //                 message_phone_number: "Tel: (+66)-098-2592690",
        //                 message_email_title: "שלחו מייל",
        //                 message_email_address: "sales@anbelectronics.com",
        //                 message_validation: "נא למלא את כל השדות",

        //             },
        //         },

        //         demo: {
        //             form: {
        //                 form_title: "שלחו לנו הודעה",
        //                 form_cb_web: "אפליקציית ווב/חנות אונליין/אתרים",
        //                 form_cb_mobile: "אפליקציות מובייל",
        //                 form_cb_cloud: "פתרונות ענן",
        //                 form_cb_uxui: "UX/UI שירותי",
        //                 form_cb_consult: "IT ייעוץ",
        //                 form_fname: "שם פרטי",
        //                 form_lname: "שם משפחה",
        //                 form_email: "מייל",
        //                 form_phone: "נייד",
        //                 form_text: "הודעה",
        //                 form_button: "שליחה",
        //                 form_button_loading: "שולח...",
        //                 form_address_title: "מיקום",
        //                 form_address: "תל אביב, ישראל",
        //                 form_phone_title: "טלפנו אלינו",
        //                 form_phone_number: "Tel: +972546604257",
        //                 form_email_title: "שלחו מייל",
        //                 form_email_address: "info@anbelectronics.com",
        //                 form_validation: "נא למלא את כל השדות",

        //             },
        //         },

        //     },
        // },

    },
});

